import React from 'react';
import { Avatar, Box, Button } from '@material-ui/core';
import useStyles from '../index.style';

const TeamsListing = ({ selectedTeams, setSelectedTeams }) => {
  const classes = useStyles();

  return (
    <Box className={classes.selectedMatchesList}>
      {selectedTeams?.map(team => {
        return (
          <Box key={team._id} className={classes.selectListMatchRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar alt={team.name} src={team.emblemUrl} variant="square" />
              </Box>
            </Box>
            <Box>
              <Box>
                <h3 style={{ fontWeight: 'bold' }}>{team.name}</h3>
              </Box>
              {team?.sport?.name && <Box>Sport - {team?.sport?.name}</Box>}
            </Box>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              onClick={() =>
                setSelectedTeams(prev => {
                  return prev.filter(item => item._id !== team._id);
                })
              }>
              Remove
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default TeamsListing;
