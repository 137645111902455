import React, { useState } from 'react';
import { Box, Table, TableHead, TableBody } from '@material-ui/core';
import GamesTableHeading from './GamesTableHeading';
import GamesTableItem from './GamesTableItem';
import { makeStyles } from '@material-ui/core/styles';

const GamesTable = ({ tableData, B2BUserType, externalType }) => {
  const useStyles = makeStyles(theme => ({
    tableContainer: {
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '16px',
      marginTop: '24px',
    },
  }));

  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const classes = useStyles();

  return (
    <Box className={`Cmt-table-responsive ${classes.tableContainer}`}>
      <Table>
        <TableHead>
          <GamesTableHeading />
        </TableHead>
        <TableBody>
          {tableData.length > 0
            ? tableData.map((row, index) => (
                <GamesTableItem
                  row={row}
                  key={index}
                  B2BUserType={B2BUserType}
                  externalType={externalType}
                  dropdownOpenId={dropdownOpenId}
                  setDropdownOpenId={setDropdownOpenId}
                />
              ))
            : null}
        </TableBody>
      </Table>
      {tableData.length === 0 && <Box style={{ margin: '60px auto', textAlign: 'center' }}>No games found</Box>}
    </Box>
  );
};

export default GamesTable;
