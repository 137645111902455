import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from 'services/auth/jwt/config';
import { BET_TEMPLATE } from 'constants/ActionTypes';

export const getBetTemplates = (page, rowsPerPage) => {
  let url = `/bet-templates/admin`;
  if (page >= 0 && rowsPerPage >= 0) url += `?offset=${page * rowsPerPage}&limit=${rowsPerPage}`;
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(url)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch({ type: BET_TEMPLATE, payload: response.data });
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const createBetTemplate = data => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/bet-templates/admin', data)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch(getBetTemplates());
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const updateBetTemplate = (id, data) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch(`/bet-templates/admin/${id}`, data)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch(getBetTemplates());
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};
