import React, { useState, useEffect } from 'react';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Button from '@material-ui/core/Button';
import { getFanClubs } from 'redux/actions/FanClub';
import { useHistory } from 'react-router-dom';
import ListView from './ListView';
import { GET_FANCLUBS } from 'constants/ActionTypes';

const FanClubList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { fanClubsList } = useSelector(({ fanClub }) => fanClub);
  const [fanClubList, setFanClubList] = useState([]);

  useEffect(() => {
    dispatch(getFanClubs());
  }, [dispatch]);

  useEffect(() => {
    setFanClubList(fanClubsList);
  }, [fanClubsList]);

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title="FanClub"
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/fan-club/new');
            dispatch({
              type: GET_FANCLUBS,
              payload: undefined,
            });
          }}>
          Create
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        {fanClubList !== undefined && fanClubList.length > 0 ? <ListView fanClubList={fanClubList} /> : null}
      </CmtCardContent>
    </CmtCard>
  );
};

export default FanClubList;
