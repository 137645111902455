import {
  GET_ODDS,
  SELECT_SPORT,
  TOGGLE_SIDEBAR_COLLAPSED,
  GET_VISIBLE_ODDS,
  PATCH_ODD_COMMON_NAME,
} from 'constants/ActionTypes';
import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';

export const selectSport = sport => {
  return {
    type: SELECT_SPORT,
    payload: sport,
  };
};

export const toggleExpandSidebar = value => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_COLLAPSED,
      payload: value,
    });
  };
};

export const getOdds = (selectedSport, page, rowsPerPage, searchQuery, sortType) => {
  return dispatch => {
    dispatch(fetchStart());
    if (selectedSport) {
      let url = `/odds/admin/odd-types/${selectedSport}?offset=${page *
        rowsPerPage}&limit=${rowsPerPage}&name=${searchQuery}`;
      if (sortType) {
        url += `&sortBy=${sortType.field}&sortOrder=${sortType.order}`;
      }
      axios
        .get(url)
        .then(response => {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ODDS, payload: response.data });
        })
        .catch(() => {
          dispatch(fetchError('Something went wrong'));
        });
    }
  };
};

export const getVisibleOdds = selectedSport => {
  return dispatch => {
    dispatch(fetchStart());
    if (selectedSport) {
      axios
        .get(`/odds/admin/odd-types/${selectedSport}?visible=true`)
        .then(response => {
          dispatch(fetchSuccess());
          dispatch({ type: GET_VISIBLE_ODDS, payload: response.data });
        })
        .catch(() => {
          dispatch(fetchError('Something went wrong'));
        });
    }
  };
};

export const oddTypeCommonName = (id, name, type, nameYeppSimple) => {
  return dispatch => {
    dispatch(fetchStart());
    let body = {
      commonName: name,
      oddOptionBettingSelectorType: parseInt(type),
      commonNameSimple: nameYeppSimple,
    };
    if (id && name !== undefined && type !== undefined) {
      axios
        .patch(`/odds/odd-types/properties/` + id, body)
        .then(response => {
          dispatch(fetchSuccess());
          dispatch({ type: PATCH_ODD_COMMON_NAME, payload: response.data });
          dispatch(fetchError('Common name saved'));
        })
        .catch(() => {
          dispatch(fetchError('Something went wrong'));
        });
    }
  };
};

export const updateBetTemplates = () => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .post(`/bet-templates/update-odd-types`)
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(fetchError('Bet Templates Updated'));
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};
