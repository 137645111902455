import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './index.style';
import ChampionshipCell from './ChampionshipCell';

const ListVIew = ({ championshipList, removeChampionship, publishChampionship }) => {
  const classes = useStyles();

  return (
    <Box className={classes.championshipListContainer}>
      {championshipList !== undefined &&
        championshipList.map(championship => {
          return (
            <ChampionshipCell
              publishChampionship={publishChampionship}
              championshipData={championship}
              key={championship?._id}
              removeChampionship={removeChampionship}
            />
          );
        })}
    </Box>
  );
};

export default ListVIew;
