import {
  GET_CHALLENGES,
  CHALLENGE_DETAILS,
  CHALLENGE_MATCHES,
  CHALLENGE_PARTICIPANTS,
  CHALLENGE_RANK,
  CHALLENGE_TRANSACTIONS,
  CHALLENGE_OUTRIGHTS,
} from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getChallenges = status => {
  let st = '';
  if (status === false) {
    st = '';
  } else {
    st = '?status=' + status;
  }
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/admin' + st)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_CHALLENGES, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getChallengeDetail = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/admin/' + id + '/details')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CHALLENGE_DETAILS, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getChallengeTransaction = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/wallet-transfers/admin/' + id + '/transactions')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CHALLENGE_TRANSACTIONS, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getChallengeParticipants = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/admin/' + id + '/participants')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CHALLENGE_PARTICIPANTS, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getChallengeMatches = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/admin/' + id + '/matches')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CHALLENGE_MATCHES, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getChallengeOutrights = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/admin/' + id + '/outrights')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CHALLENGE_OUTRIGHTS, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getChallengeRanking = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenge-rankings/admin/' + id + '/ranking')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CHALLENGE_RANK, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
