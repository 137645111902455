import React from 'react';
import { Avatar, Box, Button } from '@material-ui/core';
import useStyles from '../index.style';

const CompetitionsListing = ({ selectedCompetitions, setSelectedCompetitions }) => {
  const classes = useStyles();

  return (
    <Box className={classes.selectedMatchesList}>
      {selectedCompetitions?.map(competition => {
        return (
          <Box key={competition._id} className={classes.selectListMatchRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar alt={competition.name} src={competition.emblemUrl} variant="square" />
              </Box>
            </Box>
            <Box>
              <Box>
                <h3 style={{ fontWeight: 'bold' }}>{competition.name}</h3>
              </Box>
              {competition?.sport?.name && <Box>Sport - {competition?.sport?.name}</Box>}
            </Box>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              onClick={() =>
                setSelectedCompetitions(prev => {
                  return prev.filter(item => item._id !== competition._id);
                })
              }>
              Remove
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default CompetitionsListing;
