import { GET_POPUP, GET_ALL_POPUPS } from 'constants/ActionTypes';

import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const addPopUp = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/pop-ups', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(fetchError('POP-UP CREATED'));
        window.location.href = '/pop-ups';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getAllPopUps = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/pop-ups')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_ALL_POPUPS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getPopUp = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`/pop-ups/${id}`)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_POPUP, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const updatePopUp = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch(`/pop-ups/${id}`, body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(fetchError('POP-UP UPDATED'));
        setTimeout(() => {
          window.location.href = '/pop-ups';
        }, 200);
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const deletePopUp = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`/pop-ups/${id}`)
      .then(data => {
        dispatch(fetchSuccess());
        setTimeout(() => {
          window.location.href = '/pop-ups';
        }, 200);
        dispatch(fetchError('POP-UP DELETED'));
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
