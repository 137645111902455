import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';

const PrizeCell = ({ deletePrize, dataPrize, httpImage, editDetail, dataUuid, editPrize, disableEdit }) => {
  const classes = useStyles();

  return (
    <Box className={classes.prizeCell}>
      {
        <Box className={classes.prizeCellImage}>
          {!httpImage ? (
            dataUuid.map((uuid, index) => {
              let imgUrl;
              if (Object.getOwnPropertyNames(dataUuid[index])[0] === dataPrize.photo) {
                imgUrl = URL.createObjectURL(dataUuid[index][dataPrize.photo]);
                return <img alt="prize" key={index} src={imgUrl} />;
              }
            })
          ) : (
            <img alt="prize" src={dataPrize.photo} />
          )}
          <Button
            style={{ marginTop: '10px' }}
            className="editPrize"
            variant="contained"
            color="primary"
            onClick={editPrize}
            disabled={disableEdit}>
            Edit Prize
          </Button>
          <Button
            style={{ marginTop: '10px' }}
            className="editPrize"
            variant="contained"
            color="primary"
            onClick={deletePrize}
            disabled={disableEdit}>
            Delete Prize
          </Button>
        </Box>
      }

      <Box className={classes.prizeCellDetails}>
        <Box className={classes.prizeCellTitle}>
          <h3>
            <strong>Prize Name</strong>: {dataPrize.name}
          </h3>
        </Box>
        <Box className={classes.prizeCellDescription}>
          {dataPrize.description.map((item, key) => {
            return (
              <Box key={key} className={classes.prizeCellTranslation}>
                <span className={classes.prizeCellLabel}>Description {item.language}</span>
                <span className={classes.prizeCellValue}>{item.description}</span>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default PrizeCell;
