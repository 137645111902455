import React, { useEffect, useState } from 'react';

import { Box, Button, TablePagination, FormControl, InputLabel, Input } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import CmtAvatar from '@coremat/CmtAvatar';

import useStyles from '../index.style';
import ClearIcon from '@material-ui/icons/Clear';
import makeStyles from '@material-ui/core/styles/makeStyles';

const SelectionSearch = props => {
  const useStyles = makeStyles(theme => ({
    clearIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }));
  const classes = useStyles();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
    props.setSelection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.added]);

  const submitVal = () => {
    if (value && value.length > 0) {
      props.submit(value);
    } else {
      props.submit('');
    }
  };

  const clearSearchField = () => {
    setValue('');
    props.submit('');
  };

  return (
    <FormControl style={{ width: '100%', display: 'block' }}>
      <Box style={{ display: 'inline-block' }}>
        <InputLabel htmlFor="input-with-icon-adornment">Search for Selection</InputLabel>
        <Input
          style={{ marginRight: '20px' }}
          endAdornment={value?.length >= 1 && <ClearIcon className={classes.clearIcon} onClick={clearSearchField} />}
          id="input-with-icon-adornment"
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') submitVal();
          }}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};

const rowsPerPage = 10;

const SelectSelections = ({ selection, selectSelection, selectedSelections }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [selectionFull, setSelectionFull] = useState(selection.filter(item => item.status === 1));
  const [selectionPagination, setSelectionPagination] = useState(selectionFull.slice(0, rowsPerPage));
  const [selectionAdded, setSelectionAdded] = useState(false);
  const handleChangePage = (event, newPage) => {
    setSelectionPagination(selectionFull.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };
  useEffect(() => {
    // filter selected matches
    let updatedSelections = selection.filter(
      match => match.status === 1 && !selectedSelections.find(({ _id }) => match._id === _id),
    );
    // correct the page number by number of matches
    if (page && count) {
      if (rowsPerPage * page + 1 === count) setPage(page - 1);
    }
    setCount(updatedSelections.length);
    setSelectionFull(updatedSelections);

    let updatedSelectionsPagination = updatedSelections.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || {};

    if (!updatedSelectionsPagination.length) {
      setSelectionPagination(
        updatedSelections.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage) || {},
      );
    } else {
      setSelectionPagination(updatedSelectionsPagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSelections, selection]);
  const submit = valueSearch => {
    const searchList = [];
    let newSelectionList = selection.filter(selection => selection.status === 1);

    if (valueSearch.length > 0) {
      newSelectionList
        .filter(selection => !selectedSelections.find(({ _id }) => selection._id === _id))
        .forEach(value => {
          if (valueSearch && value.name.toLowerCase().includes(valueSearch.toLowerCase())) {
            searchList.push(value);
          }
        });
      setSelectionFull(searchList);
      setCount(searchList.length);
      setSelectionPagination(searchList.slice(0, rowsPerPage));
    } else {
      setSelectionFull(newSelectionList);
      setCount(newSelectionList.length);
      setSelectionPagination(newSelectionList.slice(0, rowsPerPage));
    }
    setPage(0);
  };

  return (
    <Box className={classes.selectListMatch}>
      <Box style={{ marginBottom: '20px', display: 'flex' }}>
        <SelectionSearch classes={classes} setSelection={setSelectionAdded} added={selectionAdded} submit={submit} />
      </Box>
      <Box className={classes.selectListMatchContainer}>
        {selectionPagination?.map((selection, id) => {
          return (
            <Box key={id} className={classes.selectListMatchRow}>
              <Box>
                <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Box
                    mr={4}
                    style={{
                      width: '30px',
                      overflow: 'hidden',
                      marginRight: '0',
                      borderRight: '1px solid #fff',
                    }}>
                    <CmtAvatar size={60} alt={selection.name} />
                  </Box>
                  <Box
                    mr={4}
                    style={{
                      width: '30px',
                      overflow: 'hidden',
                      marginRight: '0',
                    }}>
                    <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} alt={selection.name} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>
                  <h3 style={{ fontWeight: 'bold' }}>{selection.name}</h3>
                </Box>
                <Box className={classes.selectionsDate}>
                  Games: <strong>{selection.games.length}</strong>
                  <br />
                  Competitions: <strong>{selection.competitions.length}</strong>
                </Box>
              </Box>
              <Button
                style={{ marginLeft: 'auto' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  selectSelection(selection);
                  setSelectionAdded(true);
                }}>
                Add
              </Button>
            </Box>
          );
        })}
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default SelectSelections;
