import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './index.style';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import FanClubDetails from './FanClubDetails';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getFanClubs } from '../../../redux/actions/FanClub';

const FanClub = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/fan-clubs');
              dispatch(getFanClubs());
            }}
          />
        }
        title={id !== undefined ? 'Edit FanClub' : 'Create FanClub'}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent className={classes.cardContentRoot}>
        <FanClubDetails />
        <NotificationContainer />
      </CmtCardContent>
    </CmtCard>
  );
};

export default FanClub;
