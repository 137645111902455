import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import { v4 as uuidv4 } from 'uuid';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const AddPrize = ({ langList, dataPrize, onSavePrize, addPrize }) => {
  const classes = useStyles();

  const lang = langList;

  const [prizeDescription, setPrizeDescription] = useState(() => {
    let state = [];
    lang.forEach((item, key) => {
      let array = {
        description: '',
        language: item,
      };
      state.push(array);
    });

    return state;
  });

  const [prize, setPrize] = useState({});
  const [prizeUuid, setPrizeUuid] = useState(null);
  const [prizeName, setPrizeName] = useState({});

  const handleChange = (e, lang, key) => {
    let description = {
      description: e.target.value,
      language: lang,
    };
    setPrizeDescription(prizeDescription, (prizeDescription[key] = description));
  };

  const [previewPrizePhoto, setPreviewPrizePhoto] = useState(null);

  const handlePrizePhoto = e => {
    setPreviewPrizePhoto(URL.createObjectURL(e.target.files[0]));
    setPrize(e.target.files[0]);
    setPrizeUuid(uuidv4());
  };

  const onChangeName = e => {
    setPrizeName(e.target.value);
  };

  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 3000, () => {
      alert('callback');
    });
  };

  const savePrize = () => {
    let newPrize = {
      name: prizeName,
      description: prizeDescription,
      photo: prizeUuid,
      deleted: false,
    };

    let newPrizeUuid = {
      [prizeUuid]: prize,
    };

    if (prizeName.length !== undefined) {
      if (prizeUuid !== null) {
        let emptyDescription = false;
        prizeDescription.forEach((description, key) => {
          if (description.description.length === 0) {
            emptyDescription = true;
          }
        });
        if (emptyDescription === true) {
          createNotification('error', 'All prize description fields are mandatory');
        } else {
          onSavePrize(newPrize, newPrizeUuid);
          addPrize();
        }
      } else {
        createNotification('error', 'Prize Photo should not be empty');
      }
    } else {
      createNotification('error', 'Prize Name should not be empty');
    }
  };

  return (
    <Box className={classes.prizeOfficial}>
      <TextField
        id="outlined-basic"
        onChange={onChangeName}
        style={{ maxWidth: '300px' }}
        label="Prize Name"
        variant="outlined"
      />
      <Box className={classes.prizeOfficialRow}>
        <Box className={classes.prizePhoto}>
          <Box className={classes.imagePlaceholder}>
            {previewPrizePhoto !== null ? <img src={previewPrizePhoto} className={classes.imageLogo} alt="prize" /> : null}
          </Box>
          <input
            accept="image/*"
            onChange={handlePrizePhoto}
            className={classes.hiddenInput}
            id="contained-button-prize-photo"
            multiple
            type="file"
          />
          <label htmlFor="contained-button-prize-photo">
            <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
              Upload
            </Button>
          </label>
        </Box>
        <Box className={classes.prizeDescription}>
          {lang.map((item, key) => {
            return (
              <TextField
                id="outlined-basic"
                key={key}
                onChange={e => handleChange(e, item, key)}
                multiline={true}
                className={classes.Textarea50}
                label={'Description ' + item.toUpperCase()}
                variant="outlined"
              />
            );
          })}
        </Box>
      </Box>
      <Button
        style={{ margin: '20px auto 0 auto', display: 'inline-block' }}
        variant="contained"
        color="primary"
        onClick={savePrize}>
        Save Prize
      </Button>
      <NotificationContainer />
    </Box>
  );
};

export default AddPrize;
