import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  TextField,
  Typography,
  TablePagination,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import GamesTable from './GamesTable';
import { fetchGames, fetchGamesCSV } from '../../../../redux/actions/Analytics';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
  },
  downloadButton: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tableContainer: {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '16px',
    marginTop: '24px',
  },
  subtitle: {
    marginBottom: '20px',
  },
  placeholderRow: {
    height: '674px',
    textAlign: 'center',
  },
  loaderCell: {
    position: 'relative',
    height: '100%',
  },
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const GamesSection = ({ externalType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { games, loading, error } = useSelector(state => state.analytics);

  const [filters, setFilters] = useState({ searchQuery: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tableRef = useRef(null);

  useEffect(() => {
    if (externalType !== undefined) {
      fetchGamesData(externalType);
    }
  }, [externalType]);

  const fetchGamesData = externalType => {
    dispatch(fetchGames({ startDate: null, endDate: null, externalType }));
  };

  const handleFilterChange = (filter, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filter]: value }));
    setPage(0);
  };

  const handleSearchGameQueryChange = event => {
    const { value } = event.target;
    handleFilterChange('searchQuery', value);
  };

  const handleDownloadGameCSV = () => dispatch(fetchGamesCSV());

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [page, rowsPerPage]);

  const filteredGames = useMemo(() => {
    return games.filter(game => {
      const searchMatch =
        !filters.searchQuery ||
        (game.gameHeadline && game.gameHeadline.toLowerCase().includes(filters.searchQuery.toLowerCase()));

      return searchMatch;
    });
  }, [games, filters.searchQuery]);

  const sortedGames = useMemo(() => {
    return filteredGames.sort((a, b) => b.totalPoints - a.totalPoints);
  }, [filteredGames]);

  const displayedGames = sortedGames.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const renderPlaceholderRow = () => (
    <TableRow className={classes.placeholderRow}>
      <TableCell colSpan={7} className={classes.loaderCell}>
        <Box className={classes.loaderContainer}>
          <CircularProgress size={34} />
        </Box>
      </TableCell>
    </TableRow>
  );

  const renderNoGamesFoundRow = () => (
    <TableRow className={classes.placeholderRow}>
      <TableCell colSpan={7} className={classes.loaderCell}>
        <Box className={classes.loaderContainer}>
          <Typography>No games found</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom className={classes.subtitle} ref={tableRef}>
        Games
      </Typography>
      <Box className={classes.buttonContainer}>
        <TextField
          label="Search by Game"
          variant="outlined"
          value={filters.searchQuery}
          onChange={handleSearchGameQueryChange}
        />
        <Button className={classes.downloadButton} onClick={handleDownloadGameCSV} startIcon={<GetAppIcon />}>
          Download Games CSV
        </Button>
      </Box>

      <Box mt={3} className={classes.tableContainer}>
        <Table>
          <TableBody>
            {loading ? (
              renderPlaceholderRow()
            ) : displayedGames.length > 0 ? (
              <GamesTable tableData={displayedGames} externalType={externalType} />
            ) : (
              renderNoGamesFoundRow()
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredGames.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {error && <Typography color="error">{String(error)}</Typography>}
      </Box>
    </React.Fragment>
  );
};

export default GamesSection;
