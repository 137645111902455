import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchContainerHeight } from 'constants/AppConstants';
import { fetchOutrights, setOutrightsFilters, getOutrights } from 'redux/actions/Outrights';
import { fetchAllCompetitionsDetails } from 'redux/actions/Competitions';
import { TablePagination, Box, Button, FormControl, InputLabel, Input } from '@material-ui/core';
import RecentTable from './RecentTable';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import SearchIcon from '@material-ui/icons/Search';
import Sidebar from './Sidebar';

const OutrightsSearch = props => {
  const dispatch = useDispatch();
  const { filters } = useSelector(({ outrights }) => outrights);
  const [value, setValue] = useState(props.value);

  const submitVal = () => {
    dispatch(setOutrightsFilters({ ...filters, offset: 0, searchQuery: value }));
    if (value) {
      dispatch(getOutrights(0, filters.limit, filters.status?.type, filters.sport?._id, value));
    }
  };

  return (
    <FormControl className={props.classes.margin} style={{ display: 'flex', flexDirection: 'row' }}>
      <InputLabel htmlFor="input-with-icon-adornment">Search for Outrights</InputLabel>
      <Input
        style={{ marginRight: '20px' }}
        id="input-with-icon-adornment"
        defaultValue={value}
        onChange={e => {
          setValue(e.target.value);
        }}
      />
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};

const CompetitionApp = () => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({ height: getMatchContainerHeight(showFooter) + 80 });
  const dispatch = useDispatch();
  const { outrightsList, filters } = useSelector(({ outrights }) => outrights);

  const handleChangePage = (event, newPage) => {
    dispatch(setOutrightsFilters({ ...filters, offset: newPage }));
  };

  const handleChangeRowsPerPage = event => {
    dispatch(setOutrightsFilters({ ...filters, offset: 0, limit: event.target.value }));
  };

  useEffect(() => {
    dispatch(getOutrights(filters.offset, filters.limit, filters.status?.type, filters.sport?._id, filters.searchQuery));
    // eslint-disable-next-line
  }, [filters?.sport, filters?.status, filters?.offset, filters?.limit]);

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={clsx(classes.inBuildAppContainer)}>
        <Box className={classes.inBuildAppMainContent}>
          <CmtCardHeader
            className="pt-4"
            title={'Outrights List'}
            style={{ marginBottom: '30px', zIndex: '444' }}
            titleProps={{
              variant: 'h1',
              component: 'div',
              className: classes.titleRoot,
            }}>
            <OutrightsSearch classes={classes} />
            <Button
              variant="contained"
              style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
              color="primary"
              onClick={() => {
                dispatch(fetchOutrights());
              }}>
              FETCH
            </Button>
            <Button
              variant="contained"
              style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
              color="primary"
              onClick={() => {
                dispatch(fetchAllCompetitionsDetails());
              }}>
              GET OUTRIGHTS ODDS
            </Button>
          </CmtCardHeader>
          <Box className="Cmt-table-responsive" style={{ display: 'flex' }}>
            <Sidebar />
            <PerfectScrollbar className={classes.perfectScrollbarMatchCon} style={{ width: '100%' }}>
              <RecentTable tableData={outrightsList} />
            </PerfectScrollbar>
          </Box>
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={
              outrightsList?.length % filters?.limit === 0 || outrightsList?.length === 0
                ? -1
                : (filters.offset + 1) * filters.limit
            }
            page={filters.offset}
            onPageChange={handleChangePage}
            rowsPerPage={filters?.limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
            show
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompetitionApp;
