import React from 'react';
import { useSelector } from 'react-redux';
import CmtGridView from '../../../../../@coremat/CmtGridView';
import BarCell from './BarCell';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const ListGridView = ({ onShowBarDetail, onClickEditBar }) => {
  const { barsList } = useSelector(({ barApp }) => barApp);

  return (
    <Box px={6} py={{ xs: 8, xl: 10 }}>
      <CmtGridView
        border={true}
        itemPadding={18}
        responsive={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
        }}
        data={barsList}
        renderRow={(item, index) => (
          <BarCell key={index} bar={item} onShowBarDetail={onShowBarDetail} onClickEditBar={onClickEditBar} />
        )}
      />
    </Box>
  );
};

export default ListGridView;

ListGridView.prototype = {
  onShowBarDetail: PropTypes.func,
  onClickEditBar: PropTypes.func,
};
