import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings, addNewSetting, changeSetting } from '../../../redux/actions/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import SettingDetail from './SettingDetail';
import AddSetting from './AddSetting';
import Button from '@material-ui/core/Button';

const Settings = () => {
  const dispatch = useDispatch();
  const { settingsList, createSettingResponse } = useSelector(({ settings }) => settings);
  const [settings, setSettings] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const useStyles = makeStyles(theme => ({
    popupWrapper: {
      top: 0,
      left: 0,
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.3)',
    },
    popup: {
      position: 'absolute',
      top: '100px',
      left: '50%',
      maxWidth: '80%',
      maxHeight: 'calc(100vh - 150px)',
      width: '100%',
      background: '#fff',
      transform: 'translateX(-50%)',
      padding: '30px 0 30px 30px',
      borderRadius: '4px',
      display: 'flex',
      overflow: 'scroll',
    },
    fakeBorder: {
      width: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      padding: '4px 10px',
      borderRadius: '4px',
      marginRight: '10px',
      position: 'relative',
      height: '58px',
      '@media screen and (max-width: 768px)': {
        marginTop: '10px',
      },
    },
    settingsList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    settingsItem: {
      maxWidth: '50%',
      width: '100%',
    },
  }));
  const classes = useStyles();

  const toggleCreate = () => {
    openModal === true ? (document.body.style.overflow = '') : (document.body.style.overflow = 'hidden');
    setOpenModal(!openModal);
  };

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  useEffect(() => {
    setSettings(settingsList);
  }, [settingsList]);

  useEffect(() => {
    setOpenModal(false);
    document.body.style.overflow = '';
  }, [createSettingResponse]);

  const editSetting = (id, body) => {
    dispatch(changeSetting(id, body));
  };

  const addSetting = body => {
    dispatch(addNewSetting(body));
  };

  return (
    <Box component="div">
      <Box>
        <Button variant="contained" color="primary" style={{ margin: '15px' }} onClick={toggleCreate}>
          Create New Setting
        </Button>
      </Box>
      <Box className={classes.settingsList}>
        {settings.map((item, key) => {
          return <SettingDetail editSetting={editSetting} key={key} setting={item} className={classes.settingsItem} />;
        })}
      </Box>
      {openModal === true && (
        <Box className={classes.popupWrapper}>
          <Box className={classes.popup}>
            <AddSetting addSetting={addSetting} toggleCreate={toggleCreate} className={classes.settingsItem} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Settings;
