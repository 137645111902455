import React, { useMemo, useRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useFilters } from '../hooks/useFilters';
import { useDataFetching } from '../hooks/useDataFetching';
import UserFilterModal from './UserFilterModal';
import RecentTable from './RecentTable';
import { B2BUserType } from '../../../../redux/actions/Analytics';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
  },
  searchField: {
    minWidth: '260px',
  },
  button: {
    marginLeft: '16px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  downloadButton: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tableContainer: {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '16px',
    marginTop: '24px',
  },
  subtitle: {
    marginBottom: '20px',
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },
  legendIcon: {
    marginRight: '8px',
  },
  placeholderRow: {
    height: '674px',
    textAlign: 'center',
  },
  loaderCell: {
    position: 'relative',
    height: '100%',
  },
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const UsersSection = ({ externalType }) => {
  const classes = useStyles();
  const { filters, handleFilterChange, handleFilterOpen, handleFilterClose, handleApplyFilter, handleReset } = useFilters();
  const memoizedFilters = useMemo(() => ({ ...filters, externalType }), [filters, externalType]);
  const { users, error, loading, handleDownloadCSV, availableCountries, fetchData } = useDataFetching(
    memoizedFilters,
    'users',
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allCountries, setAllCountries] = useState([]);

  const tableRef = useRef(null);

  useEffect(() => {
    if (users && users.length > 0 && allCountries.length === 0) {
      const countriesSet = new Set(users.map(user => user.country));
      setAllCountries(Array.from(countriesSet));
    }
  }, [users]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filters, users]);

  const flattenedUsers = useMemo(() => {
    return Array.isArray(users) ? users : Object.values(users).flat();
  }, [users]);

  const filteredUsers = useMemo(() => {
    return flattenedUsers.filter(user => {
      const countryMatch =
        filters.country === 'ALL' || filters.country === 'All Countries' || user.country === filters.country;
      const searchQuery = filters.searchQuery.toLowerCase();
      const searchMatch =
        !searchQuery ||
        (user.nickname && user.nickname.toLowerCase().includes(searchQuery)) ||
        (user.email && user.email.toLowerCase().includes(searchQuery));
      return countryMatch && searchMatch;
    });
  }, [flattenedUsers, filters]);

  const sortedUsers = useMemo(() => {
    return filteredUsers.sort((a, b) => b.totalPoints - a.totalPoints);
  }, [filteredUsers]);

  const displayedUsers = sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderPlaceholderRow = () => (
    <TableRow className={classes.placeholderRow}>
      <TableCell colSpan={7} className={classes.loaderCell}>
        <Box className={classes.loaderContainer}>
          <CircularProgress size={34} />
        </Box>
      </TableCell>
    </TableRow>
  );

  const renderNoUsersFoundRow = () => (
    <TableRow className={classes.placeholderRow}>
      <TableCell colSpan={7} className={classes.loaderCell}>
        <Box className={classes.loaderContainer}>
          <Typography>No users found</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );

  useEffect(() => {
    handleReset();
  }, [externalType]);

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom className={classes.subtitle} ref={tableRef}>
        Users
      </Typography>
      <Box className={classes.buttonContainer}>
        <TextField
          label="Search by Nickname or Email"
          variant="outlined"
          value={filters.searchQuery}
          onChange={e => handleFilterChange('searchQuery', e.target.value)}
          className={classes.searchField}
        />
        <Button className={classes.button} onClick={handleFilterOpen} startIcon={<FilterListIcon />}>
          User Filters
        </Button>
        <Button className={classes.downloadButton} onClick={handleDownloadCSV} startIcon={<GetAppIcon />}>
          Download Users CSV
        </Button>
      </Box>
      <Box className={classes.legendContainer}>
        <Box className={classes.legendItem}>
          <FiberManualRecordIcon style={{ color: green[500] }} className={classes.legendIcon} />
          <Typography>Active in the last week</Typography>
        </Box>
        <Box className={classes.legendItem}>
          <FiberManualRecordIcon style={{ color: yellow[700] }} className={classes.legendIcon} />
          <Typography>Active in the last month, but more than a week ago</Typography>
        </Box>
        <Box className={classes.legendItem}>
          <FiberManualRecordIcon style={{ color: red[500] }} className={classes.legendIcon} />
          <Typography>Not active for more than a month</Typography>
        </Box>
      </Box>
      <Box mt={3} className={classes.tableContainer} style={{ marginBottom: 30 }}>
        <Table>
          <TableBody>
            {loading && !users ? (
              renderPlaceholderRow()
            ) : displayedUsers.length > 0 ? (
              <RecentTable tableData={displayedUsers} rowsPerPage={rowsPerPage} />
            ) : (
              renderNoUsersFoundRow()
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {error && <Typography color="error">{String(error)}</Typography>}
      </Box>
      <UserFilterModal
        open={filters.filterOpen}
        onClose={handleFilterClose}
        handleApply={handleApplyFilter}
        filters={filters}
        handleFilterChange={handleFilterChange}
        availableCountries={availableCountries}
        B2BUserType={B2BUserType}
        onReset={handleReset}
        fetchData={fetchData}
      />
    </React.Fragment>
  );
};

export default UsersSection;
