import { CREATE_NEW_RULE, GET_ALL_RULES, EDIT_RULE_STATUS, TOGGLE_SIDEBAR_COLLAPSED } from '../../constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    selectedFolder: 'accepted',
    selectedLabel: '',
    searchText: '',
  },
  ruleList: [],
  languageList: [],
  languages: [],
  newLanguageCallback: null,
  languagePlatforms: [],
  currentLanguage: null,
  totalBars: null,
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }
    case GET_ALL_RULES: {
      return {
        ...state,
        ruleList: action.payload,
      };
    }

    case EDIT_RULE_STATUS: {
      return {
        ...state,
        ruleStatus: action.payload,
      };
    }

    case CREATE_NEW_RULE: {
      return {
        ...state,
        createRuleResponse: action.payload,
      };
    }

    default:
      return state;
  }
};
