import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchPlayers } from 'redux/actions/MatchDetails';

import { Box, TableCell, Table, TableBody, TableHead, TableRow } from '@material-ui/core';

import Players from './Players';

import useStyles from '../index.style';

const Odds = ({ id, matchDetail }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { playersDetail } = useSelector(({ matchDetails }) => matchDetails);

  const [playerHome, setPlayerHome] = useState({});
  const [playerAway, setPlayerAway] = useState({});

  const [positions, setPositions] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    dispatch(getMatchPlayers(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (playersDetail !== undefined) {
      setPlayerHome(playersDetail.homeTeamPlayers);
      setPlayerAway(playersDetail.awayTeamPlayers);
      let newPositions = [];
      playersDetail.homeTeamPlayers.forEach((item, key) => {
        if (item.position?.length > 0) {
          newPositions.push(item.position);
        }
      });

      playersDetail.awayTeamPlayers.forEach((item, key) => {
        if (item.position?.length > 0) {
          newPositions.push(item.position);
        }
      });

      let pos = newPositions.filter((v, i, a) => a.indexOf(v) === i);
      setPositions(pos);

      let newTeams = [];
      newTeams.push({
        id: matchDetail.homeTeamId,
        value: matchDetail.homeTeamCode,
      });
      newTeams.push({
        id: matchDetail.awayTeamId,
        value: matchDetail.awayTeamCode,
      });

      setTeams(newTeams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playersDetail]);
  return (
    playersDetail !== undefined && (
      <Box className={classes.headerContent} style={{ display: 'flex', flexDirection: 'row' }}>
        <Box className={classes.headerContent} style={{ display: 'flex', flexDirection: 'row' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Players
                teamPlayers={playerHome}
                positions={positions}
                team={matchDetail.homeTeamCode}
                selectTeam={teams}
                teamId={matchDetail.homeTeamId}
              />
            </TableBody>
          </Table>
        </Box>
        <Box className={classes.headerContent} style={{ display: 'flex', flexDirection: 'row' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Players
                teamPlayers={playerAway}
                positions={positions}
                team={matchDetail.awayTeamCode}
                selectTeam={teams}
                teamId={matchDetail.awayTeamId}
              />
            </TableBody>
          </Table>
        </Box>
      </Box>
    )
  );
};

export default Odds;
