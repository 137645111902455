import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  TextField,
  Avatar,
  TablePagination,
  IconButton,
  Badge,
} from '@material-ui/core';
import useStyles from '../index.style';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { DeleteOutline } from '@material-ui/icons';
import { addPoll, getPoll, updatePoll } from 'redux/actions/Polls';
import Alert from 'utils/InfoAlertMessage';

const countryList = require('country-list');

const PollDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const countries = countryList.getData();
  const { poll } = useSelector(({ polls }) => polls);
  const { languages } = useSelector(({ languages }) => languages);

  const [titles, setTitles] = useState({});
  const [startDate, setStartDate] = useState();
  const [limitDate, setLimitDate] = useState();
  const [endDate, setEndDate] = useState();

  const [slicedCountries, setSlicedCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [page, setPage] = useState(0);
  const [countriesSectionIsDisplayed, setCountriesSectionIsDisplayed] = useState(false);
  const itemsPerPage = 40;

  const [publishedLanguages, setPublishedLanguages] = useState([]);
  const [numberOfAnswers, setNumberOfAnswers] = useState(['0', '1']);
  const [answers, setAnswers] = useState({});
  const [totalVotes, setTotalVotes] = useState(0);
  const currentDate = new Date().toISOString();

  useEffect(() => {
    if (languages?.length > 0) {
      setPublishedLanguages(languages.filter(item => item.status === 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages]);

  useEffect(() => {
    if (id) {
      dispatch(getPoll(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (poll && id) {
      const formattedTitles = {};
      poll.questionText.forEach(item => {
        formattedTitles[item.language] = item.text;
      });
      setTitles(formattedTitles);
      let formattedAnswers = {};
      const answerIndex = [];
      let votesCount = 0;
      poll.possibleAnswers.forEach(item => {
        answerIndex.push(item._id);
        formattedAnswers[item._id] = item?.answerTranslations[0].answerTranslation;
        votesCount += item?.voteCount;
      });
      setTotalVotes(votesCount);
      setAnswers(formattedAnswers);
      setNumberOfAnswers(answerIndex);

      setStartDate(poll?.startDate);
      setLimitDate(poll?.answerLimitDate);
      setEndDate(poll?.endDate);
      const selectedCountriesFormatted = countries.filter(item => poll?.countries?.includes(item.code));
      setSelectedCountries(selectedCountriesFormatted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poll, id]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    setSlicedCountries(countries.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleStartDate = e => {
    const newDate = new Date(e.target.value).toISOString();
    setStartDate(newDate);
  };

  const handleLimitDate = e => {
    const newDate = new Date(e.target.value).toISOString();
    setLimitDate(newDate);
  };

  const handleEndDate = e => {
    const newDate = new Date(e.target.value).toISOString();
    setEndDate(newDate);
  };

  const addHandler = country => {
    setSelectedCountries(prev => [...prev, country]);
  };

  const removeHandler = country => {
    const filteredCountries = selectedCountries.filter(item => item.code !== country.code);
    setSelectedCountries(filteredCountries);
  };

  const createNotification = message => {
    return NotificationManager.error(message, 'ERROR!', 3000);
  };

  const toggleCountriesSection = () => {
    setCountriesSectionIsDisplayed(prev => !prev);
  };

  const createPollHandler = () => {
    const canBeSubmitted = validateField();
    if (canBeSubmitted) {
      const selectedCountriesCodes = selectedCountries.map(country => {
        return country.code;
      });
      const data = {
        startDate,
        endDate,
        answerLimitDate: limitDate,
        countries: selectedCountriesCodes,
        questionText: Object.keys(titles).map(item => {
          return {
            text: titles[item],
            language: item,
          };
        }),
        possibleAnswers: Object.keys(answers)?.map(item => {
          return {
            answerTranslations: [
              {
                answerTranslation: answers[item],
                language: 'en',
              },
            ],
          };
        }),
      };
      dispatch(addPoll(data));
    }
  };

  const updatePollHandler = () => {
    const canBeSubmitted = validateField();
    if (canBeSubmitted) {
      const selectedCountriesCodes = selectedCountries.map(country => {
        return country.code;
      });
      const data = {
        startDate,
        endDate,
        answerLimitDate: limitDate,
        countries: selectedCountriesCodes,
        questionText: Object.keys(titles).map(item => {
          return {
            text: titles[item],
            language: item,
          };
        }),
        possibleAnswers: Object.keys(answers)?.map(item => {
          return !item.includes('NEW')
            ? {
                answerTranslations: [
                  {
                    answerTranslation: answers[item],
                    language: 'en',
                  },
                ],
                _id: item,
              }
            : {
                answerTranslations: [
                  {
                    answerTranslation: answers[item],
                    language: 'en',
                  },
                ],
              };
        }),
      };
      dispatch(updatePoll(poll._id, data));
    }
  };

  const validateField = () => {
    let canBeSubmitted = true;
    const currentDate = new Date().toISOString();

    if (Object.keys(titles)?.length !== publishedLanguages?.length) {
      createNotification('Please complete all the titles');
      canBeSubmitted = false;
    } else {
      Object.keys(titles).forEach(item => {
        if (!titles[item].trim()) {
          createNotification(`Please complete the title for ${item.toUpperCase()}`);
          canBeSubmitted = false;
        }
      });
    }
    if (!startDate) {
      createNotification('Start date should not be empty!');
      canBeSubmitted = false;
    }
    if (!limitDate) {
      createNotification('Limit date should not be empty!');
      canBeSubmitted = false;
    }
    if (!endDate) {
      createNotification('End date should not be empty!');
      canBeSubmitted = false;
    }
    if (currentDate >= startDate) {
      createNotification('You cannot set a past date for the start date!');
      canBeSubmitted = false;
    }
    if (startDate >= limitDate) {
      createNotification('Start date should be before the limit date!');
      canBeSubmitted = false;
    }
    if (endDate <= limitDate) {
      createNotification('Limit date should be before the end date!');
      canBeSubmitted = false;
    }
    if (startDate >= endDate) {
      createNotification('Start date should be before the end date!');
      canBeSubmitted = false;
    }
    if (Object.keys(answers)?.length !== numberOfAnswers.length) {
      createNotification('Please complete all the answers');
      canBeSubmitted = false;
    } else {
      Object.keys(answers).forEach((item, index) => {
        if (!answers[item].trim()) {
          createNotification(`Please complete the answer ${index + 1}`);
          canBeSubmitted = false;
        }
      });
    }

    return canBeSubmitted;
  };

  const answersHandler = (answer, answerIndex) => {
    setAnswers(prev => {
      return {
        ...prev,
        [answerIndex.toString()]: answer,
      };
    });
  };

  const deleteAnswerHandler = answerIndex => {
    delete answers[answerIndex];
    setAnswers({ ...answers });
    setNumberOfAnswers(prev => prev.filter(item => item.toString() !== answerIndex.toString()));
  };

  const titlesHandler = (title, lang) => {
    titles[lang] = title;
    setTitles({ ...titles });
  };

  return (
    <Box>
      <Box>
        <Box className={classes.row}>
          <Box className={classes.date}>
            <h2>Start date</h2>
            <TextField
              id="datetime-local"
              type="datetime-local"
              onChange={handleStartDate}
              value={startDate ? moment(startDate).format('YYYY-MM-DDTkk:mm') : undefined}
              className={classes.dateInput}
              sx={{ width: 200 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box className={classes.date}>
            <h2>Limit date</h2>
            <TextField
              id="datetime-local"
              type="datetime-local"
              onChange={handleLimitDate}
              value={limitDate ? moment(limitDate).format('YYYY-MM-DDTkk:mm') : undefined}
              className={classes.dateInput}
              sx={{ width: 200 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box className={classes.date}>
            <h2>End date</h2>
            <TextField
              id="datetime-local"
              type="datetime-local"
              onChange={handleEndDate}
              value={endDate ? moment(endDate).format('YYYY-MM-DDTkk:mm') : undefined}
              className={classes.dateInput}
              sx={{ width: 250 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
        <Alert variant={'standard'} style={{ width: '100%' }}>
          The limit date is the maximum date until the user can vote. The end date is the maximum date until the user can see
          the results in the app. Limit date should be before the end date.
        </Alert>

        <Box>
          {publishedLanguages.map((language, index) => {
            return (
              <Box className={classes.row} key={index}>
                <TextField
                  id="title"
                  onChange={e => titlesHandler(e.target.value, language.code)}
                  value={titles[language.code] || ''}
                  className={classes.textFieldHead}
                  label={'Title - ' + language.code.toUpperCase()}
                  variant="outlined"
                  disabled={currentDate > startDate && id}
                />
              </Box>
            );
          })}

          <Box>
            {numberOfAnswers.map((answer, index) => {
              return (
                <Box className={classes.row} key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                  <Badge
                    badgeContent={
                      poll?.possibleAnswers.find(item => item._id === answer)?.voteCount >= 0
                        ? poll?.possibleAnswers.find(item => item._id === answer)?.voteCount +
                          ' votes / ' +
                          (poll?.possibleAnswers.find(item => item._id === answer)?.voteCount * 100) / totalVotes +
                          '%'
                        : undefined
                    }
                    color="primary"
                    style={{ flex: 1 }}>
                    <TextField
                      id="answer"
                      onChange={e => answersHandler(e.target.value, answer)}
                      value={answers?.[answer] || ''}
                      className={classes.textFieldHead}
                      label={'Answer - ' + (+index + 1)}
                      variant="outlined"
                      disabled={currentDate > startDate && id}
                    />
                  </Badge>
                  <IconButton
                    aria-label="delete"
                    style={{ height: 'max-content' }}
                    disabled={index < 2 || (currentDate > startDate && id)}
                    onClick={() => deleteAnswerHandler(answer)}>
                    <DeleteOutline />
                  </IconButton>
                </Box>
              );
            })}
          </Box>

          <Button
            style={{ margin: '25px 5px ' }}
            variant="outlined"
            color="primary"
            disabled={currentDate > startDate && id}
            onClick={() =>
              setNumberOfAnswers(prev => [...prev, `NEW${prev[prev.length - 1]}${Math.floor(Math.random() * 100000)}`])
            }>
            Add answer
          </Button>
        </Box>

        <Box>
          <FormControl
            component="fieldset"
            className={classes.formControl}
            style={{
              width: '100%',
              margin: '5px 0',
              borderBottom: '1px solid black',
              borderTop: '1px solid black',
              padding: '40px 12.5px',
            }}>
            <Box mb={10}>
              <h2 style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                Selected Countries
                {!countriesSectionIsDisplayed ? (
                  <AddCircleOutlineIcon style={{ cursor: 'pointer' }} onClick={toggleCountriesSection} />
                ) : (
                  <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} onClick={toggleCountriesSection} />
                )}
              </h2>
            </Box>
            {!selectedCountries.length && (
              <Alert variant={'standard'}>
                No countries selected. <strong>All the users can see the poll and vote.</strong>
              </Alert>
            )}
            <FormGroup className={classes.countries}>
              {selectedCountries.map((country, index) => {
                return (
                  <Box
                    mr={4}
                    mb={5}
                    p={2}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      border: '1px solid rgba(0,0,0,0.06)',
                    }}
                    key={index}>
                    <Box>
                      <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Box>
                          <Avatar
                            src={'https://flagsapi.com/' + country.code + '/flat/64.png'}
                            variant="square"
                            style={{ width: '30px', height: '20px' }}></Avatar>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <h4 style={{ fontWeight: 'bold' }}>{country.name}</h4>
                      </Box>
                    </Box>
                    <Button
                      style={{ marginLeft: 'auto' }}
                      variant="contained"
                      color="primary"
                      onClick={() => removeHandler(country)}>
                      REMOVE
                    </Button>
                  </Box>
                );
              })}
            </FormGroup>
          </FormControl>
        </Box>
        {countriesSectionIsDisplayed && (
          <Box className={classes.card}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              style={{ width: '100%', margin: '40px 12.5px' }}>
              <Box mb={5}>
                <h2 style={{ fontWeight: 'bold' }}>Choose countries</h2>
              </Box>
              <FormGroup className={classes.countries}>
                {slicedCountries.map((country, index) => {
                  return (
                    <Box
                      mr={4}
                      mb={5}
                      p={2}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        border: '1px solid rgba(0,0,0,0.06)',
                      }}
                      key={index}>
                      <Box>
                        <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                          <Box>
                            <Avatar
                              src={'https://flagsapi.com/' + country.code + '/flat/64.png'}
                              variant="square"
                              style={{ width: '30px', height: '20px' }}></Avatar>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          <h4 style={{ fontWeight: 'bold' }}>{country.name}</h4>
                        </Box>
                      </Box>
                      <Button
                        style={{ marginLeft: 'auto' }}
                        variant="contained"
                        color="primary"
                        onClick={() => addHandler(country)}
                        disabled={selectedCountries.filter(item => item.code === country.code)?.length ? true : false}>
                        Add
                      </Button>
                    </Box>
                  );
                })}
              </FormGroup>
            </FormControl>
            <TablePagination
              component="div"
              style={{ order: '9999', width: '100%', marginBottom: '20px' }}
              count={countries.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              rowsPerPage={itemsPerPage}
            />
          </Box>
        )}
      </Box>
      {id && id !== 'new' ? (
        <Button style={{ margin: '25px 0 ' }} variant="contained" color="primary" onClick={updatePollHandler}>
          Update Poll
        </Button>
      ) : (
        <Button style={{ margin: '25px 0 ' }} variant="contained" color="primary" onClick={createPollHandler}>
          Create Poll
        </Button>
      )}
      <NotificationContainer />
    </Box>
  );
};

export default PollDetails;
