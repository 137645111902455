import { GET_POLLS, GET_POLL } from 'constants/ActionTypes';

const INIT_STATE = {
  polls: null,
  poll: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POLLS:
      return {
        ...state,
        polls: action.payload,
      };
    case GET_POLL:
      return {
        ...state,
        poll: action.payload,
      };
    default:
      return state;
  }
};
