import React, { useState, useEffect } from 'react';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
  getOfficialChampionshipList,
  deleteOfficialChampionshipDetail,
  publishOfficialChampionship,
} from 'redux/actions/OfficialChampionship';

import { useHistory } from 'react-router-dom';

import ListVIew from './ListVIew';
import { GET_OFFICIAL_CHAMPIONSHIPS_DETAILS } from 'constants/ActionTypes';
import { Box } from '@material-ui/core';
import EmptyResult from 'components/Table/EmptyResult';

const OfficialChampionshipList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { officialChampionshipList } = useSelector(({ officialChampionship }) => officialChampionship);

  const [championshipList, setChampionshipList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState();
  const [published, setPublished] = useState(true);
  const [active, setActive] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    setChampionshipList(championshipList.filter((item, key) => item._id !== idDelete));
    let body = {
      challengeId: idDelete,
    };
    dispatch(deleteOfficialChampionshipDetail(body));
    setIdDelete();
  };

  useEffect(() => {
    dispatch(
      getOfficialChampionshipList({
        final: !active,
        published,
      }),
    );
  }, [active, published]);

  useEffect(() => {
    setChampionshipList(officialChampionshipList);
  }, [officialChampionshipList]);

  const removeChampionship = id => {
    setOpen(true);
    setIdDelete(id);
  };

  const publishChampionship = (id, value) => {
    let body = {
      challengeId: id,
      publish: value,
    };
    dispatch(publishOfficialChampionship(body));
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title="Official Championships"
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/official-championship/new');
            dispatch({
              type: GET_OFFICIAL_CHAMPIONSHIPS_DETAILS,
              payload: undefined,
            });
          }}>
          Create
        </Button>
      </CmtCardHeader>
      <Box style={{ paddingLeft: 30, paddingRight: 30, display: 'flex', flexWrap: 'wrap', gap: 5 }}>
        <Button
          variant={!published ? 'contained' : 'outlined'}
          color={published ? 'default' : 'primary'}
          onClick={() => {
            setPublished(false);
          }}>
          Not published
        </Button>
        <Button
          variant={published ? 'contained' : 'outlined'}
          color={!published ? 'default' : 'primary'}
          onClick={() => {
            setPublished(true);
          }}>
          Published
        </Button>
        {published && (
          <>
            <Button
              variant="outlined"
              color={active ? 'secondary' : 'default'}
              onClick={() => {
                setActive(true);
              }}>
              Active
            </Button>
            <Button
              variant="outlined"
              color={!active ? 'secondary' : 'default'}
              onClick={() => {
                setActive(false);
              }}>
              Ended
            </Button>
          </>
        )}
      </Box>
      <CmtCardContent className={classes.cardContentRoot}>
        {championshipList !== undefined && championshipList.length > 0 ? (
          <ListVIew
            publishChampionship={publishChampionship}
            removeChampionship={removeChampionship}
            championshipList={championshipList}
          />
        ) : null}

        {championshipList?.length === 0 && <EmptyResult />}
      </CmtCardContent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Official Championship?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </CmtCard>
  );
};

export default OfficialChampionshipList;
