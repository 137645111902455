import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import SelectTeams from './SelectTeams';
import TeamsListing from './TeamsListing';
import SelectCompetitions from './SelectCompetitions';
import CompetitionsListing from './CompetitionsListing';
import { useDispatch, useSelector } from 'react-redux';
import { createTemplate, deleteTemplate, updateTemplate } from 'redux/actions/Selections';
import { useParams } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';

const Teams = ({
  setSelectedTeams,
  selectedTeams,
  setSelectedCompetitions,
  selectedCompetitions,
  automationDays,
  setAutomationDays,
}) => {
  const myRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [templatesPopupIsOpen, setTemplatesPopupIsOpen] = useState(false);
  const [deleteTemplatePopupIsOpen, setDeleteTemplatePopupIsOpen] = useState(false);
  const [type, setType] = useState();
  const [name, setName] = useState('');
  const [templateToDelete, setTemplateToDelete] = useState();
  const [selectedTeamTemplate, setSelectedTeamTemplate] = useState();
  const [selectedCompetitionTemplate, setSelectedCompetitionTemplate] = useState();
  const { teamsTemplates, matchesTemplates } = useSelector(({ selections }) => selections);
  const templates = type === 1 ? matchesTemplates : type === 2 ? teamsTemplates : [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
  };

  const createTemplateHandler = () => {
    let ids;
    if (type === 1) {
      ids = selectedTeams.map(item => item._id);
    } else if (type === 2) {
      ids = selectedCompetitions.map(item => item._id);
    }
    const body = {
      name,
      type,
      ids,
    };
    dispatch(createTemplate(body));
    handleClose();
  };

  const deleteTemplateHandler = () => {
    if (templateToDelete?._id) {
      dispatch(deleteTemplate(templateToDelete?._id, templateToDelete?.type));
      setDeleteTemplatePopupIsOpen(false);
      setTemplateToDelete();
    }
  };

  const selectTemplateHandler = template => {
    if (template?.type === 1) {
      setSelectedTeams(template?.teams);
      setSelectedTeamTemplate(template);
    } else if (template?.type === 2) {
      setSelectedCompetitions(template?.competitions);
      setSelectedCompetitionTemplate(template);
    }
    setTemplatesPopupIsOpen(false);
  };

  const updateTemplateHandler = () => {
    let body;
    let id;
    if (type === 1) {
      const ids = selectedTeams.map(item => item._id);
      body = {
        name: selectedTeamTemplate?.name,
        type: selectedTeamTemplate?.type,
        ids,
      };
      id = selectedTeamTemplate?._id;
    } else if (type === 2) {
      id = selectedCompetitionTemplate?._id;
      const ids = selectedCompetitions.map(item => item._id);
      body = {
        name: selectedCompetitionTemplate?.name,
        type: selectedCompetitionTemplate?.type,
        ids,
      };
    }

    dispatch(updateTemplate(body, id));
    handleClose();
  };

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  return (
    <Box>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Automation</h2>
        <Button variant="contained" color="primary" onClick={executeScroll}>
          Add Teams
        </Button>
        <Button variant="contained" color="primary" onClick={executeScroll} style={{ marginLeft: 20 }}>
          Add Competitions
        </Button>
      </Box>
      <Box>
        <TextField
          id="days"
          onChange={e => setAutomationDays(e.target.value)}
          value={automationDays}
          label="Number of days in advance that the matches are added"
          variant="outlined"
          style={{ marginBottom: 30, width: '50%' }}
        />
      </Box>
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ marginLeft: '7.5px' }}>Teams</h3>
          <Box>
            {id === 'new' && selectedTeams?.length > 0 && !selectedTeamTemplate && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleClickOpen();
                  setType(1);
                }}>
                Save As Template
              </Button>
            )}
            {id === 'new' && selectedTeamTemplate && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    updateTemplateHandler();
                    setType(1);
                  }}>
                  Update Template
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setSelectedTeamTemplate(undefined);
                    setSelectedTeams([]);
                  }}>
                  Remove Template
                </Button>
              </>
            )}
            {id === 'new' && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setTemplatesPopupIsOpen(true);
                  setType(1);
                }}>
                Start from template
              </Button>
            )}
          </Box>
        </Box>
        {selectedTeams.length > 0 ? (
          <TeamsListing selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} />
        ) : (
          <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no selected teams.</h3>
        )}
        <hr />
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          <h3 style={{ marginLeft: '7.5px', marginTop: '15px' }}>Competitions</h3>
          <Box>
            {id === 'new' && selectedCompetitions?.length > 0 && !selectedCompetitionTemplate && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleClickOpen();
                  setType(2);
                }}>
                Save As Template
              </Button>
            )}
            {id === 'new' && selectedCompetitionTemplate && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    updateTemplateHandler();
                    setType(2);
                  }}>
                  Update Template
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setSelectedCompetitions([]);
                    setSelectedCompetitionTemplate(undefined);
                  }}>
                  Remove Template
                </Button>
              </>
            )}
            {id === 'new' && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setTemplatesPopupIsOpen(true);
                  setType(2);
                }}>
                Start from template
              </Button>
            )}
          </Box>
        </Box>
        {selectedCompetitions.length > 0 ? (
          <CompetitionsListing
            selectedCompetitions={selectedCompetitions}
            setSelectedCompetitions={setSelectedCompetitions}
          />
        ) : (
          <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no selected competitions.</h3>
        )}
      </Box>
      <Box ref={myRef}>
        <SelectTeams setSelectedTeams={setSelectedTeams} selectedTeams={selectedTeams} />
      </Box>
      <Box ref={myRef}>
        <SelectCompetitions selectedCompetitions={selectedCompetitions} setSelectedCompetitions={setSelectedCompetitions} />
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create new template</DialogTitle>
        <DialogContent>
          <DialogContentText>Please complete the name for the template</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={createTemplateHandler} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={templatesPopupIsOpen}
        onClose={() => setTemplatesPopupIsOpen(false)}
        aria-labelledby="form-dialog-title"
        scroll="paper">
        <DialogTitle id="form-dialog-title">Templates</DialogTitle>
        <DialogContent>
          <DialogContentText>Please select a template</DialogContentText>

          <List>
            {templates?.map(item => {
              return (
                <div onClick={() => selectTemplateHandler(item)}>
                  <ListItem
                    key={item._id}
                    style={{
                      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
                      marginBottom: 20,
                      cursor: 'pointer',
                    }}>
                    <ListItemText primary={item.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          setDeleteTemplatePopupIsOpen(true);
                          setTemplateToDelete(item);
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTemplatesPopupIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => selectTemplateHandler(false)} color="primary">
            Select
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteTemplatePopupIsOpen}
        onClose={() => setDeleteTemplatePopupIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete template</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete <strong>'{templateToDelete?.name}'</strong> template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteTemplatePopupIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteTemplateHandler} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default Teams;
