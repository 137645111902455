//For expanding sidebar
import {
  ADD_LABEL,
  CREATE_BAR,
  DELETE_BAR,
  DELETE_LABEL_ITEM,
  GET_BAR_COUNTS,
  GET_BARS_LIST,
  GET_LABELS_LIST,
  SET_CURRENT_BAR,
  SET_FILTER_TYPE,
  TOGGLE_SIDEBAR_COLLAPSED,
  UPDATE_BAR,
  UPDATE_BAR_LABEL,
  UPDATE_LABEL_ITEM,
  UPDATE_BAR_STARRED_STATUS,
} from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

let timer;

//For expanding sidebar
export const toggleExpandSidebar = value => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_COLLAPSED,
      payload: value,
    });
  };
};

//For setting Filtertype
export const setFilterType = filterType => {
  return {
    type: SET_FILTER_TYPE,
    payload: filterType,
  };
};

//for getting labels list
export const getLabelsList = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/bar/labels')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_LABELS_LIST, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for adding new label
export const addNewLabel = label => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/bar/labels', { label })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_LABEL, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//For Deleting Label
export const deleteLabel = labelId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/bar/labels/delete', { labelId })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: DELETE_LABEL_ITEM, payload: labelId });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//For Editing Label
export const updateLabel = label => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/bar/labels', { label })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_LABEL_ITEM, payload: label });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for getting bars list
export const getBarsList = params => {
  return dispatch => {
    dispatch(fetchStart());

    // Prevent to many calls on api when search
    const delay = 0;
    clearTimeout(timer);
    timer = setTimeout(() => {
      axios
        .get('/bars/admin?offset=0&limit=100')
        .then(data => {
          dispatch(fetchSuccess());
          dispatch({ type: GET_BARS_LIST, payload: data.data });
          dispatch({ type: GET_BAR_COUNTS, payload: data.data.length });
        })
        .catch(error => {
          if (error.response?.status !== 401) {
            dispatch(fetchError('Something went wrong'));
          }
        });
    }, delay);
  };
};

export const setCurrentBar = bar => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_BAR,
      payload: bar,
    });
  };
};

//for creating new bar
export const createBar = bar => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/bars', bar)
      .then(data => {
        dispatch(getBarsList());
        dispatch({ type: CREATE_BAR, payload: data.data });
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

//for updating bar through detail page
export const onUpdateBar = bar => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/bars', { bar })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_BAR, payload: bar });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for updating bars starred status(through listing)
export const updateStarredStatus = (barIds, status) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/bar/update-starred', { barIds, status })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_BAR_STARRED_STATUS,
          payload: { barIds, status },
        });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for updating mails folder(through listing)
export const deleteBar = barIds => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/bar/delete', { barIds })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: DELETE_BAR, payload: barIds });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for updating bars label(through listing)
export const updateBarsLabel = (barIds, label) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/bar/update-label', { barIds, label })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_BAR_LABEL, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for getting bar categories(in sidebar) count
export const getBarCounts = () => {
  return dispatch => {
    axios
      .get('/bar/counter')
      .then(data => {
        dispatch({ type: GET_BAR_COUNTS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
