import React, { useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core';
import CmtMediaObject from '../../../@coremat/CmtMediaObject';
import Avatar from '@material-ui/core/Avatar';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
}));

const TableItem = ({ row }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <Avatar alt={row.name} src={row.bgImage} />
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <CmtMediaObject
          avatarPos="center"
          title={row.name}
          titleProps={{
            variant: 'h5',
            className: classes.titleRoot,
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        Matches: <strong>{row.games.length}</strong>
        <br />
        Outrights: <strong>{row.competitions.length}</strong>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <IconButton
          color="primary"
          aria-label="edit selection"
          component="span"
          onClick={() => {
            history.push('/selection/' + row._id);
          }}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
