import React, { useState, useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import SelectSelections from './SelectSelections';
import SelectionCell from './SelectionCell';
import { useParams } from 'react-router';

const Selection = ({ selections, getSelection, viewDetail }) => {
  const { id } = useParams();
  const [selectionsList, setSelectionsList] = useState([]);
  const [selectionsListDetail, setSelectionsListDetail] = useState([]);
  const [selectionsToInsert, setSelectionsToInsert] = useState([]);
  const [selectionsToRemove, setSelectionsToRemove] = useState([]);
  const myRef = useRef(null);

  const selectSelection = selection => {
    let difSelection = true;

    selectionsListDetail.forEach((selection, key) => {
      if (selection._id === id) {
        difSelection = false;
      }
    });

    if (difSelection === true && selectionsToInsert.length < 1) {
      setSelectionsToInsert(selectionsToInsert => [...selectionsToInsert, selection._id]);
      setSelectionsToRemove(selectionsToRemove.filter((item, key) => item !== selection._id));
      setSelectionsListDetail(selectionsListDetail => [...selectionsListDetail, selection]);
      setSelectionsList(selectionsList => [...selectionsList, selection._id]);
      sendSelections(
        [...selectionsList, selection._id],
        [...selectionsToInsert, selection._id],
        selectionsToRemove.filter((item, key) => item !== selection._id),
      );
    }
  };

  const sendSelections = (currentList, toBeInserted, toBeRemoved) => {
    const tempCurrentList = [],
      tempToBeInserted = [],
      tempToBeRemoved = [];

    currentList.forEach(listItem => {
      let tempItem = selections.find(item => item._id === listItem);
      tempCurrentList.push(tempItem._id);
    });
    toBeInserted.forEach(listItem => {
      let tempItem = selections.find(item => item._id === listItem);
      tempToBeInserted.push(tempItem._id);
    });
    toBeRemoved.forEach(listItem => {
      let tempItem = selections.find(item => item._id === listItem);
      tempToBeRemoved.push(tempItem._id);
    });
    getSelection(tempCurrentList, tempToBeInserted, tempToBeRemoved);
  };

  const removeSelection = id => {
    setSelectionsList(selectionsList.filter((item, key) => item !== id));
    setSelectionsListDetail(selectionsListDetail.filter((item, key) => item._id !== id));
    setSelectionsToInsert(selectionsToInsert.filter((item, key) => item !== id));
    setSelectionsToRemove(selectionsToRemove => [...selectionsToRemove, id]);
    sendSelections(
      selectionsList.filter((item, key) => item !== id),
      selectionsToInsert.filter((item, key) => item !== id),
      [...selectionsToRemove, id],
    );
  };

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  return (
    <Box>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Selections</h2>
        <Button variant="contained" color="primary" onClick={executeScroll}>
          Add
        </Button>
      </Box>
      <Box>
        {selectionsListDetail !== undefined && selectionsListDetail.length > 0 ? (
          <SelectionCell selectedSelections={selectionsListDetail} onRemove={removeSelection} />
        ) : (
          <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no selected selections. Please add one.</h3>
        )}
      </Box>

      <Box ref={myRef}>
        <SelectSelections
          selection={selections}
          selectSelection={selectSelection}
          selectedSelections={selectionsListDetail}
        />
      </Box>
    </Box>
  );
};

export default Selection;
