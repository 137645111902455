import React, { useState, useEffect, useRef } from 'react';
import {
  TableCell,
  TableRow,
  Button,
  makeStyles,
  MenuItem,
  Select,
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  alpha,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGameUsers, fetchGameUsersCSV } from '../../../../redux/actions/Analytics';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
  },
  dropdown: {
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
    borderRadius: '4px',
    boxShadow: theme.shadows[2],
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 1000,
    width: '100%',
    maxHeight: 0,
    overflow: 'hidden',
    opacity: 0,
    transform: 'scaleY(0)',
    transformOrigin: 'top',
    transition: 'max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease',
  },
  dropdownOpen: {
    maxHeight: '300px',
    opacity: 1,
    transform: 'scaleY(1)',
    overflowY: 'auto',
  },
  userTable: {
    width: '100%',
    '& th, & td': {
      padding: '8px 12px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    },
    '& th': {
      backgroundColor: theme.palette.grey[200],
      fontWeight: 'bold',
    },
  },
  noDataMessage: {
    padding: '12px',
    textAlign: 'center',
  },
  downloadButton: {
    marginTop: '10px',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const GamesTableItem = ({ row, externalType, dropdownOpenId, setDropdownOpenId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const isMounted = useRef(false);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    isMounted.current = true;
    if (row.countryBets.length === 1) {
      setSelectedCountry(row.countryBets[0].country);
    } else {
      setSelectedCountry('');
    }

    return () => {
      isMounted.current = false;
    };
  }, [row]);

  const isOpen = dropdownOpenId === row.gameId;

  const handleCountryChange = event => {
    setSelectedCountry(event.target.value);
    setDropdownOpenId(row.gameId);
  };

  const handleInfoClick = () => {
    if (selectedCountry) {
      if (isOpen) {
        setDropdownOpenId(null);
      } else {
        setDropdownOpenId(row.gameId);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen && selectedCountry && isMounted.current) {
        try {
          const selectedExternalType = externalType || row.externalType;

          await dispatch(fetchGameUsers(row.gameId, selectedCountry, selectedExternalType, row.countryBets));
        } catch (error) {
          console.error('Error fetching game users:', error);
        }
      }
    };

    fetchData();
  }, [isOpen, selectedCountry, dispatch, row.gameId, row.externalType, row.countryBets, externalType]);

  const handleDownloadCSV = () => {
    if (selectedCountry) {
      dispatch(fetchGameUsersCSV(row.gameId, selectedCountry, row.externalType));
    }
  };

  const gameUsersData = useSelector(state => state.analytics.gameUsers[row.gameId]);

  const handleClickOutside = event => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(`.${classes.downloadButton}`)
    ) {
      setDropdownOpenId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, setDropdownOpenId]);

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>{row.gameHeadline}</TableCell>
      <TableCell className={classes.tableCellRoot}>{new Date(row.date).toLocaleDateString()}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row.betsPlaced}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Select value={selectedCountry} onChange={handleCountryChange} displayEmpty fullWidth>
          <MenuItem value="" disabled>
            Select Country
          </MenuItem>
          <MenuItem value="ALL">All ({row.countryBets.reduce((total, cb) => total + cb.bets, 0)})</MenuItem>
          {row.countryBets.map((cb, index) => (
            <MenuItem key={index} value={cb.country}>
              {cb.country} ({cb.bets})
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Button
          onClick={handleInfoClick}
          variant="contained"
          color="primary"
          disabled={!selectedCountry}
          startIcon={<InfoIcon />}>
          Information
        </Button>

        <Box ref={dropdownRef} className={`${classes.dropdown} ${isOpen ? classes.dropdownOpen : ''}`}>
          {gameUsersData && gameUsersData.length > 0 ? (
            <Table className={classes.userTable}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Nickname</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gameUsersData.map(user => (
                  <TableRow key={user._id}>
                    <TableCell>{user._id}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.nickname}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography className={classes.noDataMessage}>No users found.</Typography>
          )}
          <Box className={classes.downloadButton}>
            <Button onClick={handleDownloadCSV} variant="contained" color="primary" startIcon={<GetAppIcon />} fullWidth>
              Download CSV
            </Button>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default GamesTableItem;
