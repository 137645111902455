import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from 'redux/actions/Users';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import GlobalMessages from 'assets/global/globalMessages';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    '& input': {
      minWidth: '120px',
    },
  },
  formControlWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  formControlExpandButton: {
    color: '#b8b8b8',
    cursor: 'pointer',
    transition: '100ms',
    '&:hover': {
      color: '#303030',
    },
  },
  expandedSection: {
    marginLeft: '1.5rem',
  },
  error: {
    marginTop: '10px',
    color: 'red',
  },
  formErrors: {
    color: 'red',
    marginLeft: '1rem',
  },
  titleContainer: {
    '& h2': {
      fontSize: '18px',
    },
  },
}));

const AddUser = ({ open, onClose, action, editUser }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      username: '',
      email: '',
      password: '',
    },
  });
  const { usersPermissions, userDetail } = useSelector(({ users }) => users);
  const [permissions, setPermissions] = useState([]);

  const [allRoutesChecked, setAllRoutesChecked] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (action === 'add' && usersPermissions) {
      setPermissions(
        Object.entries(usersPermissions).map(([key, value]) => ({
          category: key,
          methods: value.map(el => ({ ...el, access: false })),
          expanded: false,
          checked: false,
        })),
      );
    }
    if (action === 'edit' && usersPermissions && userDetail) {
      if (userDetail.permissions) {
        let categories = {};

        userDetail.permissions.forEach(perm => {
          const urlParts = perm.path.split('/');
          if (!categories[urlParts[1]]) {
            categories[urlParts[1]] = [perm];
          } else {
            categories[urlParts[1]].push(perm);
          }
        });

        let newPermissions = Object.entries(categories).map(([key]) => ({
          category: key,
          methods: categories[key],
          expanded: false,
          checked: categories[key].every(cat => cat.access),
        }));

        setPermissions(newPermissions);
      }
    }
  }, [action, userDetail, usersPermissions]);

  const handleCheckAllRoutes = () => {
    setAllRoutesChecked(!allRoutesChecked);

    let newPermissions = [...permissions];
    newPermissions.forEach(cat => {
      cat.checked = !allRoutesChecked;
      cat.methods.forEach(method => (method.access = !allRoutesChecked));
    });
    setPermissions(newPermissions);
  };

  const handleExpand = category => {
    let categoryIndex = permissions.findIndex(el => el.category === category);
    let newPermissions = [...permissions];
    let selectedPermission = { ...permissions[categoryIndex] };
    newPermissions[categoryIndex] = { ...permissions[categoryIndex], expanded: !selectedPermission.expanded };
    setPermissions(newPermissions);
  };

  const handleCategoryChecked = category => {
    let newPermissions = [...permissions];
    let categoryIndex = newPermissions.findIndex(el => el.category === category);
    let selectedCategory = { ...newPermissions[categoryIndex] };
    newPermissions[categoryIndex] = { ...newPermissions[categoryIndex], checked: !selectedCategory.checked };
    newPermissions[categoryIndex].methods.forEach(method => (method.access = !!newPermissions[categoryIndex].checked));
    setPermissions(newPermissions);
  };

  const handleSubCategoryChecked = (category, method, path) => {
    let newPermissions = [...permissions];
    let categoryIndex = newPermissions.findIndex(el => el.category === category);
    let selectedCategory = { ...newPermissions[categoryIndex] };
    let methodIndex = selectedCategory.methods.findIndex(el => el.path === path && el.method === method);
    let selectedMethod = selectedCategory.methods[methodIndex];
    newPermissions[categoryIndex].methods[methodIndex].access = !selectedMethod.access;

    let methodsSelected = newPermissions[categoryIndex].methods.some(method => method.access);

    if (methodsSelected) {
      newPermissions[categoryIndex] = { ...newPermissions[categoryIndex], checked: methodsSelected };
    } else {
      newPermissions[categoryIndex] = { ...newPermissions[categoryIndex], checked: false };
    }

    setPermissions(newPermissions);
  };

  const handleClose = () => {
    onClose();
  };

  const onSubmit = data => {
    let arr = [];
    permissions.forEach(cat => arr.push(cat.methods));
    let routes = [].concat(...arr);

    if (action === 'add') {
      const dataForApi = {
        name: data.name,
        username: data.username,
        email: data.email,
        password: data.password,
        routes: routes,
      };
      dispatch(createUser(dataForApi));
    } else {
      editUser(routes);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={'paper'}>
      <DialogTitle className={classes.titleContainer}>
        {action === 'add' ? 'Create admin user' : 'Update admin user'}
      </DialogTitle>
      <DialogContent dividers={true} style={{ minWidth: '600px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {action !== 'edit' ? (
            <>
              <FormControl className={classes.formControl}>
                <Controller
                  name="name"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <TextField size="small" variant="outlined" label="Name" {...field} />}
                />
                {errors.name && <FormLabel className={classes.error}>{GlobalMessages.invalidName}</FormLabel>}
              </FormControl>
              <FormControl className={classes.formControl}>
                <Controller
                  name="username"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField size="small" type="text" variant="outlined" label="Username" {...field} />
                  )}
                />
                {errors.username && <FormLabel className={classes.error}>{GlobalMessages.invalidUsername}</FormLabel>}
              </FormControl>
              <FormControl className={classes.formControl}>
                <Controller
                  name="email"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TextField size="small" type="email" variant="outlined" label="Email" inputRef={ref} {...field} />
                  )}
                />
                {errors.email && <FormLabel className={classes.error}>{GlobalMessages.invalidEmail}</FormLabel>}
              </FormControl>

              <FormControl className={classes.formControl}>
                <Controller
                  name="password"
                  rules={{ required: true, pattern: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }}
                  control={control}
                  render={({ field }) => (
                    <TextField size="small" type="password" variant="outlined" label="Password" {...field} />
                  )}
                />
                {errors.password && <FormLabel className={classes.error}>{GlobalMessages.invalidPassword}</FormLabel>}
              </FormControl>
            </>
          ) : null}
          <FormControl style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} className={classes.formControl}>
            <FormControlLabel
              checked={allRoutesChecked}
              onChange={handleCheckAllRoutes}
              control={<Checkbox name="checkedB" color="primary" />}
              label="All routes"
            />
          </FormControl>

          {permissions &&
            permissions.map(permission => {
              return (
                <div key={permission.category}>
                  <div className={classes.formControlWrapper}>
                    <FormControl className={classes.formControl}>
                      <FormControlLabel
                        onChange={() => {
                          handleCategoryChecked(permission.category);
                        }}
                        checked={permission.checked}
                        control={<Checkbox name="checkedB" color="primary" />}
                        label={permission.category}
                      />
                    </FormControl>
                    <span onClick={() => handleExpand(permission.category)} className={classes.formControlExpandButton}>
                      {permission.expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </span>
                  </div>
                  <div
                    className={classes.expandedSection}
                    style={permission.expanded ? { display: 'block' } : { display: 'none' }}>
                    {permission.methods.map(el => {
                      return (
                        <FormControl key={`${el.method}${el.path}`} className={classes.formControl}>
                          <FormControlLabel
                            onChange={() => {
                              handleSubCategoryChecked(permission.category, el.method, el.path);
                            }}
                            checked={el.access}
                            control={<Checkbox name="checkedB" color="primary" />}
                            label={`${el.method} - ${el.path}`}
                          />
                        </FormControl>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </form>
      </DialogContent>
      <DialogActions>
        {Object.keys(errors).length !== 0 ? <span className={classes.formErrors}>{GlobalMessages.formErrors}</span> : null}
        <div>
          <Button onClick={handleClose} style={{ marginLeft: '15px' }} color="primary">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary">
            {action === 'add' ? 'Create' : 'Update'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;
