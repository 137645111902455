import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { Box, Button, TablePagination, Tooltip } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';

import useStyles from './../index.style';
import SettingsIcon from '@material-ui/icons/Settings';

const MatchCell = ({ selectedMatches, onRemove }) => {
  const classes = useStyles();
  const rowsPerPage = 10;
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [page, setPage] = useState(0);
  const [slicedMatches, setSlicedMatches] = useState();

  useEffect(() => {
    let filtered = selectedMatches.sort((match1, match2) => {
      if (match1?.game !== undefined)
        return new Date(match2?.game?.scheduledDate).getTime() - new Date(match1?.game?.scheduledDate).getTime();
      else return new Date(match2?.scheduledDate).getTime() - new Date(match1?.scheduledDate).getTime();
    });
    setFilteredMatches(filtered);
  }, [selectedMatches]);

  useEffect(() => {
    setSlicedMatches(filteredMatches?.slice(0, 10));
  }, [filteredMatches]);

  const handleChangePage = (e, newPage) => {
    setSlicedMatches(filteredMatches?.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  return (
    <Box className={classes.selectedMatchesList}>
      {slicedMatches?.map((match, id) => {
        return (
          <Box key={id} className={classes.selectListMatchRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  mr={4}
                  style={{
                    width: '30px',
                    overflow: 'hidden',
                    marginRight: '0',
                    borderRight: '1px solid #fff',
                  }}>
                  <CmtAvatar size={60} src={match.homeTeamEmblemUrl} alt={match.headline} />
                </Box>
                <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                  <CmtAvatar
                    size={60}
                    style={{ transform: 'translateX(-30px)' }}
                    src={match.awayTeamEmblemUrl}
                    alt={match.headline}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <h3 style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 5 }}>
                  {match.headline}{' '}
                  {match?.autoAdded && (
                    <Tooltip title="Auto-added match">
                      <SettingsIcon />
                    </Tooltip>
                  )}
                </h3>
              </Box>
              <Box>{Moment(match.scheduledDate).format('H:mm - D MMM yyyy')}</Box>
              <Box>{match.competitionName}</Box>
            </Box>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              disabled={match.status === 'Ended'}
              onClick={() => onRemove(match._id)}>
              Remove
            </Button>
          </Box>
        );
      })}
      <TablePagination
        component="div"
        style={{ order: '9999', width: '100%' }}
        count={selectedMatches?.length || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10]}
        rowsPerPage={10}
      />
    </Box>
  );
};

export default MatchCell;
