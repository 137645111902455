import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPopUps } from 'redux/actions/PopUps';
import { Table, TableBody } from '@material-ui/core';
import TableItem from './TableItem';
import TableHeading from 'components/Table/TableHeading';
import TableHeader from 'assets/global/globalTableHeadingData';
import EmptyResult from 'components/Table/EmptyResult';

const PopUpsListing = () => {
  const dispatch = useDispatch();
  const { popUps } = useSelector(({ popUps }) => popUps);

  useEffect(() => {
    dispatch(getAllPopUps());
  }, []);

  return (
    <>
      <Table>
        <TableHeading headingData={TableHeader.PopUps} />
        {popUps?.length ? (
          <TableBody>
            {popUps?.map((row, index) => (
              <TableItem key={row._id} row={row} index={index} />
            ))}
          </TableBody>
        ) : null}
      </Table>
      {!popUps?.length && <EmptyResult />}
    </>
  );
};

export default PopUpsListing;
