import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    '& input': {
      minWidth: '120px',
    },
  },
  formControlWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  formControlExpandButton: {
    color: '#b8b8b8',
    cursor: 'pointer',
    transition: '100ms',
    '&:hover': {
      color: '#303030',
    },
  },
  expandedSection: {
    marginLeft: '1.5rem',
  },
  error: {
    marginTop: '10px',
    color: 'red',
  },
  formErrors: {
    color: 'red',
    marginLeft: '1rem',
  },
  titleContainer: {
    '& h2': {
      fontSize: '18px',
    },
  },
  section: {
    marginTop: '1.5rem',
  },
  title: {
    fontSize: '0.9rem',
  },
}));

export default useStyles;
