import React, { useState, useEffect, useRef } from 'react';
import { Box, Switch, Button } from '@material-ui/core';
import SelectCountries from './SelectCountries';
import CountryCell from './CountryCell';

const Countries = ({ allowedCountriesChampionship, invitedCountriesChampionship, getCountries, viewDetail }) => {
  const myRefCountries = useRef(null);

  const [allowedCountries, setAllowedCountries] = useState([]);
  const [invitedCountries, setInvitedCountries] = useState([]);
  const [countriesListForAllowedVisible, setCountriesListForAllowedVisible] = useState(false);
  const [countriesListForInvitedVisible, setCountriesListForInvitedVisible] = useState(false);
  const [existingAllowedCountries, setExistingAllowedCountries] = useState();
  const [existingInvitedCountries, setExistingInvitedCountries] = useState();
  const autoAddedRef = useRef();
  const invitedRef = useRef();

  useEffect(() => {
    getCountries(allowedCountries, invitedCountries);
  }, [allowedCountries, invitedCountries]);

  useEffect(() => {
    let existingCountries = [];
    viewDetail.forEach(item => {
      if (invitedCountriesChampionship?.includes(item.code)) existingCountries.push(item);
    });
    setInvitedCountries(existingCountries);
    setExistingInvitedCountries(existingCountries);
  }, [viewDetail, invitedCountriesChampionship]);

  useEffect(() => {
    let existingCountries = [];
    viewDetail.forEach(item => {
      if (allowedCountriesChampionship?.includes(item.code)) existingCountries.push(item);
    });
    setAllowedCountries(existingCountries);
    setExistingAllowedCountries(existingCountries);
  }, [viewDetail, allowedCountriesChampionship]);

  const executeScroll = ref => ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  return (
    <Box>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <h2 style={{ marginRight: '25px' }}>Countries where users are auto-added</h2>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={() => {
              setCountriesListForAllowedVisible(prev => !prev);
              setTimeout(() => {
                executeScroll(autoAddedRef);
              }, 100);
            }}>
            ADD
          </Button>
        </Box>
      </Box>
      <Box>
        {allowedCountries !== undefined && allowedCountries.length > 0 ? (
          <CountryCell selectedCountries={allowedCountries} />
        ) : null}
      </Box>
      {countriesListForAllowedVisible && (
        <Box ref={autoAddedRef}>
          <SelectCountries
            country={viewDetail}
            existingCountries={existingAllowedCountries}
            selectedCountries={allowedCountries}
            addFunction={setAllowedCountries}
            countriesToCheck={invitedCountries}
          />
        </Box>
      )}

      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <h2 style={{ marginRight: '25px' }}>Countries where users are invited</h2>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={() => {
              setCountriesListForInvitedVisible(prev => !prev);
              setTimeout(() => {
                executeScroll(invitedRef);
              }, 100);
            }}>
            ADD
          </Button>
        </Box>
      </Box>
      <Box>
        {invitedCountries !== undefined && invitedCountries.length > 0 ? (
          <CountryCell selectedCountries={invitedCountries} />
        ) : (
          <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>
            Users from all countries are invited, except from auto-added countries.
          </h3>
        )}
      </Box>
      {countriesListForInvitedVisible && (
        <Box ref={invitedRef}>
          <SelectCountries
            country={viewDetail}
            existingCountries={existingInvitedCountries}
            selectedCountries={invitedCountries}
            addFunction={setInvitedCountries}
            countriesToCheck={allowedCountries}
          />
        </Box>
      )}
    </Box>
  );
};

export default Countries;
