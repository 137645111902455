import React, { useEffect, useState } from 'react';
import { Box, Button, TablePagination, FormControl, InputLabel, Input, Avatar } from '@material-ui/core';
import useStyles from '../index.style';
import { useDispatch, useSelector } from 'react-redux';
import { getTeams } from 'redux/actions/Teams';

const CompetitionSearch = ({ setSearchQuery, searchQuery }) => {
  const { loading } = useSelector(({ common }) => common);
  return (
    <FormControl style={{ width: '100%', display: 'block' }}>
      <Box style={{ display: 'inline-block' }}>
        <InputLabel htmlFor="input-with-icon-adornment">Search for Teams</InputLabel>
        <Input
          style={{ marginRight: '20px' }}
          id="input-with-icon-adornment"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
          disabled={loading}
        />
      </Box>
    </FormControl>
  );
};
const rowsPerPage = 10;

const SelectTeams = ({ setSelectedTeams, selectedTeams }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { teamsList } = useSelector(({ teams }) => teams);
  const [page, setPage] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getTeams({ offset: newPage * rowsPerPage, limit: rowsPerPage, name: searchQuery }));
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      setPage(0);
      dispatch(getTeams({ offset: 0, limit: rowsPerPage, name: searchQuery }));
    }, 800);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <Box className={classes.selectListMatch}>
      <Box style={{ marginBottom: '20px', display: 'flex' }}>
        <CompetitionSearch classes={classes} setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      </Box>
      <Box className={classes.selectListMatchContainer}>
        {teamsList?.teams?.length > 0 &&
          teamsList?.teams?.map(team => {
            return (
              <Box key={team._id} className={classes.selectListMatchRow}>
                <Box>
                  <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Avatar alt={team.name} src={team.emblemUrl} variant="square" />
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <h3 style={{ fontWeight: 'bold' }}>{team.name}</h3>
                  </Box>
                  <Box>Sport - {team.sport.name}</Box>
                </Box>
                <Button
                  style={{ marginLeft: 'auto' }}
                  variant="contained"
                  color="primary"
                  onClick={() => setSelectedTeams(prev => [...prev, team])}
                  disabled={selectedTeams?.find(item => item._id === team._id)}>
                  ADD
                </Button>
              </Box>
            );
          })}
        {teamsList?.count && (
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={teamsList?.count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};
export default SelectTeams;
