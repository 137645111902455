import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getAppSidebarHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectSport } from 'redux/actions/Teams';
import { getAvailableSports } from 'redux/actions/Common';
import GlobalVariables from 'assets/global/globalVariables';
import { withWidth, Box, List } from '@material-ui/core';
import CmtList from '@coremat/CmtList';
import ItemCell from './ItemCell';

import useStyles from '../index.style';

const Sidebar = ({ width }) => {
  const dispatch = useDispatch();
  const { selectedSport } = useSelector(({ teams }) => teams);
  const { availableSports } = useSelector(({ common }) => common);
  const { showFooter } = useContext(AppContext);
  const [sports, setSports] = useState([]);

  useEffect(() => {
    dispatch(getAvailableSports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (availableSports) {
      const data = availableSports.map(sport => {
        return {
          ...sport,
          slug: sport.nameLowercase,
          icon: GlobalVariables.sports.find(el => el.name === sport.name)?.icon,
        };
      });
      setSports(data);
      if (selectedSport) {
        if (Object.keys(selectedSport).length === 0) dispatch(selectSport(data[0]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSports]);

  const onChangeSport = sportName => {
    const sport = sports.find(el => el.nameLowercase === sportName);
    dispatch(selectSport(sport));
  };

  const classes = useStyles({
    isCollapsed: false,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <PerfectScrollbar className={classes.perfectScrollbarMatchSidebar}>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={sports}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={selectedSport.nameLowercase}
                onChange={onChangeSport}
              />
            )}
          />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);
