import React, { useEffect } from 'react';
import Moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllLanguages } from 'redux/actions/Languages';

import { Box, Button } from '@material-ui/core';

import Broadcasts from './Broadcasts';
import Stadium from './Stadium';
import CmtAvatar from '@coremat/CmtAvatar';

import useStyles from '../index.style';

const MatchHeader = ({ matchDetails, setNewFilter }) => {
  const { headline, homeTeamEmblemUrl, awayTeamEmblemUrl, scheduledDate, competitionName, goals, status } = matchDetails;
  const dispatch = useDispatch();
  const { languages } = useSelector(({ languages }) => languages);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openStadium, setOpenStadium] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenStadium = () => {
    setOpenStadium(true);
  };

  const handleChangeOdds = () => {
    setOpen(true);
  };

  const handleCloseModalStadium = () => {
    setOpenStadium(false);
  };

  useEffect(() => {
    dispatch(fetchAllLanguages());
  }, [dispatch]);

  return (
    <Box className={classes.headerContent}>
      <Box className={classes.scoreContent}>
        {competitionName} - {Moment(scheduledDate).format('H:mm - D MMM yyyy')}
      </Box>
      <Box className="matchHeadline">
        <Box>
          <CmtAvatar size={60} src={homeTeamEmblemUrl} alt={headline} />
        </Box>
        <Box className="matchHeadlineText">
          <h1>{headline}</h1>
          <Box className={classes.scoreContent}>
            {status === 'FT' ? (
              <span>
                {goals.fullTimeHomeGoals}({goals.halfTimeHomeGoals}) - ({goals.halfTimeAwayGoals}){goals.fullTimeAwayGoals}
              </span>
            ) : null}
          </Box>
        </Box>
        <Box>
          <CmtAvatar size={60} src={awayTeamEmblemUrl} alt={headline} />
        </Box>
      </Box>
      <Box style={{ margin: '50px 0 0 0' }}>
        {status !== 'FT' ? (
          <Button style={{ margin: '15px 0' }} variant="contained" color="primary" onClick={() => setNewFilter('odds')}>
            Edit Odds
          </Button>
        ) : null}
        {status !== 'FT' ? (
          <Button
            style={{ margin: '15px 0 15px 15px' }}
            variant="contained"
            color="primary"
            onClick={() => setNewFilter('lineups')}>
            Edit Lineups
          </Button>
        ) : null}
        {status !== 'NS' && status !== 'POSTP' && status !== 'FT' ? (
          <Button
            style={{ margin: '15px 0 15px 15px' }}
            variant="contained"
            color="primary"
            onClick={() => setNewFilter('events')}>
            Edit Events
          </Button>
        ) : null}
        <Button style={{ margin: '15px 0 15px 15px' }} variant="contained" color="primary" onClick={handleOpen}>
          Broadcasters
        </Button>
        <Button style={{ margin: '15px 0 15px 15px' }} variant="contained" color="primary" onClick={handleOpenStadium}>
          Add Stadium
        </Button>
      </Box>
      <Broadcasts
        competitionId={matchDetails._id}
        languageList={languages}
        open={open}
        broadcasters={matchDetails !== undefined ? matchDetails.broadcasters : null}
        handleCloseModal={handleCloseModal}
      />
      <Stadium
        competitionId={matchDetails._id}
        open={openStadium}
        stadium={matchDetails !== undefined ? matchDetails.stadium : null}
        handleCloseModal={handleCloseModalStadium}
      />
    </Box>
  );
};

export default MatchHeader;
