const GlobalMessages = {
  required: 'Required field',
  invalid: 'Invalid field',
  formErrors: 'There are some errors in the form!',
  noDocuments: 'No documents submitted',
  noWalletTransfers: 'No wallet transfers',
  noTransactions: 'No transactions',
  invalidEmail: 'Please enter a valid email.',
  invalidUsername: 'Please enter a valid username.',
  invalidName: 'Please enter a valid name.',
  invalidPassword: 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number.',
  barAdded: 'Bar added successfully',
  commonNameRequired: 'You need to select a common name for the checked odds.',
};

export default GlobalMessages;
