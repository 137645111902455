import {
  TOGGLE_SIDEBAR_COLLAPSED,
  SELECT_SPORT,
  GET_ODDS,
  GET_VISIBLE_ODDS,
  PATCH_ODD_COMMON_NAME,
} from 'constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  selectedSport: {},
  odds: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }
    case SELECT_SPORT: {
      return {
        ...state,
        selectedSport: action.payload,
      };
    }
    case GET_ODDS: {
      return {
        ...state,
        odds: action.payload,
      };
    }
    case GET_VISIBLE_ODDS: {
      return {
        ...state,
        visibleOdds: action.payload,
      };
    }
    case PATCH_ODD_COMMON_NAME: {
      return {
        ...state,
        patchCommonName: action.payload,
      };
    }
    default:
      return state;
  }
};
