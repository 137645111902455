import { alpha, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    opacity: '0.8',
  },
  inBuildAppContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'relative',

    '&.collapsed': {
      '& $inBuildAppSidebar': {
        width: 72,
      },
      '& $inBuildAppMainContent': {
        width: 'calc(100% - 72px)',
      },
      '& .Cmt-user-info, & .Cmt-nav-text, & .Cmt-nav-count, & .Cmt-more-vert-icon, & .Cmt-media-header .title-root, & .Cmt-media-header .sub-title-text': {
        opacity: 0,
        visibility: 'hidden',
      },
      '& .connection-cell-item': {
        paddingLeft: 15,
        paddingRight: 13,
        overflow: 'hidden',
      },
      '& .Cmt-media-header .dot-status': {
        marginLeft: -24,
        position: 'relative',
        zIndex: 1,
        border: `solid 2px ${theme.palette.common.white}`,
      },
      '& $appNavItem': {
        marginLeft: 12,
        marginRight: 11,
        padding: '8px 16px',
        borderRadius: '50%',
        overflow: 'hidden',
        width: 48,
        height: 48,
      },
      '& $appNavHeaderItem': {
        borderTopColor: alpha(theme.palette.common.dark, 0.12),
      },
      '& $appNavHeaderItemText': {
        display: 'none',
      },
      '& $inBuildAppSidebarHeader': {
        paddingLeft: 15,
        paddingRight: 15,
      },
      '& $addOfficialChampionshipBtn': {
        borderRadius: '50%',
        minWidth: 10,
        padding: 8,
      },
      '& .add-officialChampionship-btn-text': {
        opacity: 0,
        visibility: 'hidden',
        width: 0,
        marginLeft: 0,
      },
    },
    '& .MuiListItemText-primary': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  inBuildAppSidebar: {
    width: 256,
    transition: 'all 0.3s ease',
    borderRight: `solid 1px ${theme.palette.borderColor.main}`,
    overflow: 'hidden',
  },
  inBuildAppMainContent: {
    width: 'calc(100% - 256px)',
    transition: 'all 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
  },
  inBuildAppSidebarHeader: {
    padding: 24,
    paddingBottom: 20,
    transition: 'all 0.3s ease',
  },
  addOfficialChampionshipBtn: {
    width: '100%',
    padding: '8px 16px',
    overflow: 'hidden',
    '& .add-officialChampionship-btn-text': {
      marginLeft: 10,
      transition: 'all 0.3s ease',
      opacity: 1,
      visibility: 'visible',
      whiteSpace: 'nowrap',
    },
  },
  appNav: {
    padding: 0,
  },
  appNavItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 10px 20px',
    marginRight: 16,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    width: 'auto',
    whiteSpace: 'nowrap',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: theme.palette.text.secondary,
    transition: 'all 0.3s ease',
    '& .Cmt-icon-root': {
      minWidth: 10,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
    '& .Cmt-nav-text': {
      marginLeft: 20,
      transition: 'all 0.3s ease',
      opacity: 1,
      visibility: 'visible',
      whiteSpace: 'nowrap',
      '& .MuiTypography-body1': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    '& .Cmt-nav-count': {
      transition: 'all 0.3s ease',
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.common.dark, 0.1),
      '& .Cmt-icon-root': {
        color: theme.palette.text.primary,
      },
    },
    '&.active': {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      '& .Cmt-icon-root': {
        color: theme.palette.primary.main,
      },
      '&:hover, &:focus': {
        '& .Cmt-icon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  appOfficialChampionshipNavItem: {
    '& .Cmt-more-vert-icon': {
      transition: 'all 0.3s ease',
      display: 'none',
      '& .MuiSvgIcon-root': {
        display: 'block',
      },
    },
    '&:hover, &:focus': {
      '& .Cmt-more-vert-icon': {
        display: 'block',
      },
      '& .Cmt-nav-count': {
        display: 'none',
      },
    },
  },
  appNavHeaderItem: {
    position: 'relative',
    marginTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 0,
    borderTop: 'solid 1px transparent',
  },
  appNavHeaderItemText: {
    paddingTop: 8,
    paddingBottom: 20,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    fontSize: 10,
    color: theme.palette.text.secondary,
    letterSpacing: 1.5,
  },
  appContentHeader: {
    padding: '6px 16px',
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    display: 'flex',
    alignItems: 'center',
  },
  selectBoxRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.disabled,
    marginLeft: 10,
    '&:before, &:after': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  appHeaderTitle: {
    marginLeft: 12,
    color: theme.palette.common.dark,
    cursor: 'pointer',
  },
  perfectScrollbarMatchCon: {
    height: props => `calc(100vh - ${props.height}px)`,
  },
  perfectScrollbarMatchSidebar: {
    height: props => `calc(100vh - ${props.height}px)`,
    marginTop: '0px',
  },
  inBuildAppHeader: {
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.1)}`,
    display: 'flex',
    alignItems: 'center',
  },
  inBuildAppHeaderSidebar: {
    display: 'flex',
    alignItems: 'center',
    padding: '11px 16px 10px 16px',
    [theme.breakpoints.up('md')]: {
      width: 256,
    },
  },
  inBuildAppHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '11px 16px 10px 0px',
  },
}));
export default useStyles;
