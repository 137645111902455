import { CREATE_NEW_SETTING, GET_SETTINGS, EDIT_SETTING, TOGGLE_SIDEBAR_COLLAPSED } from '../../constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    selectedFolder: 'accepted',
    selectedLabel: '',
    searchText: '',
  },
  settingsList: [],
  languageList: [],
  languages: [],
  newLanguageCallback: null,
  languagePlatforms: [],
  currentLanguage: null,
  totalBars: null,
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }
    case GET_SETTINGS: {
      return {
        ...state,
        settingsList: action.payload,
      };
    }

    case EDIT_SETTING: {
      return {
        ...state,
        settingStatus: action.payload,
      };
    }

    case CREATE_NEW_SETTING: {
      return {
        ...state,
        createSettingResponse: action.payload,
      };
    }

    default:
      return state;
  }
};
