import React, { useState, useEffect, useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import SelectCompetitionsOfficial from './SelectCompetitions';
import CompetitionCell from './CompetitionCell';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getChallengeOutrights } from '../../../../redux/actions/Challenges';
import InfoIcon from '@material-ui/icons/Info';
import Alert from 'utils/InfoAlertMessage';

const OutrightsOfficial = ({ viewDetail, championshipSelection }) => {
  const { id } = useParams();
  const { challengeOutrights } = useSelector(({ challenges }) => challenges);
  const dispatch = useDispatch();

  useEffect(() => {
    if (viewDetail) dispatch(getChallengeOutrights(viewDetail._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail]);

  return (
    <Box>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Outrights</h2>
      </Box>
      <Box>
        {championshipSelection?.competitions !== undefined &&
          championshipSelection?.competitions?.length > 0 &&
          id === 'new' && <CompetitionCell selectedCompetitions={championshipSelection?.competitions} />}
        {challengeOutrights !== undefined && challengeOutrights.length > 0 && (
          <CompetitionCell selectedCompetitions={challengeOutrights} />
        )}
        {viewDetail?.outrights?.length === 0 && id !== 'new' && <Alert>There are no outrights in the championship.</Alert>}
        {!championshipSelection && id === 'new' && <Alert>Please select an YEPP Selection to see the outrights.</Alert>}
        {championshipSelection && !championshipSelection?.competitions?.length && id === 'new' && (
          <Alert>There are no outrights in the selected YEPP Selection.</Alert>
        )}
      </Box>
      {id !== 'new' &&
      championshipSelection?._id !== viewDetail?.yeppSelectionId &&
      championshipSelection?.competitions?.length > 0 > 0 ? (
        <>
          <h3
            style={{
              paddingTop: '10px',
              borderTop: '1px solid black',
            }}>
            <Alert>YEPP Selection has been changed. Outrights of the selection are listed below</Alert>
          </h3>
          <CompetitionCell selectedCompetitions={championshipSelection?.competitions} />
        </>
      ) : (
        id !== 'new' &&
        championshipSelection?._id !== viewDetail?.yeppSelectionId && (
          <Alert
            style={{
              paddingTop: '10px',
              border: 'none',
              borderTop: '1px solid black',
            }}>
            There are no outrights in the new YEPP Selection you have selected.
          </Alert>
        )
      )}
    </Box>
  );
};

export default OutrightsOfficial;
