import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';

export default function Alert(props) {
  return (
    <MuiAlert
      severity="info"
      elevation={0}
      variant="outlined"
      style={{ ...props.style, width: 'max-content', border: 'none' }}
      {...props}
    />
  );
}
