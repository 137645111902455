import React, { useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Box } from '@material-ui/core';

const CommonName = ({
  handleChangeCommonName,
  id,
  oddOptionBettingSelectorTypeValue,
  commonNames,
  selectedName,
  nameSimple,
  buttonAvailable,
}) => {
  const [name, setName] = useState(selectedName);
  const [nameYeppSimple, setNameYeppSimple] = useState(nameSimple);
  const [typeValue, setTypeValue] = useState(oddOptionBettingSelectorTypeValue);

  const changeName = event => {
    setName(event.target.value);
    if (buttonAvailable !== true) {
      handleChangeCommonName(id, event.target.value, nameYeppSimple);
    }
  };

  const changeNameYeppSimple = event => {
    const newNameYeppSimple = event.target.value;
    setNameYeppSimple(newNameYeppSimple);
    if (buttonAvailable !== true) {
      // When buttonAvailable is not true, submit the common name,
      // type value, and the new nameYeppSimple immediately.
      handleChangeCommonName(id, name, newNameYeppSimple);
    }
  };

  const changeType = event => {
    setTypeValue(event.target.value);
  };

  const submitCommonName = () => {
    handleChangeCommonName(id, name, typeValue, nameYeppSimple);
  };

  return (
    <Box style={{ display: 'flex' }}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel>Common Name</InputLabel>
        <Select name="name" value={name} onChange={changeName}>
          <MenuItem key="0" value="0" disabled>
            Select Common Name
          </MenuItem>
          {commonNames &&
            commonNames.map(name => {
              return (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <FormControl style={{ width: '100%' }}>
        <InputLabel>Common Name Yepp Simple</InputLabel>
        <Select name="name" value={nameYeppSimple} onChange={changeNameYeppSimple}>
          <MenuItem key="0" value="0" disabled>
            Select Common Name Yepp Simple
          </MenuItem>
          {commonNames &&
            commonNames.map(name => {
              return (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {buttonAvailable === true ? (
        <FormControl style={{ width: '100%', marginLeft: '15px' }}>
          <InputLabel>Type Value</InputLabel>
          <Select name="name" value={typeValue !== undefined ? typeValue : 'null'} onChange={changeType}>
            <MenuItem value="null" disabled>
              Select Type Value
            </MenuItem>
            <MenuItem value="0">FIELD SELECTOR</MenuItem>
            <MenuItem value="1">TWO IN LINE SELECTOR</MenuItem>
            <MenuItem value="2">THREE IN LINE SELECTOR</MenuItem>
          </Select>
        </FormControl>
      ) : (
        ''
      )}
      {buttonAvailable === true ? (
        <Button
          onClick={submitCommonName}
          type="submit"
          variant="contained"
          style={{ marginLeft: '15px' }}
          color="primary"
          disabled={typeValue !== undefined && name !== undefined ? false : true}>
          Submit
        </Button>
      ) : (
        ''
      )}
    </Box>
  );
};

export default CommonName;
