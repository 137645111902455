import React from 'react';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      paddingRight: 44,
    },
  },
}));

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Name</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box
          style={{
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <strong style={{ marginRight: '15px' }}>Email Verified</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box
          style={{
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <strong style={{ marginRight: '15px' }}>Documents Verified</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box
          style={{
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <strong style={{ marginRight: '15px' }}>Risk Level</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Wallet Amount</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Total Amount Played</strong>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableHeading;
