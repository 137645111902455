import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableBody } from '@material-ui/core';
import TableItem from './TableItem';
import EmptyResult from 'components/Table/EmptyResult';
import { getAllTranslations, setDBFilters } from 'redux/actions/DBTranslations';

const DBTranslationsListing = () => {
  const dispatch = useDispatch();
  const { translations, filters } = useSelector(({ DBTranslations }) => DBTranslations);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(getAllTranslations(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.offset, filters.limit]);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(setDBFilters({ ...filters, offset: 0, limit: filters?.limit }));
      dispatch(getAllTranslations(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.addedBy,
    filters.updatedBy,
    filters.section,
    filters.status,
    filters.addedDateSort,
    filters.updatedDateSort,
    filters.searchQuery,
  ]);

  return (
    <>
      {translations.terms?.length ? (
        <TableBody>
          {translations.terms.map(row => (
            <TableItem key={row._id} row={row} languagesNumber={+translations?.numberOfLanguages} />
          ))}
        </TableBody>
      ) : null}
      {!translations?.numberOfTerms && <EmptyResult />}
    </>
  );
};

export default DBTranslationsListing;
