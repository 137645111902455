import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch/Switch';

const Rule = ({ rule, changeStatus }) => {
  const rulesTypeName = [
    'REGISTERED_AND_NO_BET_PLACED',
    'NO_BET_SINCE_N_DAYS',
    'CHALLENGE_AND_NO_BET_PLACED',
    'FAVOURITE_CLUB_PLAYING',
    'NO_FRIENDS_INVITED_YET',
  ];

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        minWidth: '400px',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(255,255,255, 1)',
      maxWidth: 'calc(100% - 30px)',
      margin: '10px 15px',
      width: '100%',
      padding: '5px 20px',
      borderRadius: '4px',
      '@media (max-width: 1024px)': {
        maxWidth: '100%',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
    },
    textRule: {
      background: 'rgba(0,0,0,0.03)',
      padding: '10px',
      width: '100%',
    },
    rightContainer: {
      paddingLeft: '30px',
      width: 'calc(100% - 375px)',
      '@media (max-width: 768px)': {
        width: '100%',
        padding: '0 !important',
        marginTop: '30px',
      },
    },
    leftContainer: {
      width: '375px',
      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      '& p:last-of-type': {
        marginLeft: 'auto',
      },
    },
  }));
  const classes = useStyles();
  const [status, setStatus] = useState(rule.status === 0);
  return (
    <Box component="div" className={classes.container}>
      <Accordion style={{ border: 'none', boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="email content">
          <Box className={classes.title}>
            <h3>Rule Name: {rule.name}</h3>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.wrapper}>
            <Box className={classes.leftContainer}>
              <Box className={classes.item}>
                <p>Period of time:</p>
                <p>
                  <strong>{rule.periodOfTime}(days)</strong>
                </p>
              </Box>
              <Box className={classes.item}>
                <p>Rule type:</p>
                <p>
                  <strong>{rulesTypeName[rule.ruleType]}</strong>
                </p>
              </Box>
              <Box className={classes.item}>
                <p>Rule Status:</p>
                <p>
                  <strong>{rule.status === 0 ? 'Active' : 'Inactive'}</strong>
                </p>
              </Box>
              <Box className={classes.item}>
                <p>Time in advance:</p>
                <p>
                  <strong>{rule.timeInAdvance}</strong>
                </p>
              </Box>
              <Box className={classes.item}>
                <p style={{ marginRight: '10px' }}>Push Notification:</p>
                <p>
                  <strong>{(rule.sendOn.pushNotification = true ? 'true' : 'false')}</strong>
                </p>
              </Box>
              <Box className={classes.item}>
                <p>Send Email:</p>
                <p>
                  <strong>{(rule.sendOn.email = true ? 'true' : 'false')}</strong>
                </p>
              </Box>
              <Box>
                <p>Change Status</p>
                <p>
                  <Switch
                    checked={status}
                    onChange={() => {
                      changeStatus(rule._id, rule.status);
                      setStatus(!status);
                    }}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </p>
              </Box>
            </Box>
            <Box style={{ paddingLeft: '30px' }} className={classes.rightContainer}>
              <Accordion
                style={{
                  boxShadow: 'none',
                  borderBottom: '1px solid rgba(0,0,0, 0.2)',
                }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="email content">
                  <h3>Email Content</h3>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                  {rule.emailContent.map((content, key) => {
                    return (
                      <Box
                        key={key}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '10px',
                        }}>
                        <Accordion
                          style={{
                            boxShadow: 'none',
                            borderBottom: '1px solid rgba(0,0,0, 0.2)',
                          }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={key}>
                            <Box>
                              Language: <strong>{content.language.toUpperCase()}</strong>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className={classes.textRule}>{content.content}</Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Rule;
