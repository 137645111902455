import React, { useEffect } from 'react';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useState, useContext } from 'react';
import { TablePagination, Box, ButtonGroup, IconButton, Table, Typography, Hidden } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { getMatchContainerHeight } from 'constants/AppConstants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DBTranslationsListing from './DBTranslationsListing';
import TableHeading from './DBTranslationsListing/TableHeading';
import { setDBFilters } from 'redux/actions/DBTranslations';
import { fetchAllLanguages } from 'redux/actions/Languages';
import CmtSearch from '@coremat/CmtSearch';

const DBTranslationsPage = () => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({ height: getMatchContainerHeight(showFooter) + 120 });
  const dispatch = useDispatch();
  const history = useHistory();

  const { filters, translations } = useSelector(({ DBTranslations }) => DBTranslations);
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(filters?.limit);
  const [searchQuery, setSearchQuery] = useState(filters.searchQuery);

  useEffect(() => {
    if (filters.offset === 0) setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    dispatch(fetchAllLanguages());
    dispatch(setDBFilters({ ...filters, offset: rowsPerPage * page, limit: rowsPerPage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.addedBy, filters.updatedBy, filters.section, filters.status, filters.addedDateSort, filters.updatedDateSort]);

  const handleChangePage = (e, newPage) => {
    dispatch(setDBFilters({ ...filters, offset: newPage * filters.limit }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = e => {
    dispatch(setDBFilters({ ...filters, limit: e.target.value }));
    setRowsPerPage(e.target.value);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      dispatch(setDBFilters({ ...filters, searchQuery: searchQuery }));
    }, 800);

    return () => clearTimeout(delaySearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <CmtCard>
      <Box className={classes.inBuildAppHeader}>
        <Box className={classes.inBuildAppHeaderSidebar}>
          <Hidden>
            <IconButton onClick={() => setIsSideBarCollapsed(prev => !prev)}>
              <Menu />
            </IconButton>
          </Hidden>
          <Typography className={classes.inBuildAppHeaderTitle} component="div" variant="h1">
            Terms
          </Typography>
        </Box>
        <Box className={classes.inBuildAppHeaderContent}>
          <CmtSearch
            placeholder="Search Term..."
            border={false}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <Box ml="auto" display="flex" alignItems="center">
            <Box ml={1}>
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <Button
                  onClick={() => {
                    history.push('/db-translations/new');
                  }}>
                  CREATE
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
      </Box>

      <CmtCardContent className={classes.cardContentRoot} style={{ overflow: 'auto', padding: 0 }}>
        <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
          <Sidebar />
          <Box width="100%">
            <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
              <Table className="Cmt-table-responsive" style={{ width: '100%' }}>
                <TableHeading />
                <DBTranslationsListing />
              </Table>
            </PerfectScrollbar>

            {translations.numberOfTerms ? (
              <TablePagination
                component="div"
                style={{ order: '9999', width: '100%' }}
                count={translations?.numberOfTerms || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default DBTranslationsPage;
