import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  countries: {
    display: 'grid!important',
    gridTemplateColumns: 'repeat(4,1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2,1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1,1fr)',
    },
  },
  date: {
    display: 'flex',
    margin: '10px 7.5px',
    alignItems: 'center',
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
  },

  dateInput: {
    display: 'block',
    margin: '10px 22px',
  },

  fullBoxwidth: {
    display: 'flex',
    flexDirection: 'row',
    margin: '7.5px',
    flexWrap: 'wrap',
  },
  titleRoot: {
    fontSize: '30px',
    padding: '10px',
  },

  fakeBorder: {
    width: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '4px 10px',
    borderRadius: '4px',
    marginTop: '7.5px',
    marginRight: '10px',
    marginLeft: '5px',
    position: 'relative',
    height: '58px',
    '@media screen and (max-width: 768px)': {
      marginTop: '10px',
    },
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
    width: 'auto',
    display: 'inline-block',
    padding: '3px',
    fontSize: '0.75rem',
    textAlign: 'left',
    visibility: 'visible',
    position: 'absolute',
    background: '#fff',
    zIndex: '99',
    top: '-11px',
    left: '10px',
  },

  headOfficialLeft: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '40%',
    marginBottom: '30px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },

  headOfficialRight: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '60%',
    marginBottom: '30px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexDirection: 'column',
    },
  },

  headOfficial: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },

  hiddenInput: {
    display: 'none',
  },

  textFieldHead: {
    margin: '12.5px 7.5px',
    maxWidth: 'calc(100% - 15px)',
    width: '100%',
  },

  Textarea25: {
    maxWidth: 'calc(25% - 15px)',
    width: '100%',
    margin: '7.5px',
    '& textarea': {
      minHeight: '0',
      height: '19px',
      maxHeight: '19px',
      transition: 'min-height 0.4s ease-in-out',
    },
    '& .Mui-focused': {
      '& textarea': {
        minHeight: '120px',
        maxHeight: '999px',
        transition: 'min-height 0.4s ease-in-out, max-height 0.8s ease-in-out',
      },
    },
  },
  TextareaNumber: {
    margin: '7.5px',
  },
  Textarea50: {
    maxWidth: 'calc(50% - 15px)',
    width: '100%',
    margin: '7.5px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    '& textarea': {
      minHeight: '0',
      height: '19px',
      maxHeight: '19px',
      transition: 'min-height 0.4s ease-in-out',
    },
    '& .Mui-focused': {
      '& textarea': {
        minHeight: '120px',
        maxHeight: '999px',
        transition: 'min-height 0.4s ease-in-out, max-height 0.8s ease-in-out',
      },
    },
  },

  cardContentRoot: {
    '& .notification-container': {
      top: '100px',
    },
  },

  TextFieldStyle: {
    margin: '7.5px',
  },
}));
export default useStyles;
