import React from 'react';
import { Table, TableHead, TableBody, Box } from '@material-ui/core';

import TableHeading from './TableHeading';
import TableItem from './TableItem';

const RecentTable = ({ tableData }) => {
  return (
    <Box className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
          {tableData !== undefined && tableData.length > 0
            ? tableData.map((row, index) => <TableItem row={row} key={index} />)
            : null}
        </TableBody>
      </Table>
      {tableData.length === 0 && <Box style={{ margin: '60px auto', textAlign: 'center' }}>No users found</Box>}
    </Box>
  );
};

export default RecentTable;
