import {
  ADD_SELECTION,
  GET_MATCHES_TEMPLATES,
  GET_SELECTIONS,
  GET_SELECTION_DETAILS,
  GET_TEAMS_TEMPLATES,
  PATCH_SELECTION,
} from 'constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getSelections = (page, rowsPerPage, searchQuery, generatedBy, type, status) => {
  let url = `/yepp-selections/all?`;

  if (page >= 0 && rowsPerPage >= 0) url += `offset=${page * rowsPerPage}&limit=${rowsPerPage}&`;
  if (searchQuery) url += `searchWord=${searchQuery}&`;

  if (generatedBy >= 0) {
    url = url.concat(`generatedBy=${generatedBy}&`);
  }
  if (type >= 0) {
    url = url.concat(`type=${type}&`);
  }
  if (status >= 0) {
    url = url.concat(`status=${status}&`);
  }

  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(url)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_SELECTIONS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getSelectionDetails = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/yepp-selections/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_SELECTION_DETAILS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const createSelection = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/yepp-selections', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_SELECTION, payload: data.data });
        dispatch(fetchError('Selection created.'));
        window.location.href = '/selections';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const patchSelection = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/yepp-selections/' + id, body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_SELECTION, payload: data.data });
        dispatch(fetchError('Selection patched.'));
        window.location.href = '/selections';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const addEvents = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/yepp-selections/' + id + '/add-events', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_SELECTION, payload: data.data });
        dispatch(fetchError('Selection patched.'));
        window.location.href = '/selections';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const removeEvents = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/yepp-selections/' + id + '/remove-events', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_SELECTION, payload: data.data });
        dispatch(fetchError('Selection patched.'));
        window.location.href = '/selections';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const createTemplate = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/templates', body)
      .then(data => {
        dispatch(getTemplates(data?.data?.type));
        dispatch(fetchSuccess());
        dispatch(fetchError('Template has been created!'));
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const updateTemplate = (body, id) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/templates/' + id, body)
      .then(data => {
        dispatch(getTemplates(data?.data?.type));
        dispatch(fetchSuccess());
        dispatch(fetchError('Template has been updated!'));
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getTemplates = type => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/templates?type=' + type)
      .then(data => {
        dispatch(fetchSuccess());
        if (type === 1) {
          dispatch({ type: GET_MATCHES_TEMPLATES, payload: data.data });
        } else if (type === 2) {
          dispatch({ type: GET_TEAMS_TEMPLATES, payload: data.data });
        }
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const deleteTemplate = (id, type) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/templates/' + id)
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(fetchError('Template has been deleted!'));
        dispatch(getTemplates(type));
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
