import React, { useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TableCell } from '@material-ui/core';
import useStyles from 'components/Table/table.styles';
import axios from '../../../../services/auth/jwt/config';
import { fetchError, fetchSuccess } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getSingleMatchOdds } from '../../../../redux/actions/MatchDetails';

const OddsOptionMatch = ({ oddsMatch }) => {
  const { id } = useParams();
  const [oddSelectedOption, setOddSelectedOption] = useState(0);
  const [loadedResponse, setLoadedResponse] = useState(true);
  const [buttonColorChange, setButtonColorChange] = React.useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const changeOddSelectedOption = event => {
    setOddSelectedOption(event.target.value);
  };

  const handleValidate = oddSelectedOption => {
    setLoadedResponse(false);
    setButtonColorChange(!buttonColorChange);
    axios
      .post(`/user-bets/validate-bet`, { oddOptionsId: oddSelectedOption, validate: true })
      .then(response => {
        dispatch(fetchSuccess());
        const selected = oddSelectedOption;
        dispatch(getSingleMatchOdds(id));
        setOddSelectedOption(selected);
        setLoadedResponse(true);
        dispatch(fetchError(`Odd options validated`));
        setOddSelectedOption(0);
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };

  const handleInvalidated = oddSelectedOption => {
    setLoadedResponse(false);
    setButtonColorChange(!buttonColorChange);
    axios
      .post(`/user-bets/validate-bet`, { oddOptionsId: oddSelectedOption, validate: false })
      .then(response => {
        dispatch(fetchSuccess());
        const selected = oddSelectedOption;
        dispatch(getSingleMatchOdds(id));
        setOddSelectedOption(selected);
        setLoadedResponse(true);
        dispatch(fetchError(`Odd options invalidated`));
        setOddSelectedOption(0);
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
  return (
    <>
      <TableCell className={classes.tableCellRoot} style={{ minWidth: '180px', whiteSpace: 'nowrap' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel>Select Odd Option</InputLabel>
          <Select value={oddSelectedOption} onChange={changeOddSelectedOption}>
            <MenuItem key="0" value="0" disabled>
              Select Odd Option
            </MenuItem>
            {oddsMatch.oddOptions.map(option => {
              return (
                <MenuItem key={option._id} value={option}>
                  {option.name}{' '}
                  {(option.status === 1 && 'Undecided') ||
                    (option.status === 2 && 'Validated') ||
                    (option.status === 3 && 'Invalidated') ||
                    (option.status === 4 && 'Unknown, but resolved') ||
                    (option.status === 5 && 'Unknown') ||
                    (option.status === 6 && 'Half won') ||
                    (option.status === 7 && 'Half lost') ||
                    (option.status === 8 && 'Void') ||
                    (option.status === 9 && 'Canceled') ||
                    (option.status === 10 && 'Overdue')}
                  &nbsp;
                  <span style={option.status === 3 ? { color: 'red' } : option.status === 2 ? { color: 'green' } : null}>
                    ●
                  </span>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Button
          onClick={() => handleValidate(oddSelectedOption._id)}
          color={buttonColorChange ? 'primary' : 'secondary'}
          type="submit"
          variant="contained"
          disabled={oddSelectedOption.status ? oddSelectedOption.status !== 3 && oddSelectedOption.status !== 1 : true}>
          Validate
        </Button>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Button
          onClick={() => handleInvalidated(oddSelectedOption._id)}
          type="submit"
          variant="contained"
          color={buttonColorChange ? 'primary' : 'secondary'}
          disabled={oddSelectedOption.status ? oddSelectedOption.status !== 2 : true}>
          Invalidate
        </Button>
      </TableCell>
    </>
  );
};

export default OddsOptionMatch;
