import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { alpha, Button, TableCell, TableRow, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import B2BAccountModal from './B2BAccountModal';
import { updateB2BAccount } from '../../../redux/actions/B2BAccount';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        fontWeight: 700,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
  },
}));

const TableItem = ({ row }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = body => {
    dispatch(updateB2BAccount(row._id, body));
  };

  const getUserTypeName = value => {
    const B2BUserType = {
      SPORTWORLD: 6,
      TSC: 7,
      PBC: 9,
    };

    return Object.keys(B2BUserType).find(key => B2BUserType[key] === value) || value;
  };

  return (
    <>
      <TableRow className={classes.tableRowRoot}>
        <TableCell className={classes.tableCellRoot}>{row._id}</TableCell>
        <TableCell className={classes.tableCellRoot}>{row.email}</TableCell>
        <TableCell className={classes.tableCellRoot}>{row.phoneNumber}</TableCell>
        <TableCell className={classes.tableCellRoot}>{getUserTypeName(row.b2bExternalType)}</TableCell>
        <TableCell className={classes.tableCellRoot}>
          <Button variant="contained" color="primary" onClick={handleClickOpen} startIcon={<EditIcon />}>
            Edit
          </Button>
        </TableCell>
      </TableRow>
      <B2BAccountModal open={open} onClose={handleClose} initialData={row} onSubmit={handleEdit} />
    </>
  );
};

export default TableItem;
