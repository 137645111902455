import { CREATE_NEW_SETTING, GET_SETTINGS, EDIT_SETTING } from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getSettings = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/settings')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_SETTINGS, payload: data.data });
      })
      .catch(error => {});
  };
};

export const addNewSetting = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/settings', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(getSettings());
        dispatch({ type: CREATE_NEW_SETTING, payload: data.data });
        dispatch(fetchError('New setting created'));
      })
      .catch(error => {});
  };
};

export const changeSetting = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/settings/' + id, body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(getSettings());
        dispatch({ type: EDIT_SETTING, payload: data.data });
        dispatch(fetchError('Setting changed'));
      })
      .catch(error => {});
  };
};
