import React from 'react';
import { Table, TableHead, TableBody, Box } from '@material-ui/core';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import { NotificationContainer } from 'react-notifications';

const RecentTable = ({ tableData }) => {
  return (
    <Box className="Cmt-table-responsive">
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
          {tableData !== undefined && tableData.length > 0
            ? tableData.map((row, index) => <TableItem row={row} key={index} />)
            : null}
        </TableBody>
      </Table>
      <NotificationContainer />
    </Box>
  );
};

export default RecentTable;
