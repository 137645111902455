import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelMatch, postponeMatch } from 'redux/actions/MatchApp';
import CmtSearch from '@coremat/CmtSearch';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import useStyles from './index.style';
import Moment from 'moment';
import { useForm } from 'react-hook-form';
import axios from '../../../../../services/auth/jwt/config';
import { fetchStart, fetchSuccess, fetchError } from 'redux/actions';

const statusMatch = [
  { id: 0, value: 0, label: 'Canceled' },
  { id: 1, value: 1, label: 'Postponed' },
];

const OutdatedTemplate = ({ open, onClose, match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(0);
  const [selectedMatch, setSelectedMatch] = useState();
  const [matchesResult, setMatchesResult] = useState([]);
  const [futureMatches, setFutureMatches] = useState();
  const [searchQuery, setSearchQuery] = useState('');

  const { handleSubmit } = useForm();

  useEffect(() => {
    setSearchQuery('');
  }, [match]);

  useEffect(() => {
    if (open) {
      let url = `/matches/admin?competitionId=${match.competitionId}&status=2&name=${searchQuery}`;

      axios
        .get(url)
        .then(data => {
          const filteredResults = data?.data?.matches.filter(item => item._id !== match._id);
          setFutureMatches(filteredResults);
        })
        .catch(error => {
          if (error.response?.status !== 401) {
            dispatch(fetchError('Something went wrong'));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, searchQuery]);

  const handleClose = () => {
    onClose();
  };
  const handleUpdateMatch = e => {
    setSelectedMatch(e.target.value);
  };
  const handleChangeStatus = e => {
    const selectedStatus = statusMatch.find(statusType => statusType.value === e.target.value);
    setStatus(selectedStatus.value);
    let matches = matchesResult.filter(matches => matches.sportName === match.sportName);
    setMatchesResult(matches);
  };
  const onSubmit = () => {
    if (status === 1) {
      dispatch(postponeMatch({ gameId: match._id, newGameId: selectedMatch }));
    } else {
      dispatch(cancelMatch({ gameId: match._id }));
    }

    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} scroll={'paper'}>
      <DialogTitle className={classes.titleContainer}>Status match</DialogTitle>
      <DialogContent dividers={true} style={{ minWidth: '600px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} className={classes.formControl}>
            <InputLabel>Status</InputLabel>
            <Select name="sport" value={status} onChange={handleChangeStatus}>
              {statusMatch.map(statusType => {
                return (
                  <MenuItem key={statusType.id} value={statusType.value}>
                    {statusType.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {status === 1 && (
            <FormControl style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} className={classes.formControl}>
              <InputLabel style={{ marginBottom: '30px', position: 'relative' }}>Matches</InputLabel>
              <CmtSearch
                placeholder="Search Match..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                border={false}
              />
              <Select name="sport" value={selectedMatch} onChange={handleUpdateMatch}>
                {futureMatches?.map(match => {
                  return (
                    <MenuItem key={match._id} value={match._id}>
                      {match.headline} - {Moment(match.scheduledDate).format('H:mm - D MMM yyyy')}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={handleClose} style={{ marginLeft: '15px' }} color="primary">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary">
            Submit
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default OutdatedTemplate;
