import { BET_TEMPLATE } from 'constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  betTemplates: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BET_TEMPLATE: {
      return {
        ...state,
        betTemplates: action.payload,
      };
    }
    default:
      return state;
  }
};
