import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditBetTemplate from './EditBetTemplate';
import useStyles from 'components/Table/table.styles';

const OddCellOptions = ({ betTemplate }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.matchCellOptionsRoot}>
      <Box className={clsx(classes.actionOptionRoot, 'action-option')}>
        <Box ml={1}>
          <Tooltip title="Edit">
            <IconButton size="small" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <EditBetTemplate open={open} onClose={handleClose} betTemplate={betTemplate} action="edit" />
        </Box>
      </Box>
    </Box>
  );
};

export default OddCellOptions;

OddCellOptions.prototype = {
  betTemplate: PropTypes.object.isRequired,
  fetchBetTemplates: PropTypes.func.isRequired,
};
