import React from 'react';
import { useHistory } from 'react-router-dom';
import { blockUserAML } from 'redux/actions/Users';
import { useDispatch } from 'react-redux';

import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import CmtMediaObject from '../../../@coremat/CmtMediaObject';

import { Box, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import useStyles from './index.style';
import Button from '@material-ui/core/Button';

const TableItem = ({ row }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const name = row.firstName + ' ' + row.lastName;

  const actions = [
    {
      label: 'View User',
      id: 'view',
    },
  ];

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRootItem}>
        <CmtMediaObject
          avatarPos="center"
          avatar={<CmtAvatar size={35} src={row.profilePic} alt={row.name} />}
          title={
            <Box>
              <Box>{name}</Box>
              <Box style={{ opacity: 0.6, fontSize: '0.8em' }}>{row._id}</Box>
            </Box>
          }
          titleProps={{
            variant: 'h5',
            className: classes.titleRoot,
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCellRootItem} style={{ width: '30%' }}>
        {row.email}
      </TableCell>

      <TableCell className={classes.tableCellRootItem} style={{ textAlign: 'center' }}>
        {row.riskLevel === 1 && <span style={{ color: 'blue', fontWeight: 'bold' }}>MEDIUM</span>}
        {row.riskLevel === 2 && <span style={{ color: 'red', fontWeight: 'bold' }}>HIGH</span>}
      </TableCell>

      <TableCell className={classes.tableCellRootItem}>
        <Box>
          <Box>{row.totalAmountPlayedEUR24H} EUR</Box>
          <Box>{row.totalAmountPlayedGBP24H} GBP</Box>
          <Box>{row.totalAmountPlayedUSD24H} USD</Box>
        </Box>
      </TableCell>

      <TableCell className={classes.tableCellRootItem}>
        <Box>
          <Box>{row.totalAmountPlayedEUR7D} EUR</Box>
          <Box>{row.totalAmountPlayedGBP7D} GBP</Box>
          <Box>{row.totalAmountPlayedUSD7D} USD</Box>
        </Box>
      </TableCell>

      <TableCell className={classes.tableCellRootItem}>
        <Box>
          <Box>{row.totalAmountPlayedEUR30D} EUR</Box>
          <Box>{row.totalAmountPlayedGBP30D} GBP</Box>
          <Box>{row.totalAmountPlayedUSD30D} USD</Box>
        </Box>
      </TableCell>

      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginRight: '35', zIndex: '334' }}
          color="primary"
          onClick={() =>
            dispatch(
              blockUserAML({
                userId: row._id,
                status: !row.accountAMLBlocked,
              }),
            )
          }>
          {row.accountAMLBlocked ? 'UNBLOCK' : 'BLOCK'}
        </Button>
      </TableCell>

      <TableCell className={classes.tableCellRootItem}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <CmtDropdownMenu
            TriggerComponent={
              <Tooltip title="More">
                <IconButton size="small" style={{ marginLeft: 10 }}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            }
            items={actions}
            onItemClick={() => {
              history.push('/user/' + row._id);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
