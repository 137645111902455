import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'components/Table/table.styles';

import { getSingleMatchOdds } from 'redux/actions/MatchDetails';
import OddsOptionMatch from './OddsOptions';
import moment from 'moment';

const OddsMatch = ({ matchId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { matchDetail, singleMatchOdds } = useSelector(({ matchDetails }) => matchDetails);

  useEffect(() => {
    dispatch(getSingleMatchOdds(matchId));
  }, [dispatch, matchId]);

  if (matchDetail.status !== 'Ended') {
    return <p>No odds here</p>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Odd Name</TableCell>
          <TableCell>Odd Option</TableCell>
          <TableCell>Validate</TableCell>
          <TableCell>Invalidated</TableCell>
        </TableRow>
      </TableHead>
      {singleMatchOdds?.map((oddsMatch, i) => {
        return (
          <TableBody>
            <TableRow key={i} className={classes.tableRowRoot}>
              <TableCell className={classes.tableCellRoot}>
                {oddsMatch.name} - {moment(oddsMatch.created).format('YYYY-MM-DD hh:mm A')}
              </TableCell>
              {oddsMatch.oddOptions.length ? (
                <OddsOptionMatch oddsMatch={oddsMatch} />
              ) : (
                <TableCell className={classes.tableCellRoot} align="center">
                  no Odds
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        );
      })}
    </Table>
  );
};

export default OddsMatch;
