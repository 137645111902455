import { GET_POPUP, GET_ALL_POPUPS } from 'constants/ActionTypes';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_POPUPS:
      return {
        ...state,
        popUps: action.payload,
      };
    case GET_POPUP:
      return {
        ...state,
        popUps: action.payload,
      };
    default:
      return state;
  }
};
