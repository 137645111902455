import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import useStyles from '../index.style';
import ImageIcon from '@material-ui/icons/Image';
import { withStyles } from '@material-ui/core/styles';
import { createFanClub, patchFanClub } from '../../../../redux/actions/FanClub';
import { useDispatch, useSelector } from 'react-redux';

import { getSelections } from 'redux/actions/Selections';
import Selections from '../Selections';

const stakeTypes = [
  { id: 0, value: 0, label: 'Fun' },
  { id: 1, value: 1, label: 'Money' },
  { id: 2, value: 2, label: 'Yepp Coins' },
];

const CustomColorCheckbox = withStyles({
  root: {
    color: '#000000de',
    '&$checked': {
      color: '#30178e',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const FanClubDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fanClubDetails } = useSelector(({ fanClub }) => fanClub);
  const [viewDetail, seViewDetail] = useState(fanClubDetails);

  const [cover, setCover] = useState();
  const [name, setName] = useState();
  const [stake, setStake] = useState();
  const [previewCover, setPreviewCover] = useState(null);
  const [stakeType, setStakeType] = useState(0);
  const [rankingFilterType, setRankingFilterType] = useState([
    { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
    { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
    { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
  ]);
  const [availableRankingFilterType, setAvailableRankingFilterType] = useState({});
  const { selectionsList } = useSelector(({ selections }) => selections);
  const [selectionList, setSelectionList] = useState(undefined);
  const [selections, setSelections] = useState([]);
  const [selectionsInsert, setSelectionsInsert] = useState([]);
  const [selectionsRemove, setSelectionsRemove] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  // const [viewDetail, setViewDetail] = useState(selectionDetails);

  useEffect(() => {
    dispatch(getSelections(page, rowsPerPage, searchQuery));
  }, [id]);

  useEffect(() => {
    if (selectionsList) {
      setSelectionList(selectionsList.selections);
    }
  }, [selectionsList]);

  useEffect(() => {
    if (viewDetail) {
      setPreviewCover(viewDetail.bgImage);
      setStakeType(viewDetail.stakeType);
      const allValues = [
        { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
        { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
        { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
      ];

      setRankingFilterType(
        allValues?.length
          ? allValues
          : [
              { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
              { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
              { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
            ],
      );
      let rankingFilter;
      if (viewDetail?.rankingFilterType === 0) {
        rankingFilter = { id: 0, value: true, label: 'All time', transKey: 'all_time', type: 0 };
      }
      if (viewDetail?.rankingFilterType === 1) {
        rankingFilter = { id: 1, value: true, label: 'Weekly', transKey: 'weekly', type: 1 };
      }
      if (viewDetail?.rankingFilterType === 2) {
        rankingFilter = { id: 2, value: true, label: 'Daily', transKey: 'daily', type: 2 };
      }
      setAvailableRankingFilterType(rankingFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCover = e => {
    setPreviewCover(URL.createObjectURL(e.target.files[0]));
    setCover(e.target.files[0]);
  };

  const handleChangeStakeType = e => {
    const selectedStakeType = stakeTypes.find(stakeType => stakeType.value === e.target.value);
    setStakeType(selectedStakeType.value);
  };

  const handleChangeAvailableFilter = e => {
    const newFilterType = rankingFilterType.find(rankingFilterType => rankingFilterType.id === e.target.value);

    setAvailableRankingFilterType(newFilterType);
  };

  const handleChangeRankingFilterType = e => {
    const otherRankingFilterTypes = rankingFilterType.filter(
      rankingFilterType => rankingFilterType.id !== Number(e.target.name),
    );
    const selectedRankingFilterType = rankingFilterType.find(
      rankingFilterType => rankingFilterType.id === Number(e.target.name),
    );
    selectedRankingFilterType.value = !selectedRankingFilterType.value;
    const updatedValues = [...otherRankingFilterTypes, selectedRankingFilterType];
    updatedValues.sort((a, b) => a.id - b.id);
    setRankingFilterType(updatedValues);
  };

  const createUpdateFanClub = requestType => {
    const updatedSelections = selectionsInsert.filter(id => !selectionsRemove.includes(id));

    const body = {
      name,
      file: cover,
      stake,
      stakeType,
      rankingFilterType: availableRankingFilterType.type,
      availableRankingFilterTypes: rankingFilterType.filter(element => element.value).map(element => element.type),
      yeppSelectionsId: requestType === 'create' ? selectionsInsert.toString() : updatedSelections.toString(),
    };
    if (requestType === 'create') {
      dispatch(createFanClub(body));
    } else {
      body.groupId = id;
      dispatch(patchFanClub(body));
    }
  };

  const getSelection = (currentList, toBeInserted, toBeRemoved) => {
    setSelections(currentList);
    setSelectionsInsert(toBeInserted);
    setSelectionsRemove(toBeRemoved);
  };
  return (
    <Box>
      <Box className={classes.headOfficial}>
        <Box className={classes.headOfficialLeft}>
          <TextField
            id="outlined-basic"
            onChange={e => setName(e.target.value)}
            defaultValue={viewDetail && viewDetail.name}
            className={classes.textFieldHead}
            label="FanClub Name"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            onChange={e => setStake(e.target.value)}
            defaultValue={viewDetail && viewDetail.stake}
            className={classes.textFieldHead}
            label="Official stake"
            variant="outlined"
          />
          <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
            <InputLabel>Stake Type</InputLabel>
            <Select
              name="stakeType"
              defaultValue={viewDetail && viewDetail.stakeType}
              value={stakeType}
              onChange={handleChangeStakeType}>
              {stakeTypes.map(stakeType => {
                return (
                  <MenuItem key={stakeType.id} value={stakeType.value}>
                    {stakeType.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box style={{ display: 'block', margin: '10px 7.5px' }}>
            <h2>Available filter type</h2>
          </Box>
          <FormControl required component="fieldset" sx={{ m: 3 }} variant="standard" style={{ margin: '10px 7.5px' }}>
            {rankingFilterType.map(rankingType => {
              return (
                <FormControlLabel
                  key={rankingType.id}
                  control={
                    <CustomColorCheckbox
                      checked={rankingType.value}
                      name={String(rankingType.id)}
                      onChange={handleChangeRankingFilterType}
                    />
                  }
                  label={rankingType.label}
                />
              );
            })}
          </FormControl>
          <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
            <InputLabel>Ranking Filter Type</InputLabel>
            <Select
              name="rankingFilterType"
              defaultValue={''}
              value={availableRankingFilterType?.id ?? ''}
              onChange={handleChangeAvailableFilter}>
              {rankingFilterType.map(rankingType => {
                if (rankingType.value) {
                  return (
                    <MenuItem key={rankingType.id} value={rankingType.id}>
                      {rankingType.label}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.headOfficialRight}>
          <Box className={classes.logoCover}>
            <Box className={classes.imagePlaceholder}>
              {previewCover !== null ? <img alt="cover" src={previewCover} className={classes.imageLogo} /> : null}
            </Box>
            <input
              accept="image/*"
              onChange={handleCover}
              className={classes.hiddenInput}
              id="contained-button-cover"
              multiple
              type="file"
            />
            <label htmlFor="contained-button-cover">
              <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                Upload Cover
              </Button>
            </label>
          </Box>
        </Box>
      </Box>
      {id !== 'new' ? (
        <Button
          style={{ marginBottom: '25px' }}
          variant="contained"
          color="primary"
          onClick={() => createUpdateFanClub('update')}>
          Update FanClub
        </Button>
      ) : (
        <Button
          style={{ marginBottom: '25px' }}
          variant="contained"
          color="primary"
          onClick={() => createUpdateFanClub('create')}>
          Create FanClub
        </Button>
      )}
      <Box>{selectionList !== undefined && <Selections selections={selectionList} getSelection={getSelection} />}</Box>
    </Box>
  );
};

export default FanClubDetails;
