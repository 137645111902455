import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '24px 0',
    minWidth: 120,
    width: '100%',
  },
  dialogContent: {
    padding: '32px',
    position: 'relative',
  },
  dialogActions: {
    padding: '24px',
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
  },
  gridContainer: {
    marginBottom: '24px',
  },
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '16px',
  },
  resetButton: {
    marginRight: 'auto',
  },
}));

const DEFAULT_FILTERS = {
  country: 'ALL',
  dateType: 'day',
  selectedDate: new Date(),
  startDate: null,
  endDate: null,
  selectedMonth: new Date(),
};

const CountersFilterModal = ({ open, onClose, handleApply, filters, handleFilterChange, availableCountries, onReset }) => {
  const classes = useStyles();
  const today = new Date();

  const handleStartDateChange = date => {
    const endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 6);

    handleFilterChange('startDate', date);
    handleFilterChange('endDate', endDate > today ? today : endDate);
  };

  const handleResetFilters = () => {
    handleFilterChange('country', DEFAULT_FILTERS.country);
    handleFilterChange('dateType', DEFAULT_FILTERS.dateType);
    handleFilterChange('selectedDate', DEFAULT_FILTERS.selectedDate);
    handleFilterChange('startDate', DEFAULT_FILTERS.startDate);
    handleFilterChange('endDate', DEFAULT_FILTERS.endDate);
    handleFilterChange('selectedMonth', DEFAULT_FILTERS.selectedMonth);
    onReset();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <div className={classes.dialogTitleContainer}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
          <Typography variant="h2" style={{ fontWeight: 'bold', top: '60px', position: 'relative' }}>
            Counters Filter Options
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel>Country</InputLabel>
              <Select value={filters.country} onChange={e => handleFilterChange('country', e.target.value)}>
                <MenuItem value="ALL">All Countries</MenuItem>
                {availableCountries.map(country => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel>Date Type</InputLabel>
              <Select value={filters.dateType} onChange={e => handleFilterChange('dateType', e.target.value)}>
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {filters.dateType === 'day' && (
            <Grid item xs={12}>
              <DatePicker
                label="Select Date"
                value={filters.selectedDate}
                onChange={date => handleFilterChange('selectedDate', date)}
                className={classes.formControl}
                fullWidth
                maxDate={today}
              />
            </Grid>
          )}
          {filters.dateType === 'week' && (
            <>
              <Grid item xs={12}>
                <DatePicker
                  label="Start Date"
                  value={filters.startDate}
                  onChange={handleStartDateChange}
                  className={classes.formControl}
                  fullWidth
                  maxDate={today}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker label="End Date" value={filters.endDate} disabled className={classes.formControl} fullWidth />
              </Grid>
            </>
          )}
          {filters.dateType === 'month' && (
            <Grid item xs={12}>
              <DatePicker
                label="Select Month"
                views={['year', 'month']}
                value={filters.selectedMonth}
                onChange={date => handleFilterChange('selectedMonth', date)}
                className={classes.formControl}
                fullWidth
                maxDate={today}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleResetFilters} color="primary" variant="contained" className={classes.resetButton}>
          Reset
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary" variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CountersFilterModal;
