import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  championshipListContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  championshipCell: {
    border: '1px solid rgba(0,0,0,0.06)',
    cursor: 'pointer',
    margin: '6px',
    padding: '16px',
    overflow: 'hidden',
    borderRadius: '4px',
    transition: 'all .2s',
    maxWidth: 'calc(33% - 12px)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(50% - 12px)',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% + 30%)',
      width: 'calc(100% + 30%)',
      padding: '8px',
      margin: '6px -15px',
    },
    '&:hover': {
      boxShadow: '0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%), 0px 3px 5px rgb(0 0 0 / 20%)',
      transition: 'all .2s',
      transform: 'translateY(-4px)',
    },
    '& h2': {
      fontSize: '16px',
      maxWidth: 'calc(100% - 96px)',
      overflow: 'hidden',
    },
  },
  championshipHead: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '-16px -16px 10px -16px',
    padding: '10px 0 5px 16px',
    background: 'rgba(0,0,0,0.01)',
    borderBottom: '1px solid rgba(0,0,0,0.06)',
  },
  championshipActions: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
  },
  championshipLogo: {
    minWidth: '70px',
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    border: '1px solid rgba(0,0,0,0.06)',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minWidth: '40px',
      width: '40px',
      height: '40px',
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  championshipBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  championshipDate: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 0 20px',
    width: '100%',
  },
  label: {
    opacity: '0.8',
  },
}));
export default useStyles;
