import React from 'react';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
  },
}));

const GamesTableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot} style={{ fontSize: '16px', textAlign: 'start' }}>
        <strong>Game Headline</strong>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ fontSize: '16px', textAlign: 'center' }}>
        <strong>Date</strong>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ fontSize: '16px', textAlign: 'center' }}>
        <strong>Total Predictions</strong>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ fontSize: '16px', textAlign: 'center' }}>
        <strong>Country</strong>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ fontSize: '16px', textAlign: 'center' }}>
        <strong>Info</strong>
      </TableCell>
    </TableRow>
  );
};

export default GamesTableHeading;
