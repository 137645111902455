import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, TablePagination } from '@material-ui/core';
import MatchCell from './MatchCell';
import { getChallengeMatches } from 'redux/actions/Challenges';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import BetTemplates from '../BetTemplates';
import useStyles from '../index.style';
import Alert from 'utils/InfoAlertMessage';

const Matches = ({ matches, viewDetail, championshipSelection, setChampionshipSelection }) => {
  const { selectionsList } = useSelector(({ selections }) => selections);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isSelectionsVisible, setIsSelectionsVisible] = useState(false);
  const [selections, setSelections] = useState();
  const [page, setPage] = useState(0);
  const rowsPerPage = 18;
  const myRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    if (viewDetail) dispatch(getChallengeMatches(viewDetail._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail]);

  useEffect(() => {
    if (selectionsList && selectionsList.selections) {
      setSelections(selectionsList.selections.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsList, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const executeScroll = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  return (
    <Box>
      <BetTemplates betTemplatesData={viewDetail && viewDetail.betTemplates} />
      <Box style={{ display: 'flex', gap: '20px', marginLeft: '7.5px', marginTop: '35px' }}>
        <h2>YEPP Selection</h2>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsSelectionsVisible(prev => !prev);
            setTimeout(() => {
              executeScroll();
            }, 100);
          }}>
          {isSelectionsVisible ? 'Close' : 'OPEN'}
        </Button>
        {(viewDetail && viewDetail.yeppSelectionId) || (championshipSelection && championshipSelection.id) ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setChampionshipSelection(null);
            }}>
            REMOVE SELECTION
          </Button>
        ) : null}
      </Box>
      <Box style={{ marginTop: '15px', fontSize: '16px' }}>
        {!championshipSelection ? (
          <Alert>No selection yet. Please choose one.</Alert>
        ) : (
          <Alert>
            {(viewDetail && viewDetail.yeppSelectionId) !== championshipSelection._id
              ? 'You have selected'
              : 'Championship is linked with'}{' '}
            <a href={`/selection/${championshipSelection._id}`} target="_blank" rel="noopener noreferrer">
              {championshipSelection.name}
            </a>
          </Alert>
        )}
      </Box>

      {isSelectionsVisible && (
        <>
          <Box style={{ minHeight: '400px' }} ref={myRef}>
            <Box className={classes.grid}>
              {selections &&
                selections.map(selection => (
                  <Box
                    key={selection._id}
                    value={selection.name}
                    style={{
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: '1px solid rgba(196, 206, 218, 0.7)',
                      padding: '5px 10px',
                      height: 'max-content',
                    }}>
                    {selection.name}
                    <Button
                      variant="contained"
                      disabled={selection._id === (championshipSelection && championshipSelection._id)}
                      color={selection._id !== (viewDetail && viewDetail.yeppSelectionId) ? 'primary' : 'secondary'}
                      onClick={() => {
                        setChampionshipSelection(selection);
                      }}>
                      {selection._id !== (viewDetail && viewDetail.yeppSelectionId) ? 'SELECT' : 'LINKED'}
                    </Button>
                  </Box>
                ))}
            </Box>
          </Box>
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={(selectionsList && selectionsList.count) || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
          />
        </>
      )}
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Matches</h2>
      </Box>
      {id === 'new' ? (
        <Box>
          {championshipSelection && championshipSelection.games && championshipSelection.games.length > 0 ? (
            <MatchCell selectedMatches={championshipSelection.games} />
          ) : championshipSelection && championshipSelection.games && championshipSelection.games.length === 0 ? (
            <Alert>There are no matches in the selected YEPP Selection.</Alert>
          ) : (
            <Alert>Please select an YEPP Selection to see the matches.</Alert>
          )}
        </Box>
      ) : matches && matches.length > 0 ? (
        <MatchCell selectedMatches={matches} />
      ) : (
        <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no matches in the selected YEPP Selection.</h3>
      )}
      {id !== 'new' && championshipSelection && championshipSelection._id !== (viewDetail && viewDetail.yeppSelectionId) && (
        <Box>
          {championshipSelection && championshipSelection.games && championshipSelection.games.length > 0 ? (
            <>
              <Alert
                style={{
                  paddingTop: '10px',
                  border: 'none',
                  borderTop: '1px solid black',
                }}>
                YEPP Selection has been changed. Matches of the selection are listed below.
              </Alert>
              <MatchCell selectedMatches={championshipSelection.games} />
            </>
          ) : championshipSelection && championshipSelection.games && championshipSelection.games.length === 0 ? (
            <Alert
              style={{
                paddingTop: '10px',
                border: 'none',
                borderTop: '1px solid black',
              }}>
              There are no matches in the new YEPP Selection.
            </Alert>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default Matches;
