import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from 'services/auth/jwt/config';
import { ECOSYSTEMS, GET_ECOSYSTEM_DETAIL } from 'constants/ActionTypes';

export const createEcosystems = body => {
  console.log(body, 'body');
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/environments/', body)
      .then(data => {
        dispatch(getEcosystems(data?.data?.type));
        dispatch(fetchSuccess());
        dispatch(fetchError('Template has been created!'));
        setTimeout(() => {
          window.location.href = '/ecosystems';
        }, 200);
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const updateEcosystem = (body, id) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/environments/' + id, body)
      .then(data => {
        dispatch(getEcosystems(data?.data?.type));
        dispatch(fetchSuccess());
        dispatch(fetchError('Template has been updated!'));
        setTimeout(() => {
          window.location.href = '/ecosystems';
        }, 200);
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getEcosystems = type => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/environments')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ECOSYSTEMS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getEcosystemByID = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/environments/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_ECOSYSTEM_DETAIL, payload: data.data });
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(fetchError(error.response?.data?.message));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
