import {
  ADD_SELECTION,
  GET_SELECTIONS,
  GET_SELECTION_DETAILS,
  PATCH_SELECTION,
  GET_FANCLUBS,
  GET_FANCLUB_DETAILS,
  ADD_FANCLUB,
  PATCH_FANCLUB,
} from 'constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getFanClubs = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/group/country-fanclubs')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_FANCLUBS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const createFanClub = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/challenges/country-fanclub', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_FANCLUB, payload: data.data });
        dispatch(fetchError('Fan Club created.'));
        window.location.href = '/fan-clubs';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const patchFanClub = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/group/country-fanclubs/' + id, body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_FANCLUB, payload: data.data });
        dispatch(fetchError('Fan Club updated.'));
        window.location.href = '/fan-clubs';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
