import {
  CREATE_B2B_ACCOUNT,
  FETCH_B2B_USERS_SUCCESS,
  FETCH_B2B_USERS_FAILURE,
  UPDATE_B2B_ACCOUNT,
} from '../actions/B2BAccount';

const INIT_STATE = {
  createAccountResponse: null,
  allUsers: [],
  paginatedUsers: [],
  count: 0,
  loading: false,
  error: null,
};

const B2BAccount = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_B2B_ACCOUNT:
      return {
        ...state,
        createAccountResponse: action.payload,
      };
    case FETCH_B2B_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
        count: action.payload.length,
        loading: false,
      };
    case FETCH_B2B_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_B2B_ACCOUNT:
      return {
        ...state,
        allUsers: state.allUsers.map(user => (user._id === action.payload._id ? action.payload : user)),
      };
    case 'SET_PAGINATED_USERS':
      return {
        ...state,
        paginatedUsers: action.payload,
      };
    default:
      return state;
  }
};

export default B2BAccount;
