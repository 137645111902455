import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, TablePagination, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { fetchB2BUsers } from '../../../redux/actions/B2BAccount';
import B2BAccountModal from './B2BAccountModal';
import RecentTable from './RecentTable';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: 0,
  },
  scrollbarRoot: {
    height: '100%',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

const B2BUsersAdmin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allUsers = [], paginatedUsers = [], count, loading } = useSelector(({ b2bAccount }) => b2bAccount);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    dispatch(fetchB2BUsers());
  }, [dispatch]);

  useEffect(() => {
    paginateUsers();
  }, [page, rowsPerPage, allUsers]);

  const paginateUsers = () => {
    const startIndex = page * rowsPerPage;
    const paginatedUsers = allUsers.slice(startIndex, startIndex + rowsPerPage);
    dispatch({ type: 'SET_PAGINATED_USERS', payload: paginatedUsers });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setSelectedUser(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'B2B Users'}
        style={{ marginBottom: '30px', zIndex: '444' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <PersonAddIcon style={{ marginRight: '5px' }} />
          Create B2B Account
        </Button>
        <B2BAccountModal open={open} onClose={handleClose} initialData={selectedUser} />
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <Box className="Cmt-table-responsive">
            {loading ? (
              <Box className={classes.flexCenter}>
                <Typography>Loading...</Typography>
              </Box>
            ) : (
              <RecentTable tableData={paginatedUsers} />
            )}
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default B2BUsersAdmin;
