import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import useStyles from '../BarCell.style';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BarCellOptions from './BarCellOptions';

const BarCell = ({ bar, checkedBars, handleCellCheckBox, onShowBarDetail, onClickEditBar }) => {
  const classes = useStyles();
  const { id, name, website, phone, email, rating, country, city , folder, barType, profilePic } = bar;
  return (
    <TableRow className={classes.tableRowRoot} onClick={() => onShowBarDetail(bar)}>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center">
          <Box component="span" mr={2} onClick={e => e.stopPropagation()}>
            <Checkbox
              color="primary"
              checked={checkedBars.includes(id)}
              onChange={event => handleCellCheckBox(event.target.checked, id)}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Box mr={{ xs: 4, md: 5 }}>
              <CmtAvatar size={40} src={profilePic} alt={name} />
            </Box>

            <Box>
              <Typography className={classes.titleRoot} component="div" variant="h4">
                {name}
              </Typography>
              <Typography className={classes.subTitleRoot}>{barType}</Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>{website}</TableCell>
      <TableCell className={classes.tableCellRoot}>{phone}</TableCell>
      <TableCell className={classes.tableCellRoot}>{email}</TableCell>
      <TableCell className={classes.tableCellRoot}>{rating}</TableCell>
      <TableCell className={classes.tableCellRoot}>{country}</TableCell>
      <TableCell className={classes.tableCellRoot}>{city}</TableCell>
      <TableCell className={classes.tableCellRoot}>{folder}</TableCell>
      <TableCell className={clsx(classes.tableCellRoot, classes.tableCellAction)}>
        <BarCellOptions bar={bar} onClickEditBar={onClickEditBar} />
      </TableCell>
    </TableRow>
  );
};

export default BarCell;

BarCell.prototype = {
  bar: PropTypes.object.isRequired,
  checkedBars: PropTypes.array,
  handleCellCheckBox: PropTypes.func,
  onShowBarDetail: PropTypes.func,
  onClickEditBar: PropTypes.func,
};

BarCell.defaultProps = {
  checkedBars: [],
};
