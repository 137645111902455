import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { TableCell, TableRow } from '@material-ui/core';
import OddCellOptions from './OddCellOptions';
import useStyles from 'components/Table/table.styles';

const TableItem = ({ row }) => {
  const { availableSports } = useSelector(({ common }) => common);
  const classes = useStyles();

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>{row.name}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        {availableSports && availableSports.find(sport => sport._id === row.sportId).name}
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, classes.tableCellRootSmall)}>
        <OddCellOptions betTemplate={row} />
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
