import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddPrize from './AddPrize';
import PrizeCell from './PrizeCell';
import EditPrize from './EditPrize';

const Prizes = ({ langList, getPrizes, viewDetail }) => {
  const [editDetail, setEditDetail] = useState();
  const [prizes, setPrizes] = useState([]);
  const [editPrizeUuid, setEditPrizeUuid] = useState([]);
  const [editPrize, setEditPrize] = useState([]);
  const [prizesUuid, setPrizesUuid] = useState([]);
  const [toggleAdd, setToggleAdd] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [addPrizesState, setAddPrizesState] = useState({});

  const [isEditing, setIsEditing] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);

  const addPrize = () => {
    setToggleAdd(!toggleAdd);
    setIsAddNew(!toggleAdd);
    setAddPrizesState({
      name: '',
      description: [
        {
          description: '',
          language: 'en',
        },
        {
          description: '',
          language: 'ro',
        },
      ],
      photo: '',
      deleted: false,
    });
  };

  const addEditPrize = () => {
    setToggleEdit(!toggleEdit);
    setEditPrize({
      name: '',
      description: [
        {
          description: '',
          language: 'en',
        },
        {
          description: '',
          language: 'ro',
        },
      ],
      photo: '',
      deleted: false,
    });
  };

  useEffect(() => {
    setEditDetail(viewDetail);
    if (viewDetail !== undefined) {
      let newPrizesList = [];
      viewDetail.prizes.forEach((item, key) => {
        newPrizesList.push({ ...item, deleted: false });
      });
      setPrizes(newPrizesList);
    }
  }, [viewDetail]);

  const onSavePrize = (newPrize, newPrizeUuid) => {
    setPrizes(prizes => [...prizes, newPrize]);
    setPrizesUuid(prizesUuid => [...prizesUuid, newPrizeUuid]);
    let a = [...prizes, newPrize];
    let b = [...prizesUuid, newPrizeUuid];
    sendPrizes(a, b);

    setToggleAdd(false);
    setIsEditing(false);
    setIsAddNew(false);
  };

  const onSaveEditPrize = (newPrize, newPrizeUuid) => {
    if (newPrize === null) {
      setPrizes(prizes => [...prizes, newPrize]);
      let a = [...prizes, newPrize];
      sendPrizes(a, [prizesUuid]);
    } else {
      setPrizes(prizes => [...prizes, newPrize]);
      setPrizesUuid(prizesUuid => [...prizesUuid, newPrizeUuid]);
      let a = [...prizes, newPrize];
      let b = [...prizesUuid, newPrizeUuid];
      sendPrizes(a, b);
    }

    setToggleAdd(false);
    setIsEditing(false);
    setIsAddNew(false);
  };

  const sendPrizes = (a, b) => {
    getPrizes(a, b);
  };

  const deletePrize = (key, newPrize) => {
    let removePrizeList = [];
    if (newPrize._id === null) {
      setPrizes(prizes.filter((item, keyList) => keyList !== key));
      sendPrizes(
        prizes.filter((item, keyList) => keyList !== key),
        [prizesUuid],
      );
    } else {
      removePrizeList = prizes.filter((item, key) => item._id !== newPrize._id);
      removePrizeList.push({ ...newPrize, deleted: true });
      setPrizes(removePrizeList);
      sendPrizes(removePrizeList, [prizesUuid]);
    }
  };

  const onToggleEdit = (dataPrize, key) => {
    setToggleEdit(!toggleEdit);
    setIsAddNew(!toggleAdd);
    setIsEditing(true);
    setEditPrize(dataPrize);
    if (dataPrize.photo.includes('http')) {
      setEditPrizeUuid(prizesUuid);
      setPrizesUuid(prizesUuid);
    } else {
      setPrizesUuid(prizesUuid.filter((item, index) => !item[dataPrize.photo]));
      setEditPrizeUuid(prizesUuid.filter((item, index) => item[dataPrize.photo]));
    }
    setPrizes(prizes.filter((item, index) => index !== key));
  };

  return (
    <Box>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Prizes</h2>
        <Button disabled={isEditing} variant="contained" color="primary" onClick={addPrize}>
          Add
        </Button>
      </Box>
      {toggleAdd !== false ? (
        <AddPrize langList={langList} dataPrize={addPrizesState} onSavePrize={onSavePrize} addPrize={addPrize} />
      ) : null}
      {toggleEdit !== false ? (
        <EditPrize
          langList={langList}
          httpImage={editPrize.photo.includes('http')}
          dataView={editDetail}
          dataUuid={editPrizeUuid}
          dataPrize={editPrize}
          onSaveEditPrize={onSaveEditPrize}
          addEditPrize={addEditPrize}
        />
      ) : null}
      {prizes.length > 0 ? (
        prizes.map((item, key) => {
          if (!item.deleted) {
            return (
              <PrizeCell
                deletePrize={() => deletePrize(key, item)}
                httpImage={item.photo.includes('http')}
                editDetail={editDetail}
                disableEdit={isEditing || isAddNew}
                key={key}
                dataPrize={item}
                dataUuid={prizesUuid}
                editPrize={() => onToggleEdit(item, key)}
              />
            );
          }
        })
      ) : (
        <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no prizes. Please add one.</h3>
      )}
    </Box>
  );
};

export default Prizes;
