import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../index.style';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import { getBarCounts, getLabelsList, setFilterType } from '../../../../redux/actions/BarApp';
import ItemCell from './ItemCell';
import { foldersList } from '../../../../@fake-db/bars';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withWidth } from '@material-ui/core';
import { getAppSidebarHeight } from '../../../../constants/AppConstants';
import MoreOptions from './MoreOptions';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AppContext from '../../../../components/contextProvider/AppContextProvider/AppContext';

const Sidebar = ({ onClickCreateBar, width }) => {
  const { isSideBarCollapsed, filterType, barsList } = useSelector(({ barApp }) => barApp);
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBarCounts());
  }, [dispatch, barsList]);

  useEffect(() => {
    dispatch(getLabelsList());
  }, [dispatch]);

  const onChangeFolder = folder => {
    dispatch(
      setFilterType({
        selectedFolder: folder,
        selectedLabel: '',
        searchText: '',
      }),
    );
  };

  // const onChangeLabel = label => {
  //   dispatch(
  //     setFilterType({
  //       selectedFolder: '',
  //       selectedLabel: label,
  //       searchText: '',
  //     }),
  //   );
  // };

  const classes = useStyles({
    isCollapsed: isSideBarCollapsed,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <Box className={classes.inBuildAppSidebarHeader}>
        <Button
          className={classes.addOfficialChampionshipBtn}
          variant="contained"
          color="primary"
          onClick={onClickCreateBar}>
          <PersonAddIcon />
          <Box component="span" className="add-officialChampionship-btn-text">
            Create New
          </Box>
        </Button>
      </Box>

      <PerfectScrollbar className={classes.perfectScrollbarBarSidebar}>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={foldersList}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.selectedFolder}
                onChange={onChangeFolder}
              />
            )}
          />
          <MoreOptions classes={classes} barsList={barsList} />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);

Sidebar.prototype = {
  onClickCreateBar: PropTypes.func,
};
