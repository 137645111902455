import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody } from '@material-ui/core';
import TableItem from './TableItem';
import TableHeading from 'components/Table/TableHeading';
import TableHeader from 'assets/global/globalTableHeadingData';
import EmptyResult from 'components/Table/EmptyResult';
import { getPolls } from 'redux/actions/Polls';

const PollsListing = () => {
  const dispatch = useDispatch();
  const { polls } = useSelector(({ polls }) => polls);

  useEffect(() => {
    dispatch(getPolls());
  }, []);

  return (
    <>
      <Table>
        <TableHeading headingData={TableHeader.Polls} />
        {polls?.length ? (
          <TableBody>
            {polls?.map((row, index) => (
              <TableItem key={row._id} row={row} index={index} />
            ))}
          </TableBody>
        ) : null}
      </Table>
      {!polls?.length && <EmptyResult />}
    </>
  );
};

export default PollsListing;
