import React, { useEffect, useState } from 'react';
import axios from 'services/auth/jwt/config';
import { useDispatch } from 'react-redux';
import { changeDocumentStatus } from 'redux/actions/Users';
import { fetchError } from 'redux/actions';
import { Table, TableRow, TableCell, TableBody, Button, Select, MenuItem, FormControl, Dialog } from '@material-ui/core';

import GlobalVariables from 'assets/global/globalVariables';
import TableHeader from 'assets/global/globalTableHeadingData';

import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import TableHeading from 'components/Table/TableHeading';
import useStyles from 'components/Table/table.styles';

function IdentityVerification({ documents }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userDocument, setUserDocument] = useState();
  const [open, setOpen] = useState(false);

  const [status, setStatus] = useState({
    selectID: '',
    selectAddressProof: '',
    selectCardFront: '',
    selectCardBack: '',
  });

  useEffect(() => {
    if (documents) {
      documents.forEach(el => {
        if (el.type === 0) {
          setStatus(prevState => ({ ...prevState, selectID: el.status }));
        } else if (el.type === 1) {
          setStatus(prevState => ({ ...prevState, selectAddressProof: el.status }));
        } else if (el.type === 2) {
          setStatus(prevState => ({ ...prevState, selectCardFront: el.status }));
        } else {
          setStatus(prevState => ({ ...prevState, selectCardBack: el.status }));
        }
      });
    }
  }, [documents]);

  const handleChangeSelect = e => {
    const { name, value } = e.target;
    setStatus({
      ...status,
      [name]: value,
    });
  };

  const getDocumentImage = id => {
    axios
      .get(`/documents/${id}`, { responseType: 'blob' })
      .then(response => {
        setUserDocument(URL.createObjectURL(response.data));
        setOpen(true);
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
  };

  const handleDocumentStatus = (documentId, documentType) => {
    dispatch(
      changeDocumentStatus({
        id: documentId,
        status:
          documentType === 0
            ? status.selectID
            : documentType === 1
            ? status.selectAddressProof
            : documentType === 2
            ? status.selectCardFront
            : status.selectCardBack,
      }),
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  function DocumentModal({ open }) {
    return (
      <Dialog onClose={handleClose} open={open}>
        {userDocument && <img src={userDocument} alt="document" />}
      </Dialog>
    );
  }

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-8"
        title="Identity verification documents"
        style={{ marginBottom: '30px' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <Table>
        <TableHeading headingData={TableHeader.UserDetail.identityVerification} />
        <TableBody>
          {documents?.map(document => {
            return (
              <TableRow className={classes.tableRowRoot} key={document._id}>
                <TableCell className={classes.tableCellRoot}>
                  {document.type === 0
                    ? 'ID'
                    : document.type === 1
                    ? 'Address proof'
                    : document.type === 2
                    ? 'Credit card front'
                    : 'Credit card back'}
                </TableCell>
                <TableCell className={classes.tableCellRoot}>{document.status}</TableCell>

                <TableCell className={classes.tableCellRoot}>
                  <Button variant="outlined" color="primary" onClick={() => getDocumentImage(document._id)}>
                    View
                  </Button>
                  <DocumentModal open={open} onClose={handleClose} />
                </TableCell>

                <TableCell className={classes.tableCellRoot}>
                  <FormControl style={{ minWidth: '105px', marginRight: '30px' }} className={classes.formControl}>
                    <Select
                      name={
                        document.type === 0
                          ? 'selectID'
                          : document.type === 1
                          ? 'selectAddressProof'
                          : document.type === 2
                          ? 'selectCardFront'
                          : 'selectCardBack'
                      }
                      value={
                        document.type === 0
                          ? status.selectID
                          : document.type === 1
                          ? status.selectAddressProof
                          : document.type === 2
                          ? status.selectCardFront
                          : status.selectCardBack
                      }
                      defaultValue={document.status}
                      onChange={handleChangeSelect}>
                      {GlobalVariables.documentStatus.map(docStatus => (
                        <MenuItem key={docStatus.id} value={docStatus.value}>
                          {docStatus.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleDocumentStatus(document._id, document.type)}>
                    Submit
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </CmtCard>
  );
}

export default IdentityVerification;
