import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import GlobalMessages from 'assets/global/globalMessages';
import axios from 'services/auth/jwt/config';
import { fetchError } from 'redux/actions';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import CommonName from './CommonName';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    '& input': {
      minWidth: '120px',
    },
  },
  formControlWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  formControlExpandButton: {
    color: '#b8b8b8',
    cursor: 'pointer',
    transition: '100ms',
    '&:hover': {
      color: '#303030',
    },
  },
  expandedSection: {
    marginLeft: '1.5rem',
  },
  error: {
    marginTop: '10px',
    color: 'red',
  },
  formErrors: {
    color: 'red',
    marginLeft: '1rem',
  },
  titleContainer: {
    '& h2': {
      fontSize: '18px',
    },
  },
  events: {
    marginLeft: '1.5rem',
    marginTop: '1rem',
  },
  oddName: {
    color: '#30178e',
    fontWeight: '900',
  },
}));

function EditOdd({ open, onClose, odd, fetchOdds, commonNames, nameSimple }) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const [oddData, setOddData] = useState({});
  useEffect(() => {
    const updatedData = {
      ...odd,
      availableEventParts: odd.availableEventParts.map(el => ({
        ...el,
        visible: !!odd.visibleEventParts.find(x => x.id === el.id),
        commonName: odd.visibleEventParts.find(x => x.id === el.id)?.commonName,
        commonNameSimple: odd.visibleEventParts.find(x => x.id === el.id)?.commonNameSimple,
      })),
    };
    setOddData(updatedData);
  }, [odd]);

  const handleClose = () => {
    onClose();
  };

  const handleVisibleChange = () => {
    const updatedData = { ...oddData, visible: !oddData.visible };
    if (!updatedData.visible) clearErrors('checkedVisible');
    setOddData(updatedData);
  };

  const handleEventPartChange = id => {
    const updatedData = { ...oddData };
    const eventPartIndex = updatedData.availableEventParts.findIndex(el => el.id === id);
    updatedData.availableEventParts[eventPartIndex].visible = !updatedData.availableEventParts[eventPartIndex].visible;
    setOddData(updatedData);
    clearErrors();
  };

  const handleEventPartChangeCommonName = (id, name, nameSimple) => {
    const updatedData = { ...oddData };
    const eventPartIndex = updatedData.availableEventParts.findIndex(el => el.id === id);
    updatedData.availableEventParts[eventPartIndex].commonName = name;
    updatedData.availableEventParts[eventPartIndex].commonNameSimple = nameSimple;
    setOddData(updatedData);
    clearErrors();
  };

  const onSubmit = data => {
    let filteredVisibleEvents = oddData.availableEventParts.filter(el => el.visible);
    let filteredAvailableEvents = oddData.availableEventParts.filter(el => el.visible === false);

    let visibleEventParts = filteredVisibleEvents.map(({ visible, ...rest }) => ({
      ...rest,
    }));
    let availableEventParts = filteredAvailableEvents.map(({ visible, ...rest }) => ({
      ...rest,
      commonName: '',
    }));

    //check if there is a visible odd part checked but no common name selected
    let commonNameNotSelected = visibleEventParts.some(el => !el.commonName);
    let visibleEventPartsNotSelected = visibleEventParts.length === 0;

    if (commonNameNotSelected) {
      setError('name', { type: 'required', message: GlobalMessages.commonNameRequired });
    } else if (oddData.visible && visibleEventPartsNotSelected) {
      setError('checkedVisible', { message: 'If visible is selected you need to select at least one odd part' });
    } else {
      const dataToApi = {
        visible: oddData.visible,
        visibleEventParts: visibleEventParts,
        availableEventParts: availableEventParts,
      };

      axios
        .patch(`/odds/odd-types/${oddData._id}`, dataToApi)
        .then(response => {
          fetchOdds();
        })
        .catch(() => {
          dispatch(fetchError('Something went wrong'));
        });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'} scroll={'paper'} style={{ minWidth: '800px' }}>
      <DialogTitle className={classes.titleContainer}>
        Edit <span className={classes.oddName}>{odd.name}</span> visible odd parts
      </DialogTitle>
      <DialogContent dividers={true} style={{ minWidth: '800px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {oddData && (
            <Box>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  onChange={handleVisibleChange}
                  checked={oddData.visible}
                  control={<Checkbox name="checkedVisible" color="primary" />}
                  label="Visible"
                />
              </FormControl>
              <Box className={classes.events}>
                {oddData.availableEventParts &&
                  oddData.availableEventParts.map(eventPart => {
                    return (
                      <Box
                        key={eventPart.id}
                        style={{
                          display: 'flex',
                          padding: '10px 0',
                          borderBottom: '1px solid grey',
                        }}>
                        <FormControl className={classes.formControl} style={{ width: '30%' }}>
                          <FormControlLabel
                            onChange={() => handleEventPartChange(eventPart.id)}
                            checked={eventPart.visible}
                            control={<Checkbox name="eventPart.name" color="primary" />}
                            label={eventPart.name}
                          />
                        </FormControl>
                        <Box style={{ width: '70%' }}>
                          <CommonName
                            buttonAvailable={false}
                            commonNames={commonNames}
                            eventPart={eventPart}
                            selectedName={
                              oddData && oddData.visibleEventParts.find(el => el.id === eventPart.id)?.commonName
                            }
                            nameSimple={
                              oddData && oddData.visibleEventParts.find(el => el.id === eventPart.id)?.commonNameSimple
                            }
                            id={eventPart.id}
                            handleChangeCommonName={handleEventPartChangeCommonName}
                          />
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {errors && errors.name ? <span className={classes.formErrors}>{errors.name.message}</span> : null}
        {errors && errors.checkedVisible ? (
          <span className={classes.formErrors}>{errors.checkedVisible.message}</span>
        ) : null}
        <div>
          <Button onClick={handleClose} style={{ marginLeft: '15px' }} color="primary">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary">
            Submit
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditOdd;
