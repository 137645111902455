import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { patchPlayer } from 'redux/actions/MatchDetails';

import { makeStyles, TextField, TableRow, TableCell, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';

const Players = ({ playerDetail, team }) => {
  const useStyles = makeStyles(theme => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 100,
      },
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const [player, setPlayer] = useState(playerDetail || {});
  const [playerPosition, setPlayerPosition] = useState(playerDetail.position);
  const [playerStatus, setPlayerStatus] = useState(playerDetail.status || 'none');
  useEffect(() => {
    setPlayer(playerDetail);
  }, [playerDetail]);

  const positionChange = e => {
    setPlayerPosition(e.target.value);
    let body = {
      position: e.target.value,
    };
    dispatch(patchPlayer(playerDetail._id, body));
  };

  const statusChange = e => {
    setPlayerStatus(e.target.value);
    let body = {
      status: e.target.value,
    };
    dispatch(patchPlayer(playerDetail._id, body));
  };

  return (
    (player !== undefined) > 0 && (
      <TableRow className={classes.tableRowRoot}>
        <TableCell>{player.fullname}</TableCell>

        <TableCell>{team}</TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          <TextField
            id="outlined-basic"
            label="Position"
            onChange={positionChange}
            value={playerPosition}
            variant="outlined"
            disabled={true}
          />
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={playerStatus}
              onChange={statusChange}
              disabled={true}
              label="Status">
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="reserve">Reserve</MenuItem>
              <MenuItem value="holder">Holder</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    )
  );
};

export default Players;
