import {
  ADD_OFFICIAL_CHAMPIONSHIPS,
  GET_OFFICIAL_CHAMPIONSHIPS,
  GET_OFFICIAL_CHAMPIONSHIPS_MATCHES,
  GET_OFFICIAL_CHAMPIONSHIPS_LANG,
  GET_OFFICIAL_CHAMPIONSHIPS_DETAILS,
  PATCH_OFFICIAL_CHAMPIONSHIPS_DETAILS,
  DELETE_OFFICIAL_CHAMPIONSHIPS,
  PUBLISH_OFFICIAL_CHAMPIONSHIPS,
  SELECT_BET_TEMPLATE,
  SELECT_BET_TEMPLATE_TYPE,
  SET_CUSTOM_FINAL_BETTING_DATES_OUTRIGHTS,
  SET_INDIVIDUAL_BET_TEMPLATES,
} from 'constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const createOfficialChampionship = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('challenges/official-championship', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_OFFICIAL_CHAMPIONSHIPS, payload: data.data });
        dispatch(fetchError('Official championship created.'));
        window.location.href = '/official-championships';
      })
      .catch(error => {
        if (error.response.data.message === 'challenges.INVALID_CUSTOM_END_DATE') {
          dispatch(fetchError(''));
          return NotificationManager.error('Custom date must be bigger than Matches and Outrights end date', 'ERROR!', 3000);
        } else if (error.response.data.message === 'Validation error!') {
          dispatch(fetchError(''));
          return NotificationManager.error('A available filter type needs to be selected', 'ERROR!', 3000);
        } else if (error.response.data.message === 'You should add at least one match or outright!') {
          dispatch(fetchError(''));
          return NotificationManager.error('You can’t create a championship with no matches or specials', 'ERROR!', 3000);
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getOfficialChampionshipList = body => {
  const { published, final } = body;

  return dispatch => {
    dispatch(fetchStart());
    let url = `/challenges/official-championships?published=${published}`;
    if (published) {
      url += `&final=${final}`;
    }
    axios
      .get(url)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_OFFICIAL_CHAMPIONSHIPS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getOfficialChampionshipDetail = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/official-championship-details?challengeId=' + body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_OFFICIAL_CHAMPIONSHIPS_DETAILS,
          payload: data.data,
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const publishOfficialChampionship = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/challenges/publish-official-championship', body)
      .then(data => {
        dispatch(fetchSuccess(data.message));
        dispatch({ type: PUBLISH_OFFICIAL_CHAMPIONSHIPS, payload: data.data });
      })
      .catch(error => {
        console.log(error);
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const deleteOfficialChampionshipDetail = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/challenges/official-championship', { data: body })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: DELETE_OFFICIAL_CHAMPIONSHIPS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const patchOfficialChampionshipDetail = body => {
  return dispatch => {
    dispatch(fetchStart());
    return axios
      .patch('/challenges/official-championship', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: PATCH_OFFICIAL_CHAMPIONSHIPS_DETAILS,
          payload: data.data,
        });
        dispatch(fetchError('Official championship updated.'));
        // window.location.reload();
        window.location.href = '/official-championships';
      })
      .catch(error => {
        if (error.response.data.message === 'challenges.INVALID_CUSTOM_END_DATE') {
          dispatch(fetchError(''));
          return NotificationManager.error('Custom date must be bigger than Matches and Outrights end date', 'ERROR!', 3000);
        } else {
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const getMatchesForOfficialChampionship = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/matches/admin?status=2&sortBy=date&sortOrder=1', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_OFFICIAL_CHAMPIONSHIPS_MATCHES,
          payload: data.data,
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getLangForOfficialChampionship = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/languages?status=1')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_OFFICIAL_CHAMPIONSHIPS_LANG,
          payload: data.data,
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const selectBetTemplate = betTemplate => {
  return {
    type: SELECT_BET_TEMPLATE,
    payload: betTemplate,
  };
};

export const selectBetTemplateType = betTemplateType => {
  return {
    type: SELECT_BET_TEMPLATE_TYPE,
    payload: betTemplateType,
  };
};

export const setCustomFinalBettingDatesOutrights = customFinalBettingFinal => {
  return {
    type: SET_CUSTOM_FINAL_BETTING_DATES_OUTRIGHTS,
    payload: customFinalBettingFinal,
  };
};

export const setIndividualBetTemplates = betTemplate => {
  return {
    type: SET_INDIVIDUAL_BET_TEMPLATES,
    payload: betTemplate,
  };
};
