import React, { useEffect, useState } from 'react';
import CmtCard from '@coremat/CmtCard';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChallengeDetail,
  getChallengeMatches,
  getChallengeParticipants,
  getChallengeRanking,
  getChallengeTransaction,
} from '../../../redux/actions/Challenges';

import Box from '@material-ui/core/Box';
import { useHistory, useParams } from 'react-router';
import Moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: '0 !important',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px - 10px)`,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  margin: {
    display: 'flex',
    flexDirection: 'row',
  },
  match: {
    '& p:last-of-type': {
      marginBottom: '20px',
    },
  },
  boxClass: {
    boxShadow: '0px 1px 3px rgb(0 0 0 / 20%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 1px rgb(0 0 0 / 14%)',
    background: 'rgba(244, 244, 247, 0.5)',
    borderRadius: '10px',
    padding: '15px !important',
    transition: '0.3s all ease',
    margin: '10px',
  },
  hoverClass: {
    boxShadow: '0px 1px 3px rgb(0 0 0 / 20%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 1px rgb(0 0 0 / 14%)',
    background: 'rgba(244, 244, 247, 0.5)',
    borderRadius: '10px',
    padding: '15px !important',
    transition: '0.3s all ease',
    margin: '10px',
    width: 'calc(100% - 20px) !important',
    maxWidth: 'calc(50% - 20px) !important',
    '&:hover': {
      transform: 'translateX(5px) translateY(-5px)',
      transition: '0.3s all ease',
      background: 'rgba(244, 244, 247, 1)',
    },
  },
}));

const tennisSets = ['1', '2', '3', '4', '5', '6'];

const ChallengeDetailApp = ({ width }) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { challengeDetails, challengeTransactions, challengeRank, challengeParticipants, challengeMatches } = useSelector(
    ({ challenges }) => challenges,
  );
  const [details, setDetails] = useState(challengeDetails !== undefined ? challengeDetails : {});
  const [transactions, setTransactions] = useState(challengeTransactions !== undefined ? challengeTransactions : {});
  const [rank, setRank] = useState(challengeRank !== undefined ? challengeRank : {});
  const [participants, setParticipants] = useState(challengeParticipants !== undefined ? challengeParticipants : {});
  const [matches, setMatches] = useState(challengeMatches !== undefined ? challengeMatches : {});
  const classes = useStyles({});

  useEffect(() => {
    dispatch(getChallengeDetail(id));
    dispatch(getChallengeTransaction(id));
    dispatch(getChallengeParticipants(id));
    dispatch(getChallengeRanking(id));
    dispatch(getChallengeMatches(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setDetails(challengeDetails);
  }, [challengeDetails]);

  useEffect(() => {
    setParticipants(challengeParticipants);
  }, [challengeParticipants]);

  useEffect(() => {
    setMatches(challengeMatches);
  }, [challengeMatches]);

  useEffect(() => {
    setTransactions(challengeTransactions);
  }, [challengeTransactions]);

  useEffect(() => {
    if (challengeRank !== undefined) {
      challengeRank.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
      setRank(challengeRank.sort((a, b) => parseFloat(a.position) - parseFloat(b.position)));
    }
  }, [challengeRank]);

  return (
    <CmtCard
      style={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        background: 'rgba(255,255,255, 1)',
      }}>
      {details !== undefined && (
        <Box
          style={{
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: '0 0 20px 0',
            marginBottom: '20px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.27)',
          }}>
          <Box
            style={{
              width: '100%',
              marginBottom: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <ArrowBackIcon
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => {
                history.push('/challenges');
              }}
            />
            <h1 style={{ marginBottom: 0 }}>{details.name}</h1>
          </Box>
          <Box style={{ minWidth: '25%' }} className={classes.boxClass}>
            <strong style={{ color: '#30178e', fontSize: '15px' }}>ID</strong>: {details._id} <br />
            <strong style={{ color: '#30178e', fontSize: '15px' }}>Start</strong>:{' '}
            {Moment(details.startDate).format('H:mm - D MMM yyyy')} <br />
            <strong style={{ color: '#30178e', fontSize: '15px' }}>End</strong>:{' '}
            {Moment(details.endDate).format('H:mm - D MMM yyyy')}
          </Box>
          <Box style={{ padding: '0 15px', minWidth: '25%' }} className={classes.boxClass}>
            <strong style={{ color: '#30178e', fontSize: '15px' }}>Stake</strong>: {details.stake} <br />
            <strong style={{ color: '#30178e', fontSize: '15px' }}>Stake Currency</strong>: {details.stakeCurrency} <br />
            <strong style={{ color: '#30178e', fontSize: '15px' }}>Prize</strong>: {details.totalPrize}
          </Box>
          <Box style={{ minWidth: '25%' }} className={classes.boxClass}>
            <strong style={{ color: '#30178e', fontSize: '15px' }}>Matches</strong>: {details.totalMatchesCount} <br />
            <strong style={{ color: '#30178e', fontSize: '15px' }}>Invited</strong>: {details.invitedUsersCount} <br />
            <strong style={{ color: '#30178e', fontSize: '15px' }}>Accepted</strong>: {details.acceptedUsersCount}
          </Box>
        </Box>
      )}

      <Box
        style={{
          maxWidth: '50%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          borderRight: '1px solid rgba(0, 0, 0, 0.27)',
        }}>
        <Box style={{ maxWidth: '100%', width: '100%' }}>
          <h2 style={{ paddingLeft: '15px', paddingBottom: '5px' }}>Participants</h2>
        </Box>
        <Box
          style={{
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {participants !== undefined && participants.length > 0
            ? participants.map((item, index) => (
                <Box
                  key={item._id}
                  className={classes.hoverClass}
                  style={{
                    maxWidth: '50%',
                    width: '100%',
                    padding: '0 15px 15px 15px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    history.push('/user/' + item._id);
                  }}>
                  <strong style={{ color: '#30178e', fontSize: '15px' }}>Name</strong>: {item.nickname} <br />
                  <strong style={{ color: '#30178e', fontSize: '15px' }}>Id</strong>: {item._id}
                  <br />
                  <strong style={{ color: '#30178e', fontSize: '15px' }}>Status</strong>:{' '}
                  <span style={{ color: item.status === 'accepted' ? 'green' : 'red' }}>{item.status}</span>
                </Box>
              ))
            : null}
        </Box>
      </Box>

      <Box
        style={{
          maxWidth: '50%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}>
        <Box style={{ maxWidth: '100%', width: '100%' }}>
          <h2 style={{ paddingLeft: '15px', paddingBottom: '5px' }}>Ranking</h2>
        </Box>
        <Box
          style={{
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {rank !== undefined && rank.length > 0 ? (
            rank.map((item, index) => (
              <Box
                key={item._id}
                className={classes.hoverClass}
                style={{
                  maxWidth: '50%',
                  width: '100%',
                  padding: '0 15px 15px 15px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  history.push('/user/' + item.user._id);
                }}>
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Name</strong>: {item.user.nickname} <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Position</strong>: {item.position}
                <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Points</strong>: {parseFloat(item.points).toFixed(2)}
                <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Bonus</strong>: {item.yeppBonus}
                <br />
                {item.punishment.length > 0 && (
                  <span>
                    <strong style={{ color: '#30178e', fontSize: '15px' }}>Punishment</strong>: {item.punishment}
                  </span>
                )}
              </Box>
            ))
          ) : (
            <span style={{ paddingLeft: '15px' }}>No ranking available</span>
          )}
        </Box>
      </Box>
      <Box
        style={{
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '0 15px 20px 15px',
          marginBottom: '20px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.27)',
        }}
      />
      <Box
        style={{
          maxWidth: '50%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}>
        <Box style={{ maxWidth: '100%', width: '100%' }}>
          <h2 style={{ paddingLeft: '15px', paddingBottom: '5px' }}>Transactions</h2>
        </Box>
        <Box
          style={{
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderRight: '1px solid rgba(0, 0, 0, 0.27)',
          }}>
          {transactions !== undefined && transactions.length > 0 ? (
            transactions.map((item, index) => (
              <Box
                key={item._id}
                className={classes.hoverClass}
                style={{
                  maxWidth: '50%',
                  width: '100%',
                  padding: '0 15px 15px 15px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  history.push('/user/' + item.user._id);
                }}>
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Name</strong>: {item.nickname} <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>User ID</strong>: {item.userId} <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Transaction ID</strong>: {item._id} <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Wallet ID</strong>: {item.walletId}
                <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Value</strong>: {item.value} {item.currency}
                <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Type</strong>:{' '}
                {item.type === 0 ? 'INCOME' : 'OUTCOME'}
                <br />
                <strong style={{ color: '#30178e', fontSize: '15px' }}>Date</strong>:{' '}
                {Moment(item.created).format('H:mm - D MMM yyyy')}
                <br />
              </Box>
            ))
          ) : (
            <span style={{ paddingLeft: '15px' }}>No transactions</span>
          )}
        </Box>
      </Box>
      <Box
        style={{
          maxWidth: '50%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}>
        <Box style={{ maxWidth: '100%', width: '100%' }}>
          <h2 style={{ paddingLeft: '15px', paddingBottom: '5px' }}>Matches</h2>
        </Box>
        <Box
          style={{
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {matches !== undefined && matches.length > 0
            ? matches.map((item, itemIndex) => (
                <Box
                  key={item._id}
                  className={classes.hoverClass}
                  style={{
                    maxWidth: '50%',
                    width: '100%',
                    padding: '0 15px 15px 15px',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    history.push('/match/' + item.game._id);
                  }}>
                  <p style={{ textAlign: 'center', marginBottom: '20px' }}>{item.game.headline}</p>
                  <Box
                    className={classes.match}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    {item.game.stats !== undefined ? (
                      <>
                        {item.game.sportName === 'Football' && (
                          <p
                            style={{
                              margin: 'auto',
                            }}>{`${
                            item.game.stats.wholeGame?.paramFloat1
                              ? item.game.stats.wholeGame?.paramFloat1
                              : item.game.stats.wholeGame?.paramFloat1 === undefined
                              ? ''
                              : '0'
                          } - ${
                            item.game.stats.wholeGame?.paramFloat2
                              ? item.game.stats.wholeGame?.paramFloat2
                              : item.game.stats.wholeGame?.paramFloat2 === undefined
                              ? ''
                              : '0'
                          }`}</p>
                        )}
                        {item.game.sportName === 'Tennis' &&
                          tennisSets.map(
                            tennisSet =>
                              item.game.stats[`set${tennisSet}`] && (
                                <p key={tennisSet}>
                                  <span style={{ color: '#30178e' }}>{`Set ${tennisSet}: `}</span>
                                  {item.game.stats[`set${tennisSet}`].paramFloat1} -
                                  {item.game.stats[`set${tennisSet}`].paramFloat2}
                                </p>
                              ),
                          )}
                        <span style={{ marginTop: 'auto' }}>{item.game.status}</span>
                      </>
                    ) : (
                      <p>Not available</p>
                    )}
                  </Box>
                </Box>
              ))
            : null}
        </Box>
      </Box>
    </CmtCard>
  );
};

export default ChallengeDetailApp;
