import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CountersSection from './Counters/CountersSection';
import UsersSection from './Users/UsersSection';
import GamesSection from './Games/GamesSection';
import { makeStyles } from '@material-ui/core/styles';
import {
  fetchAnalyticsCounters,
  fetchAnalyticsUsers,
  fetchGames,
  setGlobalUserType,
} from '../../../redux/actions/Analytics';
import { B2BUserType } from '../../../redux/actions/Analytics';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(7),
  },
  dropdown: {
    marginBottom: theme.spacing(5),
  },
}));

const Analytics = () => {
  const classes = useStyles();
  const topRef = useRef(null);

  const dispatch = useDispatch();
  const { globalUserType, counters, users, games, error } = useSelector(state => state.analytics);
  const isB2B = localStorage.getItem('isB2B');

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAnalyticsCounters({ externalType: globalUserType }));
    dispatch(fetchAnalyticsUsers({ externalType: globalUserType }));
    dispatch(fetchGames({ externalType: globalUserType }));
  }, [dispatch, globalUserType]);

  const handleUserTypeChange = event => {
    const selectedType = event.target.value;

    dispatch(setGlobalUserType(selectedType === 'ALL' ? null : selectedType));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box p={3}>
        <div ref={topRef}></div>
        <Typography variant="h1" gutterBottom className={classes.title}>
          Analytics
        </Typography>

        {!isB2B && (
          <FormControl fullWidth className={classes.dropdown}>
            <InputLabel>User Type</InputLabel>
            <Select value={globalUserType === null ? 'ALL' : globalUserType} onChange={handleUserTypeChange}>
              <MenuItem value="ALL">All User Types</MenuItem>
              {Object.keys(B2BUserType).map(type => (
                <MenuItem key={type} value={B2BUserType[type]}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Pass globalUserType as a prop to the child components */}
        <React.Fragment>
          <CountersSection counters={counters} externalType={globalUserType} />
          <UsersSection users={users} externalType={globalUserType} />
          <GamesSection games={games} externalType={globalUserType} />
        </React.Fragment>

        {error && <Typography color="error">{String(error)}</Typography>}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default Analytics;
