import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, Grid, Typography, CircularProgress } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import CountersFilterModal from './CountersFilterModal';
import {
  fetchAnalyticsCounters,
  fetchAnalyticsCountersCSV,
  fetchNewBets,
  fetchNewUsers,
} from '../../../../redux/actions/Analytics';
import { B2BUserType } from '../../../../redux/actions/Analytics';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: '20px',
    marginBottom: '8px',
  },
  cardValue: {
    fontSize: '50px',
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  downloadButton: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  subtitle: {
    marginBottom: '20px',
  },
  gridContainer: {
    marginBottom: '30px',
  },
  filters: {
    display: 'flex',
    marginBottom: '20px',
    gap: '8px',
  },
  filtersContent: {
    fontSize: '14px',
  },
  boldSpan: {
    fontWeight: 900,
  },
}));

const DEFAULT_FILTERS = {
  country: 'ALL',
  dateType: 'week',
  selectedDate: null,
  startDate: moment()
    .startOf('isoWeek')
    .format('YYYY-MM-DDTHH:mm:ss'),
  endDate: moment()
    .endOf('isoWeek')
    .format('YYYY-MM-DDTHH:mm:ss'),
  selectedMonth: null,
};

const CountersSection = ({ externalType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [appliedFilters, setAppliedFilters] = useState(DEFAULT_FILTERS);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [filterOpen, setFilterOpen] = useState(false);
  const { counters, newUsersThisWeek, newBetsThisWeek } = useSelector(state => state.analytics);
  const [countersLoading, setCountersLoading] = useState(true);

  const isCurrentWeek = (startDate, dateType) => {
    const currentWeekStart = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DDTHH:mm:ss');
    const formattedStartDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
    return dateType === 'week' && formattedStartDate === currentWeekStart;
  };

  const isThisWeek = isCurrentWeek(appliedFilters.startDate, appliedFilters.dateType);

  /**
   * Effect 1: Fetch data when appliedFilters or externalType changes
   */
  useEffect(() => {
    const fetchCountersData = async () => {
      setCountersLoading(true);

      const { startDate, endDate } = getDateRange(appliedFilters);

      await dispatch(
        fetchAnalyticsCounters({
          externalType,
          startDate,
          endDate,
        }),
      );

      await dispatch(
        fetchNewUsers({
          country: appliedFilters.country,
          userType: externalType,
          startDate,
          endDate,
        }),
      );

      await dispatch(
        fetchNewBets({
          country: appliedFilters.country,
          userType: externalType,
          startDate,
          endDate,
        }),
      );

      setCountersLoading(false);
    };

    fetchCountersData();
  }, [appliedFilters, externalType, dispatch]);

  useEffect(() => {
    const resetAndFetchData = async () => {
      if (externalType === null) {
        console.log('externalType set to ALL, resetting filters');
      } else {
        console.log('externalType changed, resetting filters');
      }
      setFilters(DEFAULT_FILTERS);
      setAppliedFilters(DEFAULT_FILTERS);
      if (filterOpen) {
        setFilterOpen(false);
      }
      setCountersLoading(true);
      await dispatch(
        fetchAnalyticsCounters({
          externalType,
          startDate: appliedFilters.startDate,
          endDate: appliedFilters.endDate,
        }),
      );

      await dispatch(
        fetchNewUsers({
          country: appliedFilters.country,
          userType: externalType,
          startDate: appliedFilters.startDate,
          endDate: appliedFilters.endDate,
        }),
      );

      await dispatch(
        fetchNewBets({
          country: appliedFilters.country,
          userType: externalType,
          startDate: appliedFilters.startDate,
          endDate: appliedFilters.endDate,
        }),
      );
      setCountersLoading(false);
    };

    resetAndFetchData();
  }, [externalType, dispatch]);

  const fetchCounters = (newFilters = appliedFilters) => {
    const { startDate, endDate } = getDateRange(newFilters);

    const params = {};
    if (startDate && endDate) {
      params.startDate = startDate;
      params.endDate = endDate;
    }

    if (externalType) {
      params.externalType = externalType;
    }

    dispatch(fetchAnalyticsCounters(params));
  };

  const availableCountries = counters ? Array.from(new Set(counters.map(counter => counter.country))) : [];

  const handleFilterChange = (filter, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filter]: value }));
  };

  const handleFilterOpen = () => setFilterOpen(true);
  const handleFilterClose = () => setFilterOpen(false);

  const handleApplyFilter = () => {
    console.log('Applying filters:', filters);
    setAppliedFilters(filters);
    setFilterOpen(false);
  };

  const handleReset = () => {
    const resetFilters = {
      ...DEFAULT_FILTERS,
      startDate: moment()
        .startOf('isoWeek')
        .format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment()
        .endOf('isoWeek')
        .format('YYYY-MM-DDTHH:mm:ss'),
      selectedDate: null,
      selectedMonth: null,
    };
    setFilters(resetFilters);
    setAppliedFilters(resetFilters);
    fetchCounters(resetFilters);
  };

  const handleDownloadCSV = () => dispatch(fetchAnalyticsCountersCSV());

  const getDateRange = filters => {
    let startDate = null,
      endDate = null;

    if (filters.dateType === 'day' && filters.selectedDate) {
      const localDate = new Date(filters.selectedDate);
      localDate.setHours(0, 0, 0, 0);
      startDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();

      localDate.setHours(23, 59, 59, 999);
      endDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    } else if (filters.dateType === 'week' && filters.startDate && filters.endDate) {
      const localStartDate = new Date(filters.startDate);
      localStartDate.setHours(0, 0, 0, 0);
      startDate = new Date(localStartDate.getTime() - localStartDate.getTimezoneOffset() * 60000).toISOString();

      const localEndDate = new Date(filters.endDate);
      localEndDate.setHours(23, 59, 59, 999);
      endDate = new Date(localEndDate.getTime() - localEndDate.getTimezoneOffset() * 60000).toISOString();
    } else if (filters.dateType === 'month' && filters.selectedMonth) {
      const monthStart = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth(), 1);
      monthStart.setHours(0, 0, 0, 0);
      startDate = new Date(monthStart.getTime() - monthStart.getTimezoneOffset() * 60000).toISOString();

      const monthEnd = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59, 999);
      endDate = new Date(monthEnd.getTime() - monthEnd.getTimezoneOffset() * 60000).toISOString();
    }

    return { startDate, endDate };
  };

  const renderCardValue = value => {
    if (countersLoading || counters === null) {
      return <CircularProgress />;
    }
    return <Typography className={classes.cardValue}>{value.toLocaleString()}</Typography>;
  };

  const totalUsers =
    counters && appliedFilters.country === 'ALL'
      ? counters.reduce((sum, counter) => sum + counter.totalUsers, 0)
      : (counters &&
          counters.find(counter => counter.country === appliedFilters.country) &&
          counters.find(counter => counter.country === appliedFilters.country).totalUsers) ||
        0;

  const newUsers = newUsersThisWeek;

  const totalBets =
    counters && appliedFilters.country === 'ALL'
      ? counters.reduce((sum, counter) => sum + counter.totalBets, 0)
      : (counters &&
          counters.find(counter => counter.country === appliedFilters.country) &&
          counters.find(counter => counter.country === appliedFilters.country).totalBets) ||
        0;

  const newBets = newBetsThisWeek;

  const { startDate, endDate } = getDateRange(appliedFilters);

  const formatDateWithOrdinal = date => {
    const momentDate = moment(date);
    const day = momentDate.date();
    const suffix =
      day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th';
    return momentDate.format('MMMM') + ' ' + day + suffix;
  };

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom className={classes.subtitle}>
        Counters
      </Typography>
      <Box className={classes.buttonContainer}>
        <Button className={classes.button} onClick={handleFilterOpen} startIcon={<FilterListIcon />}>
          Counters Filters
        </Button>
        <Button className={classes.downloadButton} onClick={handleDownloadCSV} startIcon={<GetAppIcon />}>
          Download Counters CSV
        </Button>
      </Box>
      <Box className={classes.filters}>
        <Typography className={classes.filtersContent}>
          <span className={classes.boldSpan}>Country:</span> {appliedFilters.country || 'All'} Countries,
        </Typography>
        <Typography className={classes.filtersContent}>
          <span className={classes.boldSpan}>Date Type:</span>{' '}
          {appliedFilters.dateType
            ? appliedFilters.dateType.charAt(0).toUpperCase() + appliedFilters.dateType.slice(1)
            : 'Week'}
          ,
        </Typography>
        <Typography className={classes.filtersContent}>
          <span className={classes.boldSpan}>Start Date:</span> {startDate ? formatDateWithOrdinal(startDate) : 'N/A'},
        </Typography>
        <Typography className={classes.filtersContent}>
          <span className={classes.boldSpan}>End Date:</span> {endDate ? formatDateWithOrdinal(endDate) : 'N/A'}
        </Typography>
      </Box>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>Total Users</Typography>
              {renderCardValue(totalUsers)}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>{isThisWeek ? 'New Users This Week' : 'New Users'}</Typography>
              {renderCardValue(newUsers)}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>Total Predictions</Typography>
              {renderCardValue(totalBets)}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>
                {isThisWeek ? 'New Predictions This Week' : 'New Predictions'}
              </Typography>
              {renderCardValue(newBets)}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CountersFilterModal
        open={filterOpen}
        onClose={handleFilterClose}
        handleApply={handleApplyFilter}
        filters={filters}
        handleFilterChange={handleFilterChange}
        B2BUserType={B2BUserType}
        availableCountries={availableCountries}
        onReset={handleReset}
      />
    </React.Fragment>
  );
};

export default CountersSection;
