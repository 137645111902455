import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './index.style';

import Moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';

const ChampionshipCell = ({ championshipData, removeChampionship, publishChampionship }) => {
  const history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState(championshipData.published);

  const handleChange = event => {
    setState(event.target.checked);
    publishChampionship(championshipData._id, event.target.checked);
  };
  return (
    <Box className={classes.championshipCell}>
      <Box className={classes.championshipHead}>
        <h2>{championshipData.name}</h2>
        <Box className={classes.championshipActions}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push('/official-championship/' + championshipData._id);
            }}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => removeChampionship(championshipData._id)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.championshipBody}>
        <Box className={classes.championshipLogo}>
          <img alt="logo" src={championshipData.logo} />
        </Box>
        <Box className={classes.championshipDate}>
          <span className={classes.label}>Start Date:</span>
          <span>
            <strong>{Moment(championshipData.startDate).format('H:mm - D MMM yyyy')}</strong>
          </span>
          <span className={classes.label}>End Date:</span>
          <span>
            <strong>
              {Moment(championshipData?.customEndDate || championshipData.endDate).format('H:mm - D MMM yyyy')}
            </strong>
          </span>
        </Box>
        <Box style={{ marginLeft: 'auto' }}>
          <span>
            <strong>Published</strong>
          </span>
          <Switch
            checked={state}
            onChange={handleChange}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChampionshipCell;
