import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import useStyles from './index.style';
import AppHeader from './AppHeader';
import clsx from 'clsx';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import BarsList from './BarsList';
import BarDetail from './BarDetail';
import CreateBar from './CreateBar';
import { setCurrentBar } from '../../../redux/actions/BarApp';

const BarApp = () => {
  const classes = useStyles();
  const { isSideBarCollapsed } = useSelector(({ barApp }) => barApp);
  const [viewMode, setViewMode] = useState('table');
  const [showBarDetail, setShowBarDetail] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const dispatch = useDispatch();

  const onChangeViewMode = mode => {
    setViewMode(mode);
  };

  const onShowBarDetail = bar => {
    dispatch(setCurrentBar(bar));
    setShowBarDetail(true);
  };

  const onHideBarDetail = () => {
    dispatch(setCurrentBar(null));
    setShowBarDetail(false);
  };

  const onClickCreateBar = () => {
    setOpenCreateDialog(true);
  };

  const onClickEditBar = bar => {
    dispatch(setCurrentBar(bar));
    setOpenCreateDialog(true);
  };

  const onCloseComposeDialog = () => {
    dispatch(setCurrentBar(null));
    setOpenCreateDialog(false);
  };

  return (
    <Box className={classes.inBuildAppCard}>
      <AppHeader onChangeViewMode={onChangeViewMode} viewMode={viewMode} />
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar onClickCreateBar={onClickCreateBar} />
        <BarsList viewMode={viewMode} onShowBarDetail={onShowBarDetail} onClickEditBar={onClickEditBar} />
      </Box>
      {showBarDetail && <BarDetail open={showBarDetail} handleDialog={onHideBarDetail} />}
      {openCreateDialog && <CreateBar open={openCreateDialog} handleDialog={onCloseComposeDialog} />}
    </Box>
  );
};

export default BarApp;
