import { ECOSYSTEMS, GET_ECOSYSTEM_DETAIL } from 'constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  Ecosystems: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ECOSYSTEMS: {
      return {
        ...state,
        Ecosystems: action.payload,
      };
    }
    case GET_ECOSYSTEM_DETAIL: {
      return {
        ...state,
        EcosystemDetail: action.payload,
      };
    }
    default:
      return state;
  }
};
