import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from 'components/Table/table.styles';
import { useHistory } from 'react-router-dom';

const EditCellSystem = ({ betTemplate }) => {
  const classes = useStyles();

  const history = useHistory();
  return (
    <Box className={classes.matchCellOptionsRoot}>
      <Box className={clsx(classes.actionOptionRoot, 'action-option')}>
        <Box ml={1}>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              aria-label="edit selection"
              component="span"
              onClick={() => {
                history.push('/ecosystem/' + betTemplate._id);
              }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default EditCellSystem;

EditCellSystem.prototype = {
  betTemplate: PropTypes.object.isRequired,
  fetchBetTemplates: PropTypes.func.isRequired,
};
