import React from 'react';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Dashboard', isActive: true },
];

const SamplePage = () => {
  return (
    <PageContainer heading={<IntlMessages id="pages.dashboard" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Box>More to be added soon.</Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default SamplePage;
