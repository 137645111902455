import {
  GET_CHALLENGES,
  CHALLENGE_DETAILS,
  CHALLENGE_MATCHES,
  CHALLENGE_PARTICIPANTS,
  CHALLENGE_RANK,
  CHALLENGE_TRANSACTIONS,
  CHALLENGE_OUTRIGHTS,
} from '../../constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    selectedFolder: 'accepted',
    selectedLabel: '',
    searchText: '',
  },
  languageList: [],
  languages: [],
  languagePlatforms: [],
  currentLanguage: null,
  totalBars: null,
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHALLENGES: {
      return {
        ...state,
        challengesList: action.payload.data,
      };
    }

    case CHALLENGE_DETAILS: {
      return {
        ...state,
        challengeDetails: action.payload.data,
      };
    }

    case CHALLENGE_RANK: {
      return {
        ...state,
        challengeRank: action.payload.data,
      };
    }

    case CHALLENGE_TRANSACTIONS: {
      return {
        ...state,
        challengeTransactions: action.payload.data,
      };
    }

    case CHALLENGE_PARTICIPANTS: {
      return {
        ...state,
        challengeParticipants: action.payload.data,
      };
    }

    case CHALLENGE_MATCHES: {
      return {
        ...state,
        challengeMatches: action.payload.data,
      };
    }

    case CHALLENGE_OUTRIGHTS: {
      return {
        ...state,
        challengeOutrights: action.payload.data,
      };
    }

    default:
      return state;
  }
};
