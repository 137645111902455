import {
  GET_MATCH_DETAIL,
  GET_MATCH_ODDS,
  GET_MATCH_PLAYERS,
  GET_MATCH_EVENTS,
  ADD_MATCH_EVENTS,
  REMOVE_MATCH_EVENTS,
  PATCH_MATCH_ODDS,
  PATCH_MATCH_PLAYER,
  SET_MATCH_BROADCAST,
  SET_MATCH_STADIUM,
  GET_SINGLE_MATCH_ODDS,
} from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getMatchDetails = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/matches/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_MATCH_DETAIL, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getMatchOdds = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/odds/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_MATCH_ODDS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getSingleMatchOdds = matchId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/odds/game/' + matchId)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_SINGLE_MATCH_ODDS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const patchMatchOdds = (id, data) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/odds/' + id, data)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_MATCH_ODDS, payload: data.data });
        dispatch(fetchError('New Odd saved'));
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const patchPlayer = (id, data) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/players/' + id, data)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_MATCH_PLAYER, payload: data.data });
        dispatch(fetchError('New lineup saved'));
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getMatchEvents = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/matches/' + id + '/events')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_MATCH_EVENTS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const addMatchEvents = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/events/add-event-after-match', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_MATCH_EVENTS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const removeMatchEvents = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/events/' + id + '/remove-event-after-match')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: REMOVE_MATCH_EVENTS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getMatchPlayers = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/players/match/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_MATCH_PLAYERS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const addBroadcastMatch = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/matches/change-broadcasters/' + id, body)
      .then(data => {
        dispatch(getMatchDetails(id));
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_MATCH_BROADCAST, payload: data });
          dispatch(fetchError(`Broadcast add success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const addStadiumMatch = (id, stadiumNameValue) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/matches/add-stadium/' + id, { stadium: stadiumNameValue })
      .then(data => {
        dispatch(getMatchDetails(id));
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_MATCH_STADIUM, payload: data });
          dispatch(fetchError(`Stadium add success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
