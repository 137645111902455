import React, { useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import useStyles from './../index.style';
import ImageIcon from '@material-ui/icons/Image';
import { v4 as uuidv4 } from 'uuid';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const EditPrize = ({ langList, dataPrize, httpImage, dataView, onSaveEditPrize, addEditPrize, dataUuid }) => {
  const classes = useStyles();
  const lang = langList;
  const [prizeDescription, setPrizeDescription] = useState(() => {
    let newLangPrizeDescription = [];
    lang.forEach((langH, key) => {
      newLangPrizeDescription.push({
        description: '',
        language: langH,
      });
    });

    return newLangPrizeDescription.reduce(
      (acc, eachArr2Elem) => {
        if (
          dataPrize.description.findIndex(
            eachArr1Elem => eachArr1Elem.language === eachArr2Elem.language && eachArr1Elem.description.length >= 1,
          ) === -1
        ) {
          acc.push(eachArr2Elem);
        }
        return acc;
      },
      [...dataPrize.description],
    );
  });

  const [prizeUuid, setPrizeUuid] = useState(dataPrize.uuid);
  const [prizeName, setPrizeName] = useState(dataPrize.name);
  const [newPrizePhoto, setNewPrizePhoto] = useState(dataPrize.photo);

  const handleChange = (e, lang, key) => {
    let description = {
      description: e.target.value,
      language: lang,
    };
    setPrizeDescription(prizeDescription, (prizeDescription[key] = description));
  };

  const [previewPrizePhoto, setPreviewPrizePhoto] = useState(dataPrize.photo);

  const handlePrizePhoto = e => {
    setPreviewPrizePhoto(URL.createObjectURL(e.target.files[0]));
    setNewPrizePhoto(e.target.files[0]);
    setPrizeUuid(uuidv4());
  };

  const onChangeName = e => {
    setPrizeName(e.target.value);
  };

  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 3000, () => {
      alert('callback');
    });
  };

  const savePrize = () => {
    let newPrize = {
      _id: dataPrize._id,
      name: prizeName,
      description: prizeDescription,
      photo: previewPrizePhoto,
      uuid: prizeUuid,
      deleted: false,
    };
    let newPrizeUuid = {
      [prizeUuid]: newPrizePhoto,
    };

    if (prizeName.length !== undefined && prizeName.length > 0) {
      if (prizeUuid !== null) {
        let emptyDescription = false;
        prizeDescription.forEach((description, key) => {
          if (description.description.length === 0) {
            emptyDescription = true;
          }
        });
        if (emptyDescription === true) {
          createNotification('error', 'All prize description fields are mandatory');
        } else {
          onSaveEditPrize(newPrize, newPrizeUuid);
          addEditPrize();
        }
      } else {
        createNotification('error', 'Prize Photo should not be empty');
      }
    } else {
      createNotification('error', 'Prize Name should not be empty');
    }
  };

  return (
    <Box className={classes.prizeOfficial}>
      <TextField
        id="outlined-basic"
        onChange={onChangeName}
        style={{ maxWidth: '300px' }}
        value={prizeName}
        label="Prize Name"
        variant="outlined"
      />
      <Box className={classes.prizeOfficialRow}>
        <Box className={classes.prizePhoto}>
          <Box className={classes.imagePlaceholder}>
            {previewPrizePhoto !== null ? <img alt="logo" src={previewPrizePhoto} className={classes.imageLogo} /> : null}
          </Box>
          <input
            accept="image/*"
            onChange={handlePrizePhoto}
            className={classes.hiddenInput}
            id="contained-button-prize-photo"
            multiple
            type="file"
          />
          <label htmlFor="contained-button-prize-photo">
            <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
              Upload
            </Button>
          </label>
        </Box>
        <Box className={classes.prizeDescription}>
          {prizeDescription.map((item, key) => {
            return (
              <TextField
                id="outlined-basic"
                key={key}
                onChange={e => handleChange(e, item.language, key)}
                defaultValue={item.description}
                multiline={true}
                className={classes.Textarea50}
                label={'Description ' + item.language.toUpperCase()}
                variant="outlined"
              />
            );
          })}
        </Box>
      </Box>
      <Button
        style={{ margin: '20px auto 0 auto', display: 'inline-block' }}
        variant="contained"
        color="primary"
        onClick={savePrize}>
        Save Prize
      </Button>
      <NotificationContainer />
    </Box>
  );
};

export default EditPrize;
