import React from 'react';
import { useHistory } from 'react-router-dom';
import { alpha, IconButton, Tooltip, TableRow, TableCell, makeStyles, Box } from '@material-ui/core';

import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
}));

const actions = [
  {
    label: 'View User',
    id: 'view',
  },
];

const TableItem = ({ row }) => {
  const history = useHistory();
  const classes = useStyles();
  const name = row.firstName + ' ' + row.lastName;

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <CmtMediaObject
          avatarPos="center"
          avatar={<CmtAvatar size={35} src={row.profilePic} alt={row.name} />}
          title={
            <Box>
              <Box>{name}</Box>
              <Box style={{ opacity: 0.6, fontSize: '0.8em' }}>{row._id}</Box>
            </Box>
          }
          titleProps={{
            variant: 'h5',
            className: classes.titleRoot,
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        {' '}
        {row.verified === true ? <CheckCircleIcon color="primary" /> : <HighlightOffIcon />}
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        {row.identityVerificationStatus === 0 && <span style={{ color: 'red', fontWeight: 'bold' }}>REQUIRED</span>}
        {row.identityVerificationStatus === 1 && <span style={{ color: 'blue', fontWeight: 'bold' }}>IN PROGRESS</span>}
        {row.identityVerificationStatus === 2 && <span style={{ color: 'green', fontWeight: 'bold' }}>COMPLETE</span>}
        {row.identityVerificationStatus === 3 && <span style={{ color: 'red', fontWeight: 'bold' }}>REQUESTED</span>}
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        {row.riskLevel === 0 && <span style={{ color: 'green', fontWeight: 'bold' }}>LOW</span>}
        {row.riskLevel === 1 && <span style={{ color: 'blue', fontWeight: 'bold' }}>MEDIUM</span>}
        {row.riskLevel === 2 && <span style={{ color: 'red', fontWeight: 'bold' }}>HIGH</span>}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box>
          <Box>
            <Box>{row.totalAmountEUR} EUR</Box>
            <Box>{row.totalAmountGBP} GBP</Box>
            <Box>{row.totalAmountUSD} USD</Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box>
          <Box>{row.totalAmountPlayedEUR} EUR</Box>
          <Box>{row.totalAmountPlayedGBP} GBP</Box>
          <Box>{row.totalAmountPlayedUSD} USD</Box>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <CmtDropdownMenu
            TriggerComponent={
              <Tooltip title="More">
                <IconButton size="small" style={{ marginLeft: 10 }}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            }
            items={actions}
            onItemClick={() => {
              history.push('/user/' + row._id);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
