import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './index.style';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PollDetails from './PollDetails';
import { fetchAllLanguages } from 'redux/actions/Languages';
import { getPolls } from 'redux/actions/Polls';

const PollCreateEditPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllLanguages());
  }, []);

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(getPolls());
              setTimeout(() => {
                history.push('/polls');
              }, 100);
            }}
          />
        }
        title={id !== undefined ? 'Edit Poll' : 'Create Poll'}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent className={classes.cardContentRoot}>
        <PollDetails />
        <NotificationContainer />
      </CmtCardContent>
    </CmtCard>
  );
};

export default PollCreateEditPage;
