import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { Box, Button, TablePagination, FormControl, InputLabel, Input, Select, MenuItem } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import CmtAvatar from '@coremat/CmtAvatar';

import useStyles from './../index.style';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableSports } from 'redux/actions';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const MatchSearch = props => {
  const useStyles = makeStyles(theme => ({
    clearIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }));
  const classes = useStyles();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
    props.setMatch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sport, props.added]);

  const submitVal = () => {
    if (value && value.length > 0) {
      props.submit(value);
    } else {
      props.submit('');
    }
  };

  const clearSearchField = () => {
    setValue('');
    props.submit('');
  };

  return (
    <FormControl style={{ width: '100%', display: 'block' }}>
      <Box style={{ display: 'inline-block' }}>
        <InputLabel htmlFor="input-with-icon-adornment">Search for Matches</InputLabel>
        <Input
          style={{ marginRight: '20px' }}
          endAdornment={value?.length >= 1 && <ClearIcon className={classes.clearIcon} onClick={clearSearchField} />}
          id="input-with-icon-adornment"
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') submitVal();
          }}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};

const rowsPerPage = 10;

const SelectMatches = ({ match, selectMatch, selectedMatches }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [matchFull, setMatchFull] = useState(match.matches.filter(item => item.status !== 'Ended'));
  const [matchPagination, setMatchPagination] = useState(matchFull.slice(0, rowsPerPage));
  const { availableSports } = useSelector(({ common }) => common);
  const [selectedSport, setSelectedSport] = useState();
  const [matchAdded, setMatchAdded] = useState(false);

  const handleChangePage = (event, newPage) => {
    setMatchPagination(matchFull.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getAvailableSports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (availableSports) setSelectedSport(availableSports[0]._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSports]);

  useEffect(() => {
    // filter selected matches
    let updatedMatches = match.matches.filter(
      match => match.status !== 'Ended' && !selectedMatches.find(({ _id }) => match._id === _id),
    );
    // filter by sport
    if (selectedSport) updatedMatches = updatedMatches.filter(match => match.sportId === selectedSport);

    // correct the page number by number of matches
    if (page && count) {
      if (rowsPerPage * page + 1 === count) setPage(page - 1);
    }
    setCount(updatedMatches.length);
    setMatchFull(updatedMatches);

    let updatedMatchesPagination = updatedMatches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || {};

    if (!updatedMatchesPagination.length) {
      setMatchPagination(updatedMatches.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage) || {});
    } else {
      setMatchPagination(updatedMatchesPagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatches, selectedSport, match.matches]);

  const submit = valueSearch => {
    const searchList = [];
    let newMatchList = match.matches.filter(match => match.status !== 'Ended' && match.sportId === selectedSport);

    if (valueSearch.length > 0) {
      newMatchList
        .filter(match => !selectedMatches.find(({ _id }) => match._id === _id))
        .forEach(value => {
          if (valueSearch && value.headline.toLowerCase().includes(valueSearch.toLowerCase())) {
            searchList.push(value);
          } else if (valueSearch && value.competitionName.toLowerCase().includes(valueSearch.toLowerCase())) {
            searchList.push(value);
          }
        });
      setMatchFull(searchList);
      setCount(searchList.length);
      setMatchPagination(searchList.slice(0, rowsPerPage));
    } else {
      setMatchFull(newMatchList);
      setCount(newMatchList.length);
      setMatchPagination(newMatchList.slice(0, rowsPerPage));
    }
    setPage(0);
  };

  const handleChangeSport = e => {
    setSelectedSport(e.target.value);
    setPage(0);
  };

  return (
    <Box className={classes.selectListMatch}>
      <Box style={{ marginBottom: '20px', display: 'flex' }}>
        <MatchSearch classes={classes} setMatch={setMatchAdded} added={matchAdded} submit={submit} sport={selectedSport} />
        {selectedSport && (
          <FormControl style={{ margin: '12.5px 20px', width: '15%' }}>
            <InputLabel>Filter by sport</InputLabel>
            <Select name="filterBySport" value={selectedSport} onChange={handleChangeSport}>
              {availableSports?.map(sport => {
                return (
                  <MenuItem key={sport._id} value={sport._id}>
                    {sport.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Box>
      <Box className={classes.selectListMatchContainer}>
        {matchPagination?.map((match, id) => {
          return (
            <Box key={id} className={classes.selectListMatchRow}>
              <Box>
                <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Box
                    mr={4}
                    style={{
                      width: '30px',
                      overflow: 'hidden',
                      marginRight: '0',
                      borderRight: '1px solid #fff',
                    }}>
                    <CmtAvatar size={60} src={match.homeTeamEmblemUrl} alt={match.headline} />
                  </Box>
                  <Box
                    mr={4}
                    style={{
                      width: '30px',
                      overflow: 'hidden',
                      marginRight: '0',
                    }}>
                    <CmtAvatar
                      size={60}
                      style={{ transform: 'translateX(-30px)' }}
                      src={match.awayTeamEmblemUrl}
                      alt={match.headline}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>
                  <h3 style={{ fontWeight: 'bold' }}>{match.headline}</h3>
                </Box>
                <Box>{Moment(match.scheduledDate).format('H:mm - D MMM yyyy')}</Box>
                <Box>{match.competitionName}</Box>
              </Box>
              <Button
                style={{ marginLeft: 'auto' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  selectMatch(
                    match._id,
                    match.headline,
                    match.scheduledDate,
                    match.homeTeamEmblemUrl,
                    match.awayTeamEmblemUrl,
                    match.competitionName,
                    match.sportId,
                  );
                  setMatchAdded(true);
                }}>
                Add
              </Button>
            </Box>
          );
        })}
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default SelectMatches;
